<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Карусель»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_carousel_1.png" alt="Блок «Карусель»" description="Блок «Карусель»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — carousel"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Карусель» --&gt;

{$props = [
    'color_scheme' => 'main',
    'lazy' => true,
    'no_margin' => false,
    'use_filter' => '1',
    'background' => '',
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Блок «Карусель»'
    ],
    'carousels' => [
        '1' => [
            'image' => 'img/200x120.png',
            'link' => '#'
        ],
        '2' => [
            'image' => 'img/200x120.png',
            'link' => '#'
        ],
        '3' => [
            'image' => 'img/200x120.png',
            'link' => '#'
        ],
        '4' => [
            'image' => 'img/200x120.png',
            'link' => '#'
        ],
        '5' => [
            'image' => 'img/200x120.png',
            'link' => '#'
        ],
        '6' => [
            'image' => 'img/200x120.png',
            'link' => '#'
        ],
        '7' => [
            'image' => '',
            'link' => '#'
        ],
        '8' => [
            'image' => '',
            'link' => '#'
        ],
        '9' => [
            'image' => '',
            'link' => '#'
        ],
        '10' => [
            'image' => '',
            'link' => '#'
        ]
    ]
]}

{call add_block block="carousel" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import carousel from '@/assets/data/carousel.json'
    import AppGoBack from "../../components/AppGoBack";

    export default {
        name: 'BlockCarousel',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: carousel,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>