<template>
    <div>
        <app-title text="Главная страница блога" />

        <p class="documentation__text">
            Установив тему, вы попадаете на стартовый экран. Первое, что вам
            нужно сделать — это создать <accent text="новую статью" />.
            Перейдите в бэкенд приложения Блог и нажмите «Новая запись».
        </p>

        <app-image
            url="/img/documentation/blog_homepage_1.png"
            alt="Новая запись в блоге"
        />

        <p class="documentation__text">
            Создадим пустую статью с любым заголовком и нажмем «Опубликовать».
        </p>

        <app-image
            url="/img/documentation/blog_homepage_2.png"
            alt="Создание новой записи в блоге"
        />

        <p class="documentation__text">
            После этого на главной странице появились блоки, настроить их
            отображение и порядок можно в админ-панели на вкладке «Главная
            страница блога» 🠖 «Порядок блоков». В каждом блоке отдельно есть
            настройка «Убрать верхний отступ». Вы можете включить ее во всех
            блоках, кроме первого, чтобы плотнее сгруппировать контент на
            странице.
        </p>

        <app-image
            url="/img/documentation/blog_homepage_3.png"
            alt="Блоки главной страницы"
        />

        <p class="documentation__text">
            На этой вкладке можно изменить настройку «Ссылки на главную
            страницу». В значении «Открывать главную страницу приложения» все
            ссылки, которые должны открывать главную страницу будут вести на
            блог (по умолчанию /blog).
        </p>

        <p class="documentation__text">
            В другом случае ссылки будут открывать корневую страницу сайта, а
            хлебные крошки примут такой вид:
        </p>

        <app-image
            url="/img/documentation/blog_homepage_4.png"
            alt="Хлебные крошки"
        />

        <app-subtitle text="Получение ID статьи" />

        <p class="documentation__text">
            Большинство блоков требует, чтобы вы вписали
            <accent text="ID статьи" />, которую нужно показать на главной
            странице. Получить его можно следующим образом: перейдите на
            страницу статьи и откройте админ-панель. Затем, в нижней части возле
            кнопки Сохранить наведите на соответствующую иконку.
        </p>

        <app-image
            url="/img/documentation/blog_homepage_5.png"
            alt="Получить ID статьи"
        />

        <app-subtitle text="Поток статей" />

        <p class="documentation__text">
            В блоке «Поток статей» будут выводиться все статьи, которые вы
            опубликовали. Статьи будут подгружаться по мере прокрутки страницы
            по 10 карточек за раз. Либо, если выбрана пагинация, то по 10 на
            странице. Изменить это количество можно через плагин Скрытые
            настройки. В приложении «Блог» перейдите в «Плагины» 🠖 «Скрытые
            настройки» и измените параметр <accent text="posts_per_page" /> на
            нужное вам число.
        </p>

        <app-subtitle text="Авторизация и комментарии" />

        <p class="documentation__text">
            Если в статье разрешены комментарии, пользователи смогут оставлять
            их как гости. Вы также можете разрешить пользователям
            регистрироваться в блоге, для этого перейдите в приложение «Сайт» в
            раздел «Личный кабинет». Включите «Личный кабинет пользователя» и в
            настройке «Раздел сайта для авторизации и регистрации» укажите
            значение <b>Блог</b>.
        </p>

        <app-image
            url="/img/documentation/blog_homepage_6.png"
            alt="Webasyst главная картинка"
        />

        <p class="documentation__text">
            Поскольку в Webasyst нельзя показывать
            <a href="https://developers.webasyst.ru/docs/features/captcha/"
                >две капчи</a
            >
            на одной странице, необходимо настроить капчу так, чтобы
            использовать ее либо в форме авторизации, либо в форме оставления
            комментария.
        </p>
    </div>
</template>

<script>
export default {
    name: 'BlogHomepage',
    data() {
        return {};
    },
};
</script>
