<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Бренды»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_brands_1.png" alt="Блок «Бренды»" description="Блок «Бренды»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — brands"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Бренды» --&gt;

{$props = [
  'mode' => 'grid',
  'color_scheme' => 'main',
  'lazy' => false,
  'no_margin' => false,
  'content' => 'settings',
  'use_filter' => true,
  'limit' => 10,
  'title' => [
    'align' => 'left',
    'size' => 'medium, H3',
    'text' => 'Что такое Lorem Ipsum?'
  ],
  'text' => 'Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов, но это не совсем так.',
  'link' => [
    'template' => 'link-two',
    'anchor' => true,
    'uppercase' => true,
    'color' => 'secondary',
    'target' => '_self',
    'link' => '#',
    'text' => 'Смотреть все'
  ],
  'items' => [
    '1' => [
      'image' => 'img/200x120.png',
      'link' => '#'
    ],
    '2' => [
      'image' => 'img/200x120.png',
      'link' => '#'
    ],
    '3' => [
      'image' => 'img/200x120.png',
      'link' => '#'
    ],
    '4' => [
      'image' => 'img/200x120.png',
      'link' => '#'
    ],
    '5' => [
      'image' => 'img/200x120.png',
      'link' => '#'
    ],
    '6' => [
      'image' => 'img/200x120.png',
      'link' => '#'
    ],
    '7' => [
      'image' => 'img/200x120.png',
      'link' => '#'
    ],
    '8' => [
      'image' => 'img/200x120.png',
      'link' => '#'
    ],
    '9' => [
      'image' => 'img/200x120.png',
      'link' => '#'
    ],
    '10' => [
      'image' => 'img/200x120.png',
      'link' => '#'
    ]
  ]
]}

{call add_block block="brands" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopBrands from '@/assets/data/shop-brands.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopBrands',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopBrands,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>