<template>
    <div class="support-button">
        <router-link :to="url" class="support-button__content">{{
            text
        }}</router-link>
        <div class="support-button__shadow"></div>
    </div>
</template>

<script>
export default {
    name: 'SupportButton',
    props: {
        text: String,
        url: String,
    },
};
</script>

<style lang="less">
.support-button {
    display: none;
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 70px;

    &__content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 20px;
        border-radius: 9px;
        background-color: @primary;
        color: @text-inverse;
        font-size: 0.95em;
        transition: all 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        cursor: pointer;
        user-select: none;
        text-decoration: none;
        z-index: 1;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 23px;
            right: 0;
            border: 10px solid transparent;
            border-bottom-color: @primary;
            transition: all 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transform: rotate(28deg);
        }

        &:hover {
            background-color: lighten(@primary, 10%);
        }

        &:hover::after {
            border-bottom-color: lighten(@primary, 10%);
        }
    }

    &__shadow {
        position: absolute;
        width: 100px;
        top: 5px;
        right: -35px;
        height: 120px;
        transform: skewX(26deg) rotate(72deg);
        opacity: 0.05;
        background-color: @inverse;
    }
}

@media (min-width: 768px) {
    .support-button {
        display: block;
    }
}
</style>