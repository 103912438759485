<template>
    <div class="box">
        <div class="box__header" v-if="this.$slots.header">
            <slot name="header"></slot>
        </div>
        <div class="box__content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppBox',
};
</script>

<style lang="less">
.box {
    background-color: @background;
    border: 1px solid @secondary;
    border-radius: 4px;

    &:not(:last-of-type) {
        margin-bottom: 2rem;
    }

    &__header {
        padding: 1rem;
        border-bottom: 1px solid @secondary;
    }

    &__content {
        padding: 1rem;
    }
}

@media (min-width: 768px) {
    .box {
        &__header,
        &__content {
            padding: 1.5rem 2rem;
        }
    }
}
</style>