<template>
    <router-link class="card-item" :to="link.url">
        <span class="card-item__icon">
            <icon-file />
        </span>
        <span>{{ link.text }}</span>
        <span class="card-item__arrow">
            <icon-arrow />
        </span>
    </router-link>
</template>

<script>
import IconFile from '@/components/icons/IconFile.vue';
import IconArrow from '@/components/icons/IconArrow.vue';

export default {
    name: 'AppItem',
    components: {
        IconFile,
        IconArrow,
    },
    props: {
        link: Object,
    },
};
</script>

<style lang="less">
.card-item {
    display: grid;
    grid-template-columns: 30px 1fr 24px;
    grid-gap: 1em;
    align-items: center;
    padding: 0.8rem;
    background-color: lighten(@secondary, 4%);
    border: 1px solid transparent;
    border-radius: 4px;
    color: @text;
    text-decoration: none;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &:hover {
        background-color: @text-inverse;
        border-color: @secondary;
        box-shadow: @medium-shadow;

        & .card-item__arrow {
            opacity: 1;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: @text-inverse;
        border-radius: 4px;
        border: 1px solid darken(@secondary, 4%);
    }

    &__arrow {
        display: flex;
        align-items: center;
        opacity: 0;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
}
</style>