<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Контакты»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_contacts_1.png" alt="Блок «Контакты»" description="Блок «Контакты»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — contacts"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Контакты» --&gt;

{$props = [
    'title' => [
        'align' => 'left',
        'size' => 'small, H3',
        'text' => 'Контакты'
    ],
    'text' => '',
    'show_phone' => true,
    'show_email' => true,
    'show_hours' => true,
    'marker' => 'Тема дизайна «Orion»',
    'latitude' => '55.759643',
    'longitude' => '37.618948',
    'zoom' => 17,
    'phone' => [
        'template' => 'link-one',
        'uppercase' => false,
        'anchor' => true,
        'color' => 'inverse',
        'target' => '_self',
        'link' => 'tel:88000000000',
        'text' => '8 (800) 000 00 00'
    ],
    'email' => [
        'template' => 'link-one',
        'uppercase' => false,
        'anchor' => true,
        'color' => 'inverse',
        'target' => '_self',
        'link' => 'mailto:example@mail.com',
        'text' => 'example@mail.com'
    ],
    'postal_code' => '125009',
    'city' => 'г. Москва',
    'address' => 'ул. Театральная 1, офис 10',
    'manual_schedule' => 'Пн—Сб 08:00–18:00'
]}

{call add_block block="contacts" props=$props}</ssh-pre>
		</div>

		<p class="documentation__text">
			Допускается только <accent text="один блок"/> на страницу.
		</p>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import contacts from '@/assets/data/contacts.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockContacts',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: contacts,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>