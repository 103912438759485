<template>
    <li class="list-item">
        <div class="list-item__icon">
            <icon-check />
        </div>
        <div class="list-item__text">
            <slot></slot>
        </div>
    </li>
</template>

<script>
import IconCheck from '@/components/icons/IconCheck.vue';

export default {
    name: 'AppListItem',
    components: {
        IconCheck,
    },
};
</script>

<style lang="less">
.list-item {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    margin-bottom: 10px;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin-top: 3px;
        border-radius: 50%;
        background-color: fade(@primary, 12%);

        & svg {
            width: 12px;
            height: 12px;
            fill: @primary;
        }
    }
}
</style>