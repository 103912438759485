<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Главный экран»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/blog-app/adding-blocks'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_blog_mainscreen_1.png" alt="Блок «Главный экран»" description="Блок «Главный экран»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — mainscreen"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Главный экран» --&gt;

{$props = [
    'lazy' => true,
    'gradient' => true,
    'animation' => true,
    'show_menu' => true,
    'color' => '#ffffff',
    'half_screen' => false,
    'background' => 'img/2200x1220.png',
    'align' => 'center',
    'title' => [
        'align' => 'center',
        'size' => 'big, H1',
        'text' => 'Современный блог с удобной настройкой блоков'
    ],
    'text' => '<p>Меняйте основные данные главной страницы в фронтенде. Теперь стало проще сделать удобный и современный блог.</p>',
    'list' => [
        'template' => 'list-one',
        'inverse' => true,
        'align' => 'center',
        'list' => ''
    ],
    'buttons' => [
        '1' => [
            'template' => 'button-one',
            'mode' => 'link',
            'link' => '#',
            'target' => '_self',
            'type' => 'primary',
            'anchor' => true,
            'text' => 'Читайть полностью'
        ],
        '2' => [
            'template' => 'button-one',
            'mode' => '',
            'link' => '#',
            'target' => '_self',
            'type' => 'secondary',
            'anchor' => 'true',
            'text' => 'Перейти на сайт'
        ]
    ],
    'links' => [
        '0' => [
            'url' => '#',
            'name' => 'Первая ссылка'
        ],
        '1' => [
            'url' => '#',
            'name' => 'Вторая ссылка'
        ],
        '2' => [
            'url' => '#',
            'name' => 'Третья ссылка'
        ],
        '3' => [
            'url' => '#',
            'name' => 'Четвертая ссылка'
        ],
        '4' => [
            'url' => '#',
            'name' => 'Пятая ссылка'
        ],
        '5' => [
            'url' => '#',
            'name' => 'Шестая ссылка'
        ]
    ]
]}

{call add_block block="mainscreen" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import blogMainscreen from '@/assets/data/blog-mainscreen.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockBlogBanner',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: blogMainscreen
            }
        }
    }
</script>