<template>
    <div
        :url="url"
        class="download-button"
        :class="[
            `download-button--${type}`,
            { 'download-button--cursor': cursor },
        ]"
        @click.prevent.self.once="clickHandler()"
    >
        <svg
            width="40"
            height="40"
            viewBox="0 0 72 72"
            xmlns="http://www.w3.org/2000/svg"
        >
            <clipPath id="progress-clip">
                <circle cx="36" cy="36" r="36" />
            </clipPath>
            <circle
                ref="bg"
                opacity="0"
                :fill="colorScheme.secondary"
                cx="36"
                cy="36"
                r="36"
            />
            <rect
                ref="linePath"
                :fill="colorScheme.contrast"
                x="15"
                y="57"
                width="42"
                height="4"
                clip-path="url(#progress-clip)"
            />
            <circle
                ref="progressCircle"
                opacity="0"
                fill="none"
                cx="36"
                cy="36"
                r="34"
                :stroke="colorScheme.contrast"
                stroke-width="4"
                transform="rotate(-90 36 36)"
            />
            <polygon
                ref="mainShape"
                :fill="colorScheme.contrast"
                transform="translate(0 -6)"
                points="15 36 27 36 27 18 45 18 45 36 57 36 36 57"
            />
        </svg>
        <button ref="restart" class="download-button__restart is-hidden">
            Скачать повторно
        </button>
    </div>
</template>

<script>
import { gsap } from 'gsap';

export default {
    name: 'DownloadButton',
    props: {
        text: String,
        url: String,
        type: String,
    },
    data() {
        return {
            colorScheme: {
                primary: `#c8a86b`,
                secondary: `#f0f0f0`,
                contrast: `#cacaca`,
            },
            cursor: true,
        };
    },
    methods: {
        downloadFile: function (delay) {
            const link = document.createElement('a');
            link.setAttribute('href', this.url);
            link.style.display = 'none';

            setTimeout(() => {
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }, delay);
        },
        getCircleLength: el => 2 * Math.PI * el.getAttribute('r'),
        showElement: el => el.classList.remove('is-hidden'),
        clickHandler: function () {
            const delay = 0;
            const td = { preload: 0.5, progress: 3, success: 0.25 };

            const bgCircle = this.$refs.bg;
            const mainShape = this.$refs.mainShape;
            const progressCircle = this.$refs.progressCircle;
            const linePath = this.$refs.linePath;
            const restart = this.$refs.restart;

            // Timeline instance
            const tl = gsap.timeline({
                onComplete: () => this.showElement(restart),
                delay,
            });

            // Preload animation
            tl.to(bgCircle, td.preload, { attr: { opacity: 1 } });
            tl.to(
                mainShape,
                td.preload,
                { attr: { transform: 'translate(0 0)' } },
                0
            );
            tl.to(
                linePath,
                td.preload,
                { attr: { x: bgCircle.getAttribute('r'), y: 0, width: 2 } },
                0
            );

            // Progress animation
            const progressPathLength = this.getCircleLength(progressCircle);

            tl.fromTo(
                progressCircle,
                td.progress,
                {
                    attr: {
                        opacity: 1,
                        'stroke-dasharray': progressPathLength,
                        'stroke-dashoffset': progressPathLength,
                    },
                },
                { attr: { 'stroke-dashoffset': 0 } }
            );
            tl.set(linePath, { attr: { opacity: 0 } });

            // Success animation
            const successStartTime = td.preload + td.progress;

            tl.to(
                bgCircle,
                td.success,
                { attr: { fill: this.colorScheme.secondary } },
                successStartTime
            );
            tl.to(
                progressCircle,
                td.success,
                { attr: { opacity: 0 } },
                successStartTime
            );
            tl.to(
                mainShape,
                td.success,
                {
                    attr: {
                        points: '15 42 21 36 30 45 39.5654298 33.63 51 21 57 27 30 57',
                        fill: this.colorScheme.primary,
                    },
                },
                successStartTime
            );

            restart.addEventListener('click', () => {
                tl.restart(true);
                this.downloadFile(3750);
            });

            this.cursor = false;
            this.downloadFile(3750);
        },
    },
};
</script>

<style lang="less" scoped>
.download-button {
    position: relative;

    &--cursor {
        cursor: pointer;
    }

    & svg {
        width: 100%;
        pointer-events: none;
    }

    &__restart {
        position: absolute;
        bottom: -20px;
        right: 0;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        font: inherit;
        font-size: 0.85em;
        transition: 0.3s ease-in-out;
        will-change: visibility, opacity, transform;
        white-space: nowrap;

        &.is-hidden {
            transform: translateY(2px);
        }
    }

    .is-hidden {
        visibility: hidden;
        opacity: 0;
    }
}
</style>