<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Действие»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_action_1.png" alt="Блок «Действие»" description="Блок «Действие»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — action"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Действие» --&gt;

{$props = [
    'counters_number' => '2',
    'gradient' => true,
    'lazy' => false,
    'background' => 'img/2200x1220.png',
    'align' => 'left',
    'title' => [
        'align' => 'left',
        'size' => 'extra, H2',
        'text' => 'Сделайте сайт непохожим на другие'
    ],
    'color' => '#ffffff',
    'buttons' => [
        '1' => [
            'template' => 'button-one',
            'mode' => 'feedback',
            'link' => '#',
            'target' => '_self',
            'type' => 'primary',
            'anchor' => true,
            'wide' => true,
            'icon' => 'phone',
            'text' => 'Заказать консультацию'
        ]
    ],
    'counters' => [
        '1' => [
            'template' => 'counter-one',
            'align' => 'left',
            'counter' => '11',
            'unit' => 'шрифтов',
            'text' => 'помогут найти фирменный стиль'
        ],
        '2' => [
            'template' => 'counter-one',
            'align' => 'left',
            'counter' => '18',
            'unit' => 'цветовых схем',
            'text' => 'золотая, зеленая, оранжевая, синяя, красная и другие'
        ]
    ]
]}

{call add_block block="action" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import action from '@/assets/data/action.json'
    import AppGoBack from "../../components/AppGoBack";

    export default {
        name: 'BlockAction',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: action,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>