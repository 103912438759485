var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"side-navigation"},[_c('ul',{staticClass:"side-navigation__list"},[_c('li',{staticClass:"side-navigation__item"},[_vm._m(0),_vm._v(" "),_c('router-link',{attrs:{"to":"/"}},[_vm._v("Список демо-версий")])],1),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._l((_vm.navigation),function(link,key){return _c('li',{key:key,staticClass:"side-navigation__item"},[(link.meta.group)?_c('span',{staticClass:"side-navigation__group"},[_c('span',[_vm._v(_vm._s(link.meta.group))])]):_vm._e(),_vm._v(" "),(link.meta.subgroup)?_c('span',{staticClass:"side-navigation__subgroup"},[_c('span',[_vm._v(_vm._s(link.meta.subgroup))])]):_vm._e(),_vm._v(" "),_c('router-link',{attrs:{"exact":"","to":link.path,"active-class":"is-active"}},[_vm._v("\n                "+_vm._s(link.meta.name)+"\n                "),(link.meta.marked)?_c('span',{staticClass:"side-navigation__marker"},[_vm._v("new")]):_vm._e()])],1)})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"side-navigation__group"},[_c('span',[_vm._v("Демо-версии")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"side-navigation__item"},[_c('a',{attrs:{"href":"https://orion.active-vision.ru/demo/demo-one/","target":"_blank"}},[_vm._v("Пример сайта")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"side-navigation__item"},[_c('a',{attrs:{"href":"https://orion.active-vision.ru/demo/demo-two/","target":"_blank"}},[_vm._v("Пример лендинга")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"side-navigation__item"},[_c('a',{attrs:{"href":"https://orion.active-vision.ru/demo/shop/","target":"_blank"}},[_vm._v("Пример магазина")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"side-navigation__item"},[_c('a',{attrs:{"href":"https://orion.active-vision.ru/demo/blog/","target":"_blank"}},[_vm._v("Пример блога")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"side-navigation__item"},[_c('a',{attrs:{"href":"https://orion.active-vision.ru/demo/photos/","target":"_blank"}},[_vm._v("Пример фотогалереи")])])
}]

export { render, staticRenderFns }