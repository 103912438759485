<template>
	<div>
		<app-title text="Набор блоков" />

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<navigation-box
			icon="cards"
			title="Карточки"
			description="Блоки темы дизайна «Orion»"
			:links="navigationLinks(`cards`)"
		/>

		<navigation-box
			icon="list"
			title="Списки"
			description="Блоки темы дизайна «Orion»"
			:links="navigationLinks(`list`)"
		/>

		<navigation-box
			icon="fullscreen"
			title="Полноэкранные"
			description="Блоки темы дизайна «Orion»"
			:links="navigationLinks(`fullscreen`)"
		/>

		<navigation-box
			icon="interactive"
			title="Интерактивные"
			description="Блоки темы дизайна «Orion»"
			:links="navigationLinks(`interactive`)"
		/>

		<navigation-box
			icon="text"
			title="Текстовые"
			description="Блоки темы дизайна «Orion»"
			:links="navigationLinks(`text`)"
		/>

		<block-search :links="navigation" />
	</div>
</template>

<script>
    import NavigationBox from '@/components/NavigationBox.vue'
    import BlockSearch from "../../components/BlockSearch";

    export default {
        name: 'ListOfBlocks',
        components: {
            NavigationBox,
            BlockSearch
        },
        data() {
            return {
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        },
        computed: {
            navigation() {
                const navigation = this.$router.options.routes.find(element => element.name === `Documentation`);
                return navigation.children.filter(function (link) {
                    return link.meta.type === `block`;
                });
            }
        },
        methods: {
            navigationLinks(category) {
                const navigation =  this.navigation.filter(function(link) {
                    return link.meta.category === category;
                });
                return navigation.map(function (link) {
                    return {url: link.path, text: link.meta.name};
                });
            }
		}
    }
</script>