<template>
	<div>
		<div class="documentation__header">
			<app-title text="Элемент «Ссылка»"></app-title>
			<app-go-back :link="{name: 'Набор элементов', path: '/documentation/elements-list'}"></app-go-back>
		</div>

		<app-subtitle text="Вызов элемента на страницах сайта"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову элементов на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Элемент «Ссылка» --&gt;
{$props = [
  'template' => 'link-two',
  'anchor' => true,
  'uppercase' => true,
  'color' => 'secondary',
  'target' => '_self',
  'link' => '#',
  'text' => 'Подробнее'
]}

{call add_element element="link/`$props.template`" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import link from '@/assets/data/element-link.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'ElementLink',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: link,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>