<template>
    <div class="element">
        <img :src="icon" :alt="name" class="element__icon" width="72" />
        <p v-if="name" class="element__name">{{ name }}</p>
        <p v-if="desc" class="element__desc">{{ desc }}</p>

        <discount :app="app" showPrice />

        <div class="element__button">
            <app-button
                v-if="button"
                :type="button.style"
                :text="button.text"
                :url="button.url"
            ></app-button>
        </div>
    </div>
</template>

<script>
import Discount from './Discount';
import AppButton from './AppButton';

export default {
    name: 'AppElement',
    components: {
        AppButton,
        Discount,
    },
    props: {
        icon: String,
        name: String,
        desc: String,
        app: String,
        button: {
            style: {
                type: String,
                default: `primary`,
            },
            text: String,
            url: String,
        },
    },
};
</script>

<style lang="less">
.element {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__icon {
        display: block;
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
    }

    &__name {
        font-family: @title-font;
        font-weight: 600;
    }

    &__desc {
        display: block;
        font-size: 0.85em;
        color: lighten(@text, 30%);
        margin-bottom: 20px;
    }

    & .discount {
        margin-top: auto;
    }
}
</style>