<template>
	<div>
		<div class="documentation__header">
			<app-title text="Элемент «Линия»"></app-title>
			<app-go-back :link="{name: 'Набор элементов', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
		</div>

		<app-subtitle text="Вызов элемента на страницах магазина"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Элемент «Линия» --&gt;
{$props = [
  'template' => 'line-one',
  'name' => 'Lorem Ipsum',
  'value' => 'Псевдолатинский набор слов'
]}

{call add_element element="line/`$props.template`" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import shopLine from '@/assets/data/shop-element-line.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'ElementShopLine',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: shopLine,
            }
        }
    }
</script>