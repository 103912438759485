<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Статистика»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_statistics_1.png" alt="Блок «Статистика»" description="Блок «Статистика»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — statistics"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Статистика» --&gt;

{$props = [
    'id' => '',
    'animation' => true,
    'color_scheme' => 'alter',
    'no_margin' => false,
    'title' => [
        'align' => 'left',
        'size' => 'small, H3',
        'text' => 'Тема дизайна для приложения «Фото»'
    ],
    'buttons' => [
        '1' => [
            'template' => 'button-one',
            'mode' => 'feedback',
            'link' => '#',
            'target' => '_self',
            'type' => 'primary',
            'anchor' => true,
            'wide' => true,
            'icon' => 'none',
            'text' => 'Подробнее'
        ]
    ],
    'text' => '&lt;p&gt;Новое визуальное оформления для движка фотогалереи Webasyst. Создайте портфолио ваших проектов, выполненных работ с публичным или ограниченным доступом.&lt;/p&gt;&lt;p&gt;Приложение «Фото» — это фотохостинг на вашем сайте. Организуйте неограниченное количество альбомов, фильтруйте ваши фото, выставляйте им оценки, добавляйте описание.&lt;/p&gt;',
    'items' => [
        '1' => [
            'animation' => true,
            'color_scheme' => 'alter',
            'number' => '65',
            'text' => 'Первая шкала'
        ],
        '2' => [
            'animation' => true,
            'color_scheme' => 'alter',
            'number' => '95',
            'text' => 'Вторая шкала'
        ],
        '3' => [
            'animation' => true,
            'color_scheme' => 'alter',
            'number' => '25',
            'text' => 'Третья шкала'
        ],
        '4' => [
            'animation' => true,
            'color_scheme' => 'alter',
            'number' => '45',
            'text' => 'Четвертая шкала'
        ]
    ]
]}

{call add_block block="statistics" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import statistics from '@/assets/data/statistics.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockStatistics',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: statistics,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>