<template>
    <div class="sidebar" :class="{ 'is-opened': sidebarIsOpened }">
        <side-navigation :links="navigation" />
        <div class="sidebar__footer">
            <div class="sidebar__footer-content">
                <div class="sidebar__footer-item">
                    <router-link class="sidebar__footer-link" to="/support"
                        >Поддержка пользователей</router-link
                    >
                </div>
                <router-link class="sidebar__mail" to="/support">
                    <icon-mail />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import IconMail from '@/components/icons/IconMail.vue';
import SideNavigation from '@/components/SideNavigation.vue';

export default {
    name: 'Sidebar',
    components: {
        SideNavigation,
        IconMail,
    },
    props: {
        links: Object,
    },
    computed: {
        sidebarIsOpened() {
            return this.$store.state.sidebarIsOpened;
        },
        navigation() {
            const navigation = this.$router.options.routes.find(
                element => element.name === `Documentation`
            );
            return navigation.children;
        },
    },
    created() {
        this.setSidebarState();

        let timeout = false;
        let delay = 250;

        window.addEventListener('resize', () => {
            clearTimeout(timeout);
            timeout = setTimeout(this.windowResizeHandler, delay);
        });
    },
    methods: {
        setSidebarState() {
            if (window.innerWidth < 1400) {
                this.sidebarToggle();
            }
        },
        sidebarToggle() {
            this.$store.state.sidebarIsOpened = !this.sidebarIsOpened;
        },
        windowResizeHandler() {
            if (this.sidebarIsOpened && window.innerWidth < 1400) {
                this.sidebarToggle();
            }
        },
    },
};
</script>

<style lang="less">
.sidebar {
    position: absolute;
    left: -300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    min-height: calc(100vh - 45px);
    box-sizing: border-box;
    border-right: 1px solid darken(@secondary, 4%);
    background-color: @text-inverse;
    transition: left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    box-shadow: @medium-shadow;
    z-index: 50;

    &.is-opened {
        left: 0;
    }

    &__footer {
        height: 85px;
        padding-top: 0.5rem;
        box-sizing: border-box;
    }

    &__footer-content {
        display: grid;
        grid-template-columns: 1fr 30px;
        grid-gap: 10px;
        align-items: center;
        height: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        background-color: lighten(@secondary, 4%);
        border-top: 1px solid @secondary;
        box-sizing: border-box;
        font-size: 0.9em;
        line-height: 1.4em;
    }

    &__footer-link {
        position: relative;
        font-size: 0.95em;
        color: @text;
        text-decoration: none;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: -3px;
            left: auto;
            box-sizing: border-box;
            width: 0;
            height: 2px;
            transition: 0.25s ease all;
            background-color: @primary;
        }

        &:hover::after {
            width: 100%;
            left: 0;
            right: auto;
            transition-duration: 0.5s;
        }
    }

    &__mail {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        will-change: transform;

        &:active {
            transform: scale(0.9);
        }
    }
}

@media (min-width: 1200px) {
    .sidebar {
        min-height: calc(100vh - 65px);
    }
}

@media (min-width: 1400px) {
    .sidebar {
        box-shadow: unset;
    }
}
</style>