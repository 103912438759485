<template>
    <div>
        <div class="documentation__header">
            <app-title text="Блок «Отзывы»"></app-title>
            <app-go-back
                :link="{
                    name: 'Набор блоков',
                    path: '/documentation/shop-app/adding-blocks',
                }"
            ></app-go-back>
        </div>

        <app-image
            url="/img/documentation/block_shop_reviews_1.png"
            alt="Блок «Отзывы»"
            description="Блок «Отзывы»"
            border
        />

        <app-subtitle
            text="Вызов блока на страницах сайта"
            label="ID — reviews"
        />

        <!-- prettier-ignore -->
        <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Отзывы» --&gt;

{$props = [
  'mode' => 'carousel',
  'no_margin' => false,
  'color_scheme' => 'semitone',
  'limit' => 3,
  'title' => [
    'align' => 'left',
    'size' => 'medium, H2',
    'text' => 'Последние отзывы'
  ],
  'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова.',
  'stretch' => true,
  'product' => true,
  'date' => true
]}

{call add_block block="reviews" props=$props}</ssh-pre>
    </div>

        <app-table :data="tableData" />
    </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import IconCopy from '@/components/icons/IconCopy.vue';
import shopReviews from '@/assets/data/shop-reviews.json';
import AppGoBack from '../../components/AppGoBack';

export default {
    name: 'BlockShopReviews',
    components: {
        SshPre,
        IconCopy,
        AppGoBack,
    },
    data() {
        return {
            tableData: shopReviews,
            infoLink: {
                name: 'Подробнее',
                path: '/documentation/shop-app/adding-blocks',
            },
        };
    },
};
</script>
