<template>
	<div>
		<div class="documentation__header">
			<app-title text="Элемент «Кнопка»"></app-title>
			<app-go-back :link="{name: 'Набор элементов', path: '/documentation/elements-list'}"></app-go-back>
		</div>

		<app-subtitle text="Вызов элемента на страницах сайта"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову элементов на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Элемент «Кнопка» --&gt;
{$props = [
  'template' => 'button-one',
  'mode' => 'link',
  'link' => '#',
  'target' => '_self',
  'type' => 'primary',
  'anchor' => true,
  'icon' => 'none',
  'text' => 'Подробнее'
]}

{call add_element element="button/button" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import button from '@/assets/data/element-button.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'ElementButton',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: button,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>