<template>
    <div class="navigation-box">
        <div class="navigation-box__header">
            <div class="navigation-box__circle">
                <component v-bind:is="iconComponent"></component>
            </div>
            <div>
                <h2 class="navigation-box__title">{{ title }}</h2>
                <p class="navigation-box__description">{{ description }}</p>
            </div>
        </div>
        <ul class="navigation-box__links" v-if="links">
            <li
                class="navigation-box__link"
                v-for="(link, key) of links"
                :key="key"
            >
                <app-item :link="link" />
            </li>
        </ul>
    </div>
</template>

<script>
import AppItem from '@/components/AppItem.vue';
import IconBook from '@/components/icons/IconBook.vue';
import IconCards from '@/components/icons/IconCards.vue';
import IconList from '@/components/icons/IconList.vue';
import IconFullscreen from '@/components/icons/IconFullscreen.vue';
import IconInteractive from '@/components/icons/IconInteractive.vue';
import IconText from '@/components/icons/IconText.vue';
import IconPen from '@/components/icons/IconPen.vue';
import IconElement from '@/components/icons/IconElement.vue';

export default {
    name: 'NavigationBox',
    components: {
        AppItem,
        IconBook,
        IconCards,
        IconList,
        IconFullscreen,
        IconInteractive,
        IconText,
        IconPen,
        IconElement,
    },
    props: {
        icon: String,
        title: String,
        description: String,
        links: Array,
    },
    computed: {
        iconComponent: function () {
            return this.icon ? `icon-${this.icon.toLowerCase()}` : `icon-book`;
        },
    },
};
</script>

<style lang="less">
.navigation-box {
    padding: 1rem;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: darken(@secondary, 4%);
    background-color: @background;

    &:not(:last-of-type) {
        margin-bottom: 2rem;
    }

    &__header {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 3em;
        grid-row-gap: 2em;
        align-items: center;
    }

    &__title {
        font-family: @title-font;
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        background-color: @primary;
        border: 20px solid lighten(@secondary, 4%);
        border-radius: 50%;
    }

    &__links {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 1em;
        grid-row-gap: 0.5em;
        margin-top: 20px;
    }
}

@media (min-width: 480px) {
    .navigation-box {
        padding: 1.5rem;

        &__header {
            grid-template-columns: 110px 1fr;
        }
    }
}

@media (min-width: 992px) {
    .navigation-box {
        &__links {
            grid-template-columns: 1fr 1fr;
        }
    }
}
</style>
