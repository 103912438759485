<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Слайдер»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_slider_1.png" alt="Блок «Слайдер»" description="Блок «Слайдер»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — slider"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Слайдер» --&gt;

{$props = [
  'template' => 'slide-one',
  'color_scheme' => 'semitone',
  'icon_style' => 'is-linear',
  'icon_color' => 'is-primary',
  'loop' => true,
  'navigation' => true,
  'slides' => [
    '1' => [
      'show' => true,
      'color' => 'hsl(225, 12%, 13%)',
      'background' => '',
      'lazy' => false,
      'shadow' => false,
      'margin' => true,
      'color_scheme' => 'semitone',
      'animation' => true,
      'title' => [
        'align' => 'left',
        'size' => 'big, H1',
        'text' => 'Тема дизайна «Orion Shop»'
      ],
      'text' => 'Тема дизайна Orion Shop для Shop-Script позволит вам создать современный и гибкий интернет магазин, оптимизированный для любых устройств.',
      'image' => 'img/500x370.png',
      'buttons' => [
        '1' => [
          'template' => 'button-one',
          'mode' => 'link',
          'link' => '#',
          'target' => '_self',
          'type' => 'primary',
          'wide' => true,
          'anchor' => true,
          'text' => 'Подробнее'
        ]
      ]
    ],
    '2' => [
      'show' => true,
      'color' => 'hsl(225, 12%, 13%)',
      'background' => '',
      'lazy' => false,
      'shadow' => false,
      'margin' => true,
      'color_scheme' => 'semitone',
      'animation' => true,
      'title' => [
        'align' => 'left',
        'size' => 'big, H2',
        'text' => 'Тема дизайна «Orion Shop»'
      ],
      'text' => 'Тема дизайна Orion Shop для Shop-Script позволит вам создать современный и гибкий интернет магазин, оптимизированный для любых устройств.',
      'image' => 'img/500x370.png',
      'buttons' => [
        '1' => [
          'template' => 'button-one',
          'mode' => 'link',
          'link' => '#',
          'target' => '_self',
          'type' => 'primary',
          'wide' => true,
          'anchor' => true,
          'text' => 'Подробнее'
        ]
      ]
    ],
    '3' => [
      'show' => true,
      'color' => 'hsl(225, 12%, 13%)',
      'background' => '',
      'lazy' => false,
      'shadow' => false,
      'margin' => true,
      'color_scheme' => 'semitone',
      'animation' => true,
      'title' => [
        'align' => 'left',
        'size' => 'big, H2',
        'text' => 'Тема дизайна «Orion Shop»'
      ],
      'text' => 'Тема дизайна Orion Shop для Shop-Script позволит вам создать современный и гибкий интернет магазин, оптимизированный для любых устройств.',
      'image' => 'img/500x370.png',
      'buttons' => [
        '1' => [
          'template' => 'button-one',
          'mode' => 'link',
          'link' => '#',
          'target' => '_self',
          'type' => 'primary',
          'wide' => true,
          'anchor' => true,
          'text' => 'Подробнее'
        ]
      ]
    ]
  ],
  'links' => [
    '1' => [
      'text' => 'Скидки и акции',
      'url' => '#',
      'iconset' => '29',
      'icon' => ''
    ],
    '2' => [
      'text' => 'Доставка и оплата',
      'url' => '#',
      'iconset' => '23',
      'icon' => ''
    ],
    '3' => [
      'text' => 'Адреса магазинов',
      'url' => '#',
      'iconset' => '221',
      'icon' => ''
    ]
  ]
]}

{call add_block block="slider" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopSlider from '@/assets/data/shop-slider.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopSlider',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopSlider,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>