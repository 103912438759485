<template>
    <div>
        <div class="documentation__header">
            <app-title text="Блок «О компании»"></app-title>
            <app-go-back
                :link="{
                    name: 'Набор блоков',
                    path: '/documentation/block-list',
                }"
            ></app-go-back>
        </div>

        <app-image
            url="/img/documentation/block_aboutus_1.png"
            alt="Блок «О компании»"
            description="Блок «О компании»"
            border
        />

        <app-subtitle
            text="Вызов блока на страницах сайта"
            label="ID — aboutus"
        />

        <info-box
            text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах"
            :link="infoLink"
        />

        <!-- prettier-ignore -->
        <div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «О компании» --&gt;

{$props = [
    'direction' => 'forward',
    'color_scheme' => 'alter',
    'lazy' => true,
    'no_margin' => false,
    'use_mask' => true,
    'counter' => [
        'template' => 'static-counter-one',
        'color_scheme' => 'alter',
        'image' => 'img/500x570.png',
        'number' => '12',
        'text' => 'Лет опыта'
    ],
    'badge' => 'Лендинг на основе темы «Orion»',
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Зачем нужен лендинг, какой смысл его создавать и что на нем рекламировать?'
    ],
    'buttons' => [
        '1' => [
            'template' => 'button-one',
            'mode' => 'feedback',
            'link' => '#',
            'target' => '_self',
            'type' => 'primary',
            'anchor' => true,
            'wide' => true,
            'icon' => 'phone',
            'text' => 'Заказать консультацию'
        ]
    ],
    'text' => '&lt;p&gt;Landing page — это хороший инструмент, направленный на получение лидов. Его задача — получить контактные данные потенциального клиента (телефон или email) для последующей работы с ними.&lt;/p&gt;&lt;p&gt;Лендинг — одностраничный сайт, весь его контент направлен на то, чтобы побудить человека совершить одно единственное действие. В итоге, какой-то процент посетителей действительно его совершит.&lt;/p&gt;&lt;p&gt;Лендинг подойдет далеко не для всех. Ваше предложение должно быть конкретным и сегментированным, в противном случае вы рискуете потратить слишком большую сумму на рекламу.&lt;/p&gt;'
]}

{call add_block block="aboutus" props=$props}</ssh-pre>
		</div>

        <app-table :data="tableData" />
    </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import IconCopy from '@/components/icons/IconCopy.vue';
import aboutus from '@/assets/data/aboutus.json';
import AppGoBack from '../../components/AppGoBack';

export default {
    name: 'BlockAboutus',
    components: {
        SshPre,
        IconCopy,
        AppGoBack,
    },
    data() {
        return {
            tableData: aboutus,
            infoLink: {
                name: 'Подробнее',
                path: '/documentation/adding-blocks',
            },
        };
    },
};
</script>
