import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    sidebarIsOpened: true,
    useContainer: true,
    modal: {
        show: false,
        content: undefined,
        title: ``,
    },
    themeVersion: new Map([
        ['site', '2.8.0'],
        ['photos', '2.7.0'],
        ['blog', '1.6.0'],
        ['shop', '1.2.0'],
    ]),
    themePrice: {
        site: {
            name: `Orion`,
            price: 3599,
            discount: 15,
            start: new Date(2021, 10, 26),
            duration: 7,
        },
        photos: {
            name: `Orion`,
            price: 0,
            discount: 0,
            start: new Date(2000, 1, 1),
            duration: 0,
        },
        blog: {
            name: `Orion Blog`,
            price: 1799,
            discount: 15,
            start: new Date(2000, 1, 1),
            duration: 7,
        },
        shop: {
            name: `Orion Shop`,
            price: 8399,
            discount: 15,
            start: new Date(2000, 1, 1),
            duration: 7,
        },
    },
};
const mutations = {};

export default new Vuex.Store({
    state,
    mutations,
});
