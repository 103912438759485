<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Баннер»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/blog-app/adding-blocks'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_blog_banner_1.png" alt="Блок «Баннер»" description="Блок «Баннер»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — banner"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Баннер» --&gt;

{$props = [
    'color_scheme' => 'alter',
    'no_margin' => true,
    'image' => [
        'link' => '#',
        'alt' => 'Баннер',
        'mobile' => 'img/710x500.png',
        'tablet' => 'img/1060x300.png',
        'desktop' => 'img/1200x195.png'
    ]
]}

{call add_block block="banner" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import blogBanner from '@/assets/data/blog-banner.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockBlogBanner',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: blogBanner,
            }
        }
    }
</script>