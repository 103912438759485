<template>
    <div class="block-search">
        <div class="block-search__filtering">
            <label for="block-search">Найти блок по ID</label>
            <div class="block-search__wrapper">
                <input id="block-search" v-model="query" type="text" />
                <div class="block-search__icon">
                    <icon-arrow />
                </div>
                <div class="block-search__result">
                    <div
                        class="block-search__links"
                        v-if="typeof findResult(query) === `object`"
                    >
                        <a
                            v-for="(link, key) of findResult(query)"
                            :key="key"
                            :href="link.path"
                            class="block-search__link"
                        >
                            {{ link.meta.name }}
                        </a>
                    </div>
                    <span class="block-search__message" v-else>{{
                        findResult(query)
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconArrow from '@/components/icons/IconArrow.vue';

export default {
    name: `BlockSearch`,
    props: {
        links: Array,
    },
    components: {
        IconArrow,
    },
    data() {
        return {
            query: ``,
        };
    },
    methods: {
        findResult(query) {
            if (!query) return `Начните вводить ID блока`;
            let result = this.links.filter(function (link) {
                let id = link.path.replace(`documentation`, ``);
                return id.includes(query);
            });
            return result.length ? result : `Ничего не найдено`;
        },
    },
};
</script>

<style lang="less">
.block-search {
    margin-bottom: 20px;

    &__filtering {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & label {
            display: inline-block;
            margin-bottom: 5px;
            font-family: @title-font;
            font-size: 0.95em;
            font-weight: bold;
        }

        & input {
            width: 100%;
            max-width: 350px;
            box-sizing: border-box;
            padding: 0.5rem 0.75rem;
            border-radius: 4px;
            border: 1px solid darken(@secondary, 5%);
            background-color: @background;
            transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

            &:focus {
                outline: 0;
                box-shadow: 0 0 0 0.25rem fade(@primary, 20%);
            }
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 350px auto 1fr;
        grid-gap: 1rem;
        align-items: start;
    }

    &__link,
    &__message,
    &__icon {
        display: flex;
        align-items: center;
        height: 33px;
    }

    &__result {
        font-size: 0.95em;
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
    }

    &__link {
        margin-right: 10px;
        padding: 0.5rem 0.75rem;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid transparent;
        background-color: lighten(@secondary, 4%);
        color: @text;
        text-decoration: none;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:hover {
            background-color: @text-inverse;
            border-color: @secondary;
            box-shadow: @medium-shadow;
        }

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }
}

@media (min-width: 480px) {
    .block-search {
        padding: 1.5rem;
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: darken(@secondary, 4%);
        background-color: @background;

        &__filtering {
            & input {
                background-color: lighten(@secondary, 4%);
            }
        }
    }
}
</style>