<template>
    <div>
        <div class="table__filtering">
            <label>Фильтр по свойствам:</label>
            <input class="form-control" v-model="filters.name.value" />
        </div>

        <div class="table__container">
            <v-table class="table" :data="data.rows" :filters="filters">
                <thead class="table__header-row" slot="head">
                    <v-th class="table__header" sortKey="property"
                        >Свойство</v-th
                    >
                    <th class="table__header">Тип данных</th>
                    <th class="table__header">Описание</th>
                </thead>
                <tbody slot="body" slot-scope="{ displayData }">
                    <tr
                        class="table__row"
                        v-for="row in displayData"
                        :key="row.id"
                    >
                        <td class="table__data">{{ row.property }}</td>
                        <td class="table__data">{{ row.type }}</td>
                        <td class="table__data">
                            {{ row.description }}
                            <router-link
                                v-if="row.link"
                                :to="row.link"
                                class="table__link"
                                >Подробнее...</router-link
                            >
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppTable',
    props: ['data'],
    data: () => ({
        filters: {
            name: { value: '', keys: ['property'] },
        },
    }),
};
</script>

<style lang="less">
@table-width: 800px;

.table {
    width: 100%;
    min-width: 500px;
    max-width: @table-width;
    border-collapse: collapse;
    font-size: 0.95em;
    background-color: @text-inverse;

    &__container {
        overflow-x: scroll;
        margin-bottom: 20px;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: @secondary;
        }

        &::-webkit-scrollbar-track {
            background-color: @secondary;
        }

        &::-webkit-scrollbar-thumb {
            background-color: darken(@secondary, 15%);
        }
    }

    &__filtering {
        margin-bottom: 20px;

        & label {
            display: block;
            margin-bottom: 5px;
            font-family: @title-font;
            font-size: 0.95em;
            font-weight: bold;
        }

        & input {
            width: 100%;
            max-width: 350px;
            box-sizing: border-box;
            padding: 0.5rem 0.75rem;
            border-radius: 4px;
            border: 1px solid darken(@secondary, 5%);
            transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

            &:focus {
                outline: 0;
                box-shadow: 0 0 0 0.25rem fade(@primary, 20%);
            }
        }
    }

    &__data,
    &__header {
        padding: 0.4rem 1rem;
        border: 1px solid darken(@secondary, 5%);
        border-collapse: collapse;
        text-align: left;
    }

    &__header {
        font-family: @title-font;
        white-space: nowrap;
    }

    &__header-row {
        background-color: lighten(@secondary, 4%);
    }

    &__row {
        transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:nth-of-type(even) {
            background-color: lighten(@secondary, 4%);
        }

        &:hover {
            background-color: lighten(@secondary, 2%);
        }
    }

    &__link {
        color: @primary;
    }
}

@media (min-width: 768px) {
    .table {
        &__container {
            overflow-x: unset;
        }
    }
}
</style>