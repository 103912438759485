<template>
    <h1
        class="title"
        :class="[
            titleAlign,
            { 'title--oversize': oversize },
            { 'title--inverse': inverse },
        ]"
    >
        {{ text }}
    </h1>
</template>

<script>
export default {
    name: `AppTitle`,
    props: {
        text: String,
        align: {
            type: String,
            default: `left`,
        },
        oversize: {
            type: Boolean,
            default: false,
        },
        inverse: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        titleAlign() {
            return `title--${this.align}`;
        },
    },
};
</script>

<style lang="less">
.title {
    font-family: @title-font;
    font-weight: 600;
    margin-bottom: 20px;

    &--left {
        text-align: left;
    }

    &--center {
        text-align: center;
        max-width: 800px;
        margin-right: auto;
        margin-left: auto;
    }

    &--right {
        text-align: right;
    }

    &--oversize {
        font-size: 34px;
    }

    &--inverse {
        color: @text-inverse;
    }
}

@media (min-width: 992px) {
    .title {
        &--oversize {
            font-size: 42px;
        }
    }
}
</style>