<template>
	<div>
		<app-title text="Добавление блоков и элементов c помощью Smarty" />

		<p class="documentation__text">
			Добавление блоков на <accent text="главную страницу"/> осуществляется через графический интерфейс.
			Во фронтенде перейдите на вкладку «Главная страница» 🠖 «Порядок блоков», включите нужные блоки и настройте их порядок.
		</p>

		<p class="documentation__text">
			На все <accent text="остальные страницы"/> блоки добавляются по следующей схеме:
		</p>

		<app-subtitle text="Подготовка страницы"/>

		<p class="documentation__text">
			В бэкенде Webasyst создайте новую страницу и перейдите в режим редактирования HTML.
		</p>

		<app-image url="/img/documentation/adding_blocks_1.png" alt="Добавление блоков" />

		<p class="documentation__text">
			Далее, <b>один раз</b> в начале страницы необходимо подключить файл с утилитами.
		</p>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>{include "{$wa->site->themePath(waRequest::getTheme())}utils.html"}</ssh-pre>
		</div>

		<p class="documentation__text">
			Затем, <b>также один раз на страницу</b>, нужно поставить переменную <accent text="userCode"/> в значение true,
			это уберет стандартные стили страницы. И выставить настройки кнопок и заголовков страницы.
		</p>

		<app-list text="Переменная $_primary_font отвечает за шрифт заголовков и может иметь одно из следующих значений: 'exo', 'mulish', 'alice', 'nunito', 'raleway', 'tenor', 'jura', 'philosopher', 'commissioner', 'lora', 'source' или 'jost'; Переменная $_button_type задает стиль кнопок и может иметь одно из следующих значений: 'button-one', 'button-two' или 'button-three'" />

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>{$wa->globals("userCode", true)}
{$_primary_font = 'philosopher'}
{$_button_type = 'button-one'}</ssh-pre>
		</div>

		<p class="documentation__text">
			Далее вы можете добавлять блоки в любом количестве или любой ваш HTML код.
		</p>

		<app-subtitle text="Добавление блоков" line/>

		<p class="documentation__text">
			Добавим блок на страницу на примере <router-link class="documentation__link" to="/documentation/gallery">блока «Этапы»</router-link>. Для этого нужно добавить Smarty код для вызова блока, который можно получить <b>одним из двух</b> способов:
		</p>

		<app-image-block :reverse="true" :image="{url: '/img/documentation/adding_blocks_2.png', alt: 'Добавление блоков'}">
			<ul class="list">
				<li class="list__item">Откройте админ-панель, настройте нужный блок и сохраните изменения. После перезагрузки страницы нажмите кнопку «Получить код»,
					затем скопируйте его и вставьте на требуемую страницу. В результате вы получите точную копию блока с главной страницы.
				</li>
				<li class="list__item">
					Либо добавьте блок из раздела документации <router-link class="documentation__link" to="/documentation/block-list">«Набор блоков»</router-link>. Скопируйте Smarty код и отредактируйте настройки вручную. Каждое свойство описано в документации и повторяет графический интерфейс темы.
				</li>
			</ul>
		</app-image-block>

		<p class="documentation__text">
			Сначала в коде создается ассоциативный массив <accent text="$props"/>, в котором будут описаны все настройки и контент блока.
			Props - это сокращение от properties, то есть свойства.
			Затем вызывается нужный блок, в который передается переменная <accent text="$props"/>.
		</p>

		<app-subtitle text="Итоговый результат"/>

		<p class="documentation__text">
			В итоге все вместе будет выглядеть так:
		</p>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Подключает утилиты --&gt;
{include "{$wa->site->themePath(waRequest::getTheme())}utils.html"}

&lt;!-- Убирает стандартные стили --&gt;
{$wa->globals("userCode", true)}

&lt;!-- Настройки шрифта и кнопок --&gt;
{$_primary_font = 'philosopher'}
{$_button_type = 'button-one'}

&lt;!-- Настройки блока «Этапы» --&gt;
{$props = [
    'color_scheme' => 'main',
    'icon_style' => 'is-linear',
    'title' => [
        'align' => 'center',
        'size' => 'medium, H2',
        'text' => 'Этапы установки темы'
    ],
    'items' => [
        '1' => [
            'iconset' => '1',
            'icon' => '',
            'name' => 'Установка Webasyst',
            'desc' => 'Скачайте архив со скриптами фреймворка Webasyst, распакуйте и загрузите их на удаленный хостинг или на локальный сервер.'
        ],
        '2' => [
            'iconset' => '2',
            'icon' => '',
            'name' => 'Установка приложений',
            'desc' => 'В бэкенде фреймворка создайте требуемые страницы в приложении «Сайт», установите приложение «Фото» и необходимые вам плагины.'
        ],
        '3' => [
            'iconset' => '3',
            'icon' => '',
            'name' => 'Приобретение темы Orion',
            'desc' => 'В приложении «Сайт» перейдите во вкладку «Дизайн» и выберите раздел «Темы дизайна». Вбейте в поиск Orion, выберите тему и нажмите «Купить или активировать».'
        ],
        '4' => [
            'iconset' => '4',
            'icon' => '',
            'name' => 'Настройка и наполнения контентом',
            'desc' => 'Во вкладке «Дизайн» перейдите в раздел «Оформление» и начните кастомизмровать сайт.'
        ]
    ]
]}

&lt;!-- Вызов блока --&gt;
{call add_block block="steps" props=$props}</ssh-pre>
		</div>

		<p class="documentation__text">
			Если нужно добавить <b>несколько одинаковых блоков</b> на одну страницу, то следует
			изменить id каждого блока так, чтобы они были уникальными. Для этого добавьте в
			<accent text="$props"/> стоку <accent text="'id' => 'НОВЫЙ_ID',"/>
		</p>

		<p class="documentation__text">
			В некоторых блоках используется встроенный <router-link class="documentation__link" to="/documentation/icon-set">набор иконок</router-link>.
		</p>
		<app-subtitle text="Свой HTML" line/>

		<p class="documentation__text">
			Между блоками допускается использование своей HTML разметки.
		</p>

		<div class="documentation__code">
			<ssh-pre language="html" label="HTML" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>{include "{$wa->site->themePath(waRequest::getTheme())}utils.html"}
{$wa->globals("userCode", true)}
{$_primary_font = 'philosopher'}
{$_button_type = 'button-one'}

...

&lt;section class=&quot;frame frame--main&quot;&gt;
    &lt;div class=&quot;frame__container&quot;&gt;
        &lt;div class=&quot;frame__center&quot;&gt;
            &lt;!-- Ваш HTML здесь --&gt;
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/section&gt;</ssh-pre>
		</div>

		<p class="documentation__text">
			Внутри своей HTML разметки можно использовать <router-link class="documentation__link" to="/documentation/elements-list">элементы темы</router-link>:
		</p>

		<div class="documentation__code">
			<ssh-pre language="html" label="HTML" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;section class=&quot;frame frame--main&quot;&gt;
    &lt;div class=&quot;frame__container&quot;&gt;
        &lt;div class=&quot;frame__center&quot;&gt;

            &lt;p style=&quot;margin-bottom: 20px&quot;&gt;Lorem Ipsum - это текст-рыба, часто используемый в печати.&lt;/p&gt;

            &lt;!-- Элемент «Кнопка» --&gt;
            {$props = [
                'template' => 'button-one',
                'mode' => 'feedback',
                'link' => '',
                'target' => '_self',
                'type' => 'primary',
                'anchor' => true,
                'icon' => 'none',
                'text' => 'Заказать звонок'
            ]}

            {call add_element element="button/button" props=$props}

        &lt;/div&gt;
    &lt;/div&gt;
&lt;/section&gt;</ssh-pre>
		</div>

		<app-subtitle text="Добавление элементов" line/>

		<p class="documentation__text">
			Также <router-link class="documentation__link" to="/documentation/elements-list">элементы</router-link> можно
			добавить непосредственно на страницу, которую вы создали в визуальном редакторе Webasyst (начиная с версии темы 2.5.0).
			Для этого потребуется переключиться в режим HTML и в начале страницы подключить файл с утилитами.
			Далее добавить настройки шрифта, кнопок и вставить сам элемент. Пример использования:
		</p>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Подключает утилиты --&gt;
{include "{$wa->site->themePath(waRequest::getTheme())}utils.html"}

&lt;!-- Настройки шрифта и кнопок --&gt;
{$_primary_font = 'philosopher'}
{$_button_type = 'button-one'}

&lt;!-- Настройки элемента «Заголовок статьи» --&gt;
{$props = [
    'align' => 'right',
    'lazy' => false,
    'gradient' => true,
    'background' => 'img/900x330.png',
    'color' => '#ffffff',
    'title' => 'Есть много вариантов Lorem Ipsum',
    'text' => 'Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов, но это не совсем так.'
]}

&lt;!-- Вызов элемента --&gt;
{call add_element element="article-title" props=$props}

&lt;br&gt;
&lt;p&gt;Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.&lt;/p&gt;</ssh-pre>
		</div>

		<info-box text="Нашли ошибку теме или документации? Сообщите о ней на support@active-vision.ru" :link="infoLink"/>

	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'

    export default {
        name: 'AddingBlocks',
        components: {
            SshPre,
            IconCopy
        },
        data() {
            return {
                infoLink: {}
            }
        }
    }
</script>

