<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Рекомендации»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/blog-app/adding-blocks'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_blog_featured_1.png" alt="Блок «Рекомендации»" description="Блок «Рекомендации»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — featured"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Рекомендации» --&gt;

{$props = [
    'color_scheme' => 'alter',
    'no_margin' => false,
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Рекомендуемые статьи'
    ],
    'items' => [
        '1' => [
            'id' => '1',
            'lazy' => true,
            'show_views' => true,
            'show_comments' => true,
            'color_scheme' => 'alter',
            'preview' => '/wa-apps/blog/themes/orion_blog/img/580x680.png',
            'width' => '580',
            'height' => '670',
            'shadow' => true
        ],
        '2' => [
            'id' => '1',
            'lazy' => true,
            'show_views' => true,
            'show_comments' => true,
            'color_scheme' => 'alter',
            'preview' => '/wa-apps/blog/themes/orion_blog/img/580x680.png',
            'width' => '580',
            'height' => '670',
            'shadow' => true
        ],
        '3' => [
            'id' => '1',
            'lazy' => true,
            'show_views' => true,
            'show_comments' => true,
            'color_scheme' => 'alter',
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ],
        '4' => [
            'id' => '1',
            'lazy' => true,
            'show_views' => true,
            'show_comments' => true,
            'color_scheme' => 'alter',
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ],
        '5' => [
            'id' => '1',
            'lazy' => true,
            'show_views' => true,
            'show_comments' => true,
            'color_scheme' => 'alter',
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ]
    ]
]}

{call add_block block="featured-posts" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import blogFeatured from '@/assets/data/blog-featured.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockBlogFeatured',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: blogFeatured
            }
        }
    }
</script>