<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Блог»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_blog_1.png" alt="Блок «Блог»" description="Блок «Блог»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — blog"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Блог» --&gt;

{$props = [
    'content' => 'settings',
    'color_scheme' => 'alter',
    'no_margin' => false,
    'blog_id' => '1',
    'limit' => '5',
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Новости компании'
    ],
    'posts' => [
        '1' => [
            'color_scheme' => 'alter',
            'title' => 'Есть много вариантов Lorem Ipsum',
            'user' => [
                'name' => 'Иван'
            ],
            'datetime' => '2021-01-30',
            'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
            'link' => '#'
        ],
        '2' => [
            'color_scheme' => 'alter',
            'title' => 'Этот трактат по теории этики был очень популярен в эпоху Возрождения',
            'user' => [
                'name' => 'Анна'
            ],
            'datetime' => '2021-01-30',
            'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
            'link' => '#'
        ],
        '3' => [
            'color_scheme' => 'alter',
            'title' => 'Многие думают, что Lorem Ipsum - взятый с потолка',
            'user' => [
                'name' => 'Сергей'
            ],
            'datetime' => '2021-01-30',
            'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
            'link' => '#'
        ],
        '4' => [
            'color_scheme' => 'alter',
            'title' => 'Этот трактат по теории этики был очень популярен в эпоху Возрождения',
            'user' => [
                'name' => 'Владимир'
            ],
            'datetime' => '2021-01-30',
            'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
            'link' => '#'
        ],
        '5' => [
            'color_scheme' => 'alter',
            'title' => 'Lorem Ipsum - это текст-рыба, часто используемый в печати и вэб-дизайне',
            'user' => [
                'name' => 'Дмитрий'
            ],
            'datetime' => '2021-01-30',
            'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
            'link' => '#'
        ]
    ]
]}

{call add_block block="blog" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import blog from '@/assets/data/blog.json'
    import AppGoBack from "../../components/AppGoBack";

    export default {
        name: 'BlockBlog',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: blog,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>