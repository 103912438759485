import { render, staticRenderFns } from "./ElementTitle.vue?vue&type=template&id=3153f5c2&"
import script from "./ElementTitle.vue?vue&type=script&lang=js&"
export * from "./ElementTitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports