<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Сетка»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/blog-app/adding-blocks'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_blog_grid_1.png" alt="Блок «Сетка»" description="Блок «Сетка»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — grid"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Сетка» --&gt;

{$props = [
    'color_scheme' => 'main',
    'no_margin' => false,
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => ''
    ],
    'grid_content' => 'settings',
    'blog_id' => '1',
    'grid' => '1, 1, 1, 1, 1, 1'
]}

{call add_block block="grid" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import blogGrid from '@/assets/data/blog-grid.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockBlogGrid',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: blogGrid
            }
        }
    }
</script>