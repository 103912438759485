<template>
    <nav class="navigation" :class="{ 'is-opened': opened }">
        <ul class="navigation__list">
            <li
                class="navigation__item"
                v-for="(link, key) in links"
                :key="key"
            >
                <router-link
                    v-if="!link.meta.hidden"
                    :exact="determineExact(link.path)"
                    :to="link.path"
                    active-class="is-active"
                    >{{ link.meta.name }}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'Navigation',
    props: {
        links: Array,
        opened: Boolean,
    },
    methods: {
        determineExact(url) {
            return url === `/`;
        },
    },
};
</script>

<style lang="less">
.navigation {
    display: none;
    position: absolute;
    right: 0;
    top: 45px;
    font-size: 0.95em;

    &.is-opened {
        display: block;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        padding: 0.75rem 1rem;
        background-color: @text-inverse;
        border-radius: 4px;
        border: 1px solid @secondary;
        box-shadow: @medium-shadow;

        &::before {
            content: '';
            position: absolute;
            top: -21px;
            right: 10px;
            display: block;
            width: 22px;
            height: 22px;
            box-sizing: border-box;
            background-color: @text-inverse;
            clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
        }
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        & > a {
            white-space: nowrap;
            text-decoration: none;
            font-size: 0.95em;
            color: @text;
        }
    }
}

@media (min-width: 1200px) {
    .navigation {
        display: block;
        position: relative;
        right: unset;
        top: unset;
        height: 100%;

        &__list {
            flex-direction: row;
            align-items: center;
            gap: 2em;
            height: 100%;
            padding: unset;
            background-color: unset;
            border-radius: unset;
            border: unset;
            box-shadow: unset;

            &::before {
                display: none;
            }
        }

        &__item {
            & > a {
                &.is-active {
                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 2px;
                        background-color: @primary;
                    }
                }
            }
        }
    }
}
</style>