<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Призыв к действию»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_cta_1.png" alt="Блок «Призыв к действию»" description="Блок «Призыв к действию»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — cta"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Призыв к действию» --&gt;

{$props = [
  'template' => 'one',
  'color_scheme' => 'main',
  'no_margin' => false,
  'title' => [
    'size' => 'medium, H2',
    'text' => 'Как работает бесплатная доставка?'
  ],
  'text' => 'На этот и другие вопросы Вам с радостью ответят наши специалисты.',
  'buttons' => [
    '1' => [
      'template' => 'button-one',
      'mode' => 'feedback',
      'link' => '#',
      'target' => '_self',
      'type' => 'primary',
      'anchor' => true,
      'wide' => true,
      'icon' => 'phone',
      'text' => 'Заказать звонок'
    ]
  ]
]}

{call add_block block="cta" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopCta from '@/assets/data/shop-cta.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopCta',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopCta,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>