<template>
    <nav class="side-navigation">
        <ul class="side-navigation__list">
            <li class="side-navigation__item">
                <span class="side-navigation__group"
                    ><span>Демо-версии</span></span
                >
                <router-link to="/">Список демо-версий</router-link>
            </li>
            <li class="side-navigation__item">
                <a
                    href="https://orion.active-vision.ru/demo/demo-one/"
                    target="_blank"
                    >Пример сайта</a
                >
            </li>
            <li class="side-navigation__item">
                <a
                    href="https://orion.active-vision.ru/demo/demo-two/"
                    target="_blank"
                    >Пример лендинга</a
                >
            </li>
            <li class="side-navigation__item">
                <a
                    href="https://orion.active-vision.ru/demo/shop/"
                    target="_blank"
                    >Пример магазина</a
                >
            </li>
            <li class="side-navigation__item">
                <a
                    href="https://orion.active-vision.ru/demo/blog/"
                    target="_blank"
                    >Пример блога</a
                >
            </li>
            <li class="side-navigation__item">
                <a
                    href="https://orion.active-vision.ru/demo/photos/"
                    target="_blank"
                    >Пример фотогалереи</a
                >
            </li>
            <li
                class="side-navigation__item"
                v-for="(link, key) in navigation"
                :key="key"
            >
                <span class="side-navigation__group" v-if="link.meta.group"
                    ><span>{{ link.meta.group }}</span></span
                >
                <span
                    class="side-navigation__subgroup"
                    v-if="link.meta.subgroup"
                    ><span>{{ link.meta.subgroup }}</span></span
                >
                <router-link exact :to="link.path" active-class="is-active">
                    {{ link.meta.name }}
                    <span
                        v-if="link.meta.marked"
                        class="side-navigation__marker"
                        >new</span
                    >
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'Sidebar',
    props: {
        links: Array,
    },
    computed: {
        navigation() {
            return this.links.filter(function (link) {
                return link.meta.type === `page`;
            });
        },
    },
};
</script>

<style lang="less">
.side-navigation {
    max-height: calc(100vh - 150px);
    height: 100%;
    padding: 2rem 1.5rem 1.5rem;
    box-sizing: border-box;
    overflow-y: scroll;
    font-size: 0.95em;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: lighten(@secondary, 4%);
    }

    &::-webkit-scrollbar-track {
        background-color: lighten(@secondary, 4%);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darken(@secondary, 15%);
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &__item > a {
        position: relative;
        font-size: 0.95em;
        color: @text;
        text-decoration: none;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: -3px;
            left: auto;
            box-sizing: border-box;
            width: 0;
            height: 2px;
            transition: 0.25s ease all;
            background-color: @primary;
        }

        &.is-active::after,
        &:hover::after {
            width: 100%;
            left: 0;
            right: auto;
            transition-duration: 0.5s;
        }
    }

    &__item {
        &:not(:first-of-type) {
            & .side-navigation__group {
                margin-top: 20px;
            }
        }
    }

    &__marker {
        position: absolute;
        top: 50%;
        left: calc(100% + 10px);
        transform: translateY(-50%);
        display: block;
        padding: 0.3em 0.6em;
        border-radius: 4px;
        font-size: 10px;
        line-height: 10px;
        text-transform: uppercase;
        background-color: darken(@secondary, 15%);
        color: @text-inverse;
    }

    &__group {
        display: block;
        margin-bottom: 10px;

        & span {
            display: inline-block;
            padding-left: 0.6rem;
            padding-right: 0.6rem;
            border-radius: 4px;
            background-color: @primary;
            color: @text-inverse;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            font-size: 0.75em;
        }
    }

    &__subgroup {
        display: block;
        margin-bottom: 10px;
        margin-top: 10px;

        & span {
            font-family: @title-font;
            font-weight: 600;
            color: @primary;
        }
    }
}
</style>