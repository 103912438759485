<template>
  <div>
    <app-title text="Каталог" />

    <p class="documentation__text">
      Примеры настроенных категорий из демо-версии темы:
    </p>

    <ul class="list">
      <li class="list__item">
        <a class="documentation__link" target="_blank" rel="nofollow" href="/demo/shop-one/category/tekstil/">Пример страницы</a> с фильтром и сортировкой.
      </li>
      <li class="list__item">
        <a class="documentation__link" target="_blank" rel="nofollow" href="/demo/shop-one/category/osveshchenie/">Страница</a> с подкатегориями.
      </li>
    </ul>

    <p class="documentation__text">
      Перейдите в бэкенд магазина, во вкладке <accent text="Товары"/> в боковом меню «Категории» создайте разные категории для ваших товаров, тема поддерживает несколько уровней вложенности.
    </p>

    <p class="documentation__text">
      Нажмите на созданную категорию, и далее на кнопку <accent text="Настройки категории"/> с иконкой шестеренки.
    </p>

    <app-image url="/img/documentation/shop_category_1.png" alt="Shop Script настройки категории" />

    <p class="documentation__text">
      В открывшемся меню настроек включите сортировку товаров.
    </p>

    <app-image url="/img/documentation/shop_category_2.png" alt="Orion Shop настройки категории" />

    <p class="documentation__text">
      Если требуется, разрешите фильтрацию товаров и отметьте характеристики по которым можно будет фильтровать товары.
    </p>

    <app-image url="/img/documentation/shop_category_3.png" alt="Orion Shop фильтрация" />

    <app-subtitle text="Категории"/>

    <p class="documentation__text">
      В список категорий можно добавить изображения, иконки и наклейки.
    </p>

    <app-image url="/img/documentation/shop_category_5.png" alt="Orion Shop список категорий" />

    <p class="documentation__text">
      Небольшие картинки можно добавить с помощью плагина <a href="https://www.webasyst.ru/store/plugin/shop/skcatimage/" class="documentation__link" target="_blank">«Изображения для категории»</a>, либо через <accent text="Дополнительные параметры"/> категории:
    </p>

    <app-list text="«image» — ссылка на изображение относительно папки с темой;«icon» — число, порядковый номер иконки из набора;«badge» — название наклейки; «badge_color» — цвет наклейки" />

    <app-image url="/img/documentation/shop_category_6.png" alt="Orion Shop список категорий" />

    <p class="documentation__text">
      Узнать номер нужной вам иконки вы можете на странице <router-link class="documentation__link" to="/documentation/icon-set">встроенный набор иконок</router-link>.
    </p>

    <app-subtitle text="Дополнительные возможности"/>

    <p class="documentation__text">
      В настройках категории в поле <accent text="Дополнительные параметры"/> вы можете добавить элементы темы или ваш HTML код в одно из следующих мест:
    </p>

    <app-list text="«sidebar_before_hook» —  id блока, который будет добавлен перед сайдбаром;«sidebar_after_hook» — id блока, который будет добавлен после сайдбара" />

    <p class="documentation__text">
      Создайте в приложении <accent text="Сайт"/> 🠖 <accent text="Блоки"/> блок, например <b>shop.before_sidebar</b>, с вашим контентом и укажите его название в выбранном параметре.
    </p>

    <app-image url="/img/documentation/shop_category_4.png" alt="Orion Shop дополнительные параметры" />

    <p class="documentation__text">
      В каталоге возможны три вида карточек товаров: thumbs, list и table. Это значение можно задать отдельно для каждой категории через дополнительные параметры.
      Например, если указать <b>view_type=table</b>, в выбранной категории карточки будут всегда отображаться в виде таблицы, пока пользователь не поменяет это через кнопки изменения вида.
    </p>

  </div>
</template>

<script>
export default {
  name: 'ShopCatalog',
  data() {
    return {

    }
  },
}
</script>