<template>
	<div>
		<div class="documentation__header">
			<app-title text="Элемент «Хлебные крошки»"></app-title>
			<app-go-back :link="{name: 'Набор элементов', path: '/documentation/elements-list'}"></app-go-back>
		</div>

		<app-subtitle text="Вызов элемента на страницах сайта"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову элементов на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Элемент «Хлебные крошки» --&gt;
{$props = [
  'color_scheme' => 'main',
  'shadow' => false,
  'homepage_url' => 'root'
]}

{call add_element element="breadcrumbs" props=$props app='site' breadcrumbs=$breadcrumbs}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import breadcrumbs from '@/assets/data/element-breadcrumbs.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'ElementBreadcrumbs',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: breadcrumbs,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>