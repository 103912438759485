import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home';
import Support from '../views/Support';
import Start from '../views/documentation/Start';
import VideoGuides from '../views/documentation/VideoGuides';

import HomepageBlocks from '../views/documentation/HomepageBlocks';
import BlockBlog from '../views/documentation/BlockBlog';
import BlockBanners from '../views/documentation/BlockBanners';
import BlockVideo from '../views/documentation/BlockVideo';
import BlockTabs from '../views/documentation/BlockTabs';
import BlockFaq from '../views/documentation/BlockFaq';
import BlockGallery from '../views/documentation/BlockGallery';
import BlockMainscreen from '../views/documentation/BlockMainscreen';
import BlockAction from '../views/documentation/BlockAction';
import BlockCards from '../views/documentation/BlockCards';
import BlockCarousel from '../views/documentation/BlockCarousel';
import BlockColumns from '../views/documentation/BlockColumns';
import BlockContacts from '../views/documentation/BlockContacts';
import BlockAboutus from '../views/documentation/BlockAboutus';
import BlockDescription from '../views/documentation/BlockDescription';
import BlockFeatures from '../views/documentation/BlockFeatures';
import BlockReviews from '../views/documentation/BlockReviews';
import BlockAdvantages from '../views/documentation/BlockAdvantages';
import BlockCta from '../views/documentation/BlockCta';
import BlockProjects from '../views/documentation/BlockProjects';
import BlockSlider from '../views/documentation/BlockSlider';
import BlockList from '../views/documentation/BlockList';
import BlockStatistics from '../views/documentation/BlockStatistics';
import BlockPage from '../views/documentation/BlockPage';
import BlockPricing from '../views/documentation/BlockPricing';
import BlockProducts from '../views/documentation/BlockProducts';
import BlockServices from '../views/documentation/BlockServices';
import BlockSixcards from '../views/documentation/BlockSixcards';
import BlockSteps from '../views/documentation/BlockSteps';
import BlockTable from '../views/documentation/BlockTable';
import BlockStory from '../views/documentation/BlockStory';

import BlockBlogBanner from '../views/documentation/BlockBlogBanner';
import BlockBlogTrending from '../views/documentation/BlockBlogTrending';
import BlockBlogCarousel from '../views/documentation/BlockBlogCarousel';
import BlockBlogCategories from '../views/documentation/BlockBlogCategories';
import BlockBlogFeatured from '../views/documentation/BlockBlogFeatured';
import BlockBlogGrid from '../views/documentation/BlockBlogGrid';
import BlockBlogMainscreen from '../views/documentation/BlockBlogMainscreen';
import BlockBlogSlider from '../views/documentation/BlockBlogSlider';
import BlockBlogStream from '../views/documentation/BlockBlogStream';

import BlockShopAccordion from '../views/documentation/BlockShopAccordion';
import BlockShopPromotions from '../views/documentation/BlockShopPromotions';
import BlockShopBanner from '../views/documentation/BlockShopBanner';
import BlockShopBlog from '../views/documentation/BlockShopBlog';
import BlockShopBrands from '../views/documentation/BlockShopBrands';
import BlockShopTabs from '../views/documentation/BlockShopTabs';
import BlockShopCards from '../views/documentation/BlockShopCards';
import BlockShopCarousel from '../views/documentation/BlockShopCarousel';
import BlockShopCatalog from '../views/documentation/BlockShopCatalog';
import BlockShopColumns from '../views/documentation/BlockShopColumns';
import BlockShopContacts from '../views/documentation/BlockShopContacts';
import BlockShopTags from '../views/documentation/BlockShopTags';
import BlockShopNew from '../views/documentation/BlockShopNew';
import BlockShopReviews from '../views/documentation/BlockShopReviews';
import BlockShopAdvantages from '../views/documentation/BlockShopAdvantages';
import BlockShopCta from '../views/documentation/BlockShopCta';
import BlockShopViewed from '../views/documentation/BlockShopViewed';
import BlockShopGrid from '../views/documentation/BlockShopGrid';
import BlockShopSlider from '../views/documentation/BlockShopSlider';
import BlockShopPayments from '../views/documentation/BlockShopPayments';
import BlockShopText from '../views/documentation/BlockShopText';
import BlockShopSale from '../views/documentation/BlockShopSale';
import BlockShopProducts from '../views/documentation/BlockShopProducts';
import BlockShopStory from '../views/documentation/BlockShopStory';

import ElementLink from '../views/documentation/ElementLink';
import ElementTitle from '../views/documentation/ElementTitle';
import ElementList from '../views/documentation/ElementList';
import ElementStaticCounter from '../views/documentation/ElementStaticCounter';
import ElementButton from '../views/documentation/ElementButton';
import ElementFaq from '../views/documentation/ElementFaq';
import ElementCard from '../views/documentation/ElementCard';
import ElementPostcard from '../views/documentation/ElementPostcard';

import ElementShopFaq from '../views/documentation/ElementShopFaq';
import ElementShopTitle from '../views/documentation/ElementShopTitle';
import ElementShopCard from '../views/documentation/ElementShopCard';
import ElementShopButton from '../views/documentation/ElementShopButton';
import ElementShopLine from '../views/documentation/ElementShopLine';
import ElementShopLink from '../views/documentation/ElementShopLink';
import ElementShopPostcard from '../views/documentation/ElementShopPostcard';
import ElementShopList from '../views/documentation/ElementShopList';
import ElementShopItem from '../views/documentation/ElementShopItem';
import ElementShopProduct from '../views/documentation/ElementShopProduct';

import NewPage from '../views/documentation/NewPage';
import AddingBlocks from '../views/documentation/AddingBlocks';
import Photos from '../views/documentation/Photos';

import ShopApp from '../views/documentation/ShopApp';
import ShopHomepage from '../views/documentation/ShopHomepage';
import ShopProduct from '../views/documentation/ShopProduct';
import ShopCatalog from '../views/documentation/ShopCatalog';
import ShopAddingBlocks from '../views/documentation/ShopAddingBlocks';

import BlogApp from '../views/documentation/BlogApp';
import BlogHomepage from '../views/documentation/BlogHomepage';
import BlogPosts from '../views/documentation/BlogPosts';
import BlogAddingBlocks from '../views/documentation/BlogAddingBlocks';

import Update from '../views/documentation/Update';
import UpdateList from '../views/documentation/UpdateList';
import AppError from '../components/AppError';
import InstallingArchive from '../views/documentation/InstallingArchive';
import ListOfBlocks from '../views/documentation/ListOfBlocks';
import ListOfElements from '../views/documentation/ListOfElements';
import IconSet from '../views/documentation/IconSet';
import ElementArticleTitle from '../views/documentation/ElementArticleTitle';
import ElementBreadcrumbs from '../views/documentation/ElementBreadcrumbs';

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        component: AppError,
        meta: {
            name: '404',
            hidden: true,
        },
    },
    {
        path: '/',
        component: Home,
        meta: {
            name: 'Демоверсии',
        },
    },
    {
        path: '/documentation',
        name: 'Documentation',
        component: () => import('../views/Documentation.vue'),
        meta: {
            name: 'Документация',
            breadcrumb: [{ name: 'Документация' }],
        },
        children: [
            {
                path: '/documentation',
                component: Start,
                meta: {
                    name: 'Установка и настройка',
                    type: 'page',
                    group: 'Приложение «Сайт»',
                    breadcrumb: [{ name: 'Документация' }],
                },
            },
            {
                path: '/documentation/homepage-blocks',
                component: HomepageBlocks,
                meta: {
                    name: 'Главная страница',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Главная страница' },
                    ],
                },
            },
            {
                path: '/documentation/new-page',
                component: NewPage,
                meta: {
                    name: 'Создание новых страниц',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Создание новых страниц' },
                    ],
                },
            },
            {
                path: '/documentation/photos',
                component: Photos,
                meta: {
                    name: 'Фотогалерея',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Фотогалерея' },
                    ],
                },
            },
            {
                path: '/documentation/video-guides',
                component: VideoGuides,
                meta: {
                    name: 'Видео-уроки',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Видео-уроки' },
                    ],
                },
            },
            {
                path: '/documentation/banners',
                component: BlockBanners,
                meta: {
                    name: 'Блок «Баннеры»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Баннеры»' },
                    ],
                },
            },
            {
                path: '/documentation/blog',
                component: BlockBlog,
                meta: {
                    name: 'Блок «Блог»',
                    type: 'block',
                    category: 'cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Блог»' },
                    ],
                },
            },
            {
                path: '/documentation/video',
                component: BlockVideo,
                meta: {
                    name: 'Блок «Видео»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Видео»' },
                    ],
                },
            },
            {
                path: '/documentation/tabs',
                component: BlockTabs,
                meta: {
                    name: 'Блок «Вкладки»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Вкладки»' },
                    ],
                },
            },
            {
                path: '/documentation/faq',
                component: BlockFaq,
                meta: {
                    name: 'Блок «Вопрос-ответ»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Вопрос-ответ»' },
                    ],
                },
            },
            {
                path: '/documentation/gallery',
                component: BlockGallery,
                meta: {
                    name: 'Блок «Галерея»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Галерея»' },
                    ],
                },
            },
            {
                path: '/documentation/mainscreen',
                component: BlockMainscreen,
                meta: {
                    name: 'Блок «Главный экран»',
                    type: 'block',
                    category: 'fullscreen',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Главный экран»' },
                    ],
                },
            },
            {
                path: '/documentation/action',
                component: BlockAction,
                meta: {
                    name: 'Блок «Действие»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Действие»' },
                    ],
                },
            },
            {
                path: '/documentation/cards',
                component: BlockCards,
                meta: {
                    name: 'Блок «Карточки»',
                    type: 'block',
                    category: 'cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Карточки»' },
                    ],
                },
            },
            {
                path: '/documentation/carousel',
                component: BlockCarousel,
                meta: {
                    name: 'Блок «Карусель»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Карусель»' },
                    ],
                },
            },
            {
                path: '/documentation/columns',
                component: BlockColumns,
                meta: {
                    name: 'Блок «Колонки»',
                    type: 'block',
                    category: 'text',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Колонки»' },
                    ],
                },
            },
            {
                path: '/documentation/contacts',
                component: BlockContacts,
                meta: {
                    name: 'Блок «Контакты»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Контакты»' },
                    ],
                },
            },
            {
                path: '/documentation/aboutus',
                component: BlockAboutus,
                meta: {
                    name: 'Блок «О компании»',
                    type: 'block',
                    category: 'text',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «О компании»' },
                    ],
                },
            },
            {
                path: '/documentation/description',
                component: BlockDescription,
                meta: {
                    name: 'Блок «Описание»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Описание»' },
                    ],
                },
            },
            {
                path: '/documentation/features',
                component: BlockFeatures,
                meta: {
                    name: 'Блок «Особенности»',
                    type: 'block',
                    category: 'list',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Особенности»' },
                    ],
                },
            },
            {
                path: '/documentation/reviews',
                component: BlockReviews,
                meta: {
                    name: 'Блок «Отзывы»',
                    type: 'block',
                    category: 'cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Отзывы»' },
                    ],
                },
            },
            {
                path: '/documentation/advantages',
                component: BlockAdvantages,
                meta: {
                    name: 'Блок «Преимущества»',
                    type: 'block',
                    category: 'cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Преимущества»' },
                    ],
                },
            },
            {
                path: '/documentation/cta',
                component: BlockCta,
                meta: {
                    name: 'Блок «Призыв к действию»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Призыв к действию»' },
                    ],
                },
            },
            {
                path: '/documentation/projects',
                component: BlockProjects,
                meta: {
                    name: 'Блок «Проекты»',
                    type: 'block',
                    category: 'text',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Проекты»' },
                    ],
                },
            },
            {
                path: '/documentation/story',
                component: BlockStory,
                meta: {
                    name: 'Блок «Рассказ»',
                    type: 'block',
                    category: 'text',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Рассказ»' },
                    ],
                },
            },
            {
                path: '/documentation/slider',
                component: BlockSlider,
                meta: {
                    name: 'Блок «Слайдер»',
                    type: 'block',
                    category: 'fullscreen',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Слайдер»' },
                    ],
                },
            },
            {
                path: '/documentation/list',
                component: BlockList,
                meta: {
                    name: 'Блок «Список»',
                    type: 'block',
                    category: 'list',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Список»' },
                    ],
                },
            },
            {
                path: '/documentation/statistics',
                component: BlockStatistics,
                meta: {
                    name: 'Блок «Статистика»',
                    type: 'block',
                    category: 'interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Статистика»' },
                    ],
                },
            },
            {
                path: '/documentation/page',
                component: BlockPage,
                meta: {
                    name: 'Блок «Страница»',
                    type: 'block',
                    category: 'text',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Страница»' },
                    ],
                },
            },
            {
                path: '/documentation/table',
                component: BlockTable,
                meta: {
                    name: 'Блок «Таблица»',
                    type: 'block',
                    category: 'text',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Таблица»' },
                    ],
                },
            },
            {
                path: '/documentation/pricing',
                component: BlockPricing,
                meta: {
                    name: 'Блок «Тарифы»',
                    type: 'block',
                    category: 'cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Тарифы»' },
                    ],
                },
            },
            {
                path: '/documentation/products',
                component: BlockProducts,
                meta: {
                    name: 'Блок «Товары»',
                    type: 'block',
                    category: 'cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Товары»' },
                    ],
                },
            },
            {
                path: '/documentation/services',
                component: BlockServices,
                meta: {
                    name: 'Блок «Услуги»',
                    type: 'block',
                    category: 'fullscreen',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Услуги»' },
                    ],
                },
            },
            {
                path: '/documentation/sixcards',
                component: BlockSixcards,
                meta: {
                    name: 'Блок «Шесть карточек»',
                    type: 'block',
                    category: 'cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Шесть карточек»' },
                    ],
                },
            },
            {
                path: '/documentation/steps',
                component: BlockSteps,
                meta: {
                    name: 'Блок «Этапы»',
                    type: 'block',
                    category: 'list',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Этапы»' },
                    ],
                },
            },
            {
                path: '/documentation/element-faq',
                component: ElementFaq,
                meta: {
                    name: 'Элемент «Вопрос-ответ»',
                    type: 'element',
                    category: 'elements',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Вопрос-ответ»' },
                    ],
                },
            },
            {
                path: '/documentation/element-title',
                component: ElementTitle,
                meta: {
                    name: 'Элемент «Заголовок»',
                    type: 'element',
                    category: 'elements',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Заголовок»' },
                    ],
                },
            },
            {
                path: '/documentation/element-article-title',
                component: ElementArticleTitle,
                meta: {
                    name: 'Элемент «Заголовок статьи»',
                    type: 'element',
                    category: 'elements',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Заголовок статьи»' },
                    ],
                },
            },
            {
                path: '/documentation/element-card',
                component: ElementCard,
                meta: {
                    name: 'Элемент «Карточка»',
                    type: 'element',
                    category: 'elements',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Карточка»' },
                    ],
                },
            },
            {
                path: '/documentation/element-button',
                component: ElementButton,
                meta: {
                    name: 'Элемент «Кнопка»',
                    type: 'element',
                    category: 'elements',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Кнопка»' },
                    ],
                },
            },
            {
                path: '/documentation/element-postcard',
                component: ElementPostcard,
                meta: {
                    name: 'Элемент «Пост»',
                    type: 'element',
                    category: 'elements',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Пост»' },
                    ],
                },
            },
            {
                path: '/documentation/element-list',
                component: ElementList,
                meta: {
                    name: 'Элемент «Список»',
                    type: 'element',
                    category: 'elements',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Список»' },
                    ],
                },
            },
            {
                path: '/documentation/element-link',
                component: ElementLink,
                meta: {
                    name: 'Элемент «Ссылка»',
                    type: 'element',
                    category: 'elements',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Ссылка»' },
                    ],
                },
            },
            {
                path: '/documentation/element-static-counter',
                component: ElementStaticCounter,
                meta: {
                    name: 'Элемент «Статический счетчик»',
                    type: 'element',
                    category: 'elements',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Статический счетчик»' },
                    ],
                },
            },
            {
                path: '/documentation/element-breadcrumbs',
                component: ElementBreadcrumbs,
                meta: {
                    name: 'Элемент «Хлебные крошки',
                    type: 'element',
                    category: 'elements',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Хлебные крошки»' },
                    ],
                },
            },
            {
                path: '/documentation/adding-blocks',
                component: AddingBlocks,
                meta: {
                    name: 'Инструкция по использованию',
                    subgroup: 'Блоки и элементы',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        {
                            name: 'Инструкция по использованию блоков и элементов',
                        },
                    ],
                },
            },
            {
                path: '/documentation/block-list',
                component: ListOfBlocks,
                meta: {
                    name: 'Блоки',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Набор блоков' },
                    ],
                },
            },
            {
                path: '/documentation/elements-list',
                component: ListOfElements,
                meta: {
                    name: 'Элементы',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Набор элементов' },
                    ],
                },
            },
            {
                path: '/documentation/icon-set',
                component: IconSet,
                meta: {
                    name: 'Иконки',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Набор иконок' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app',
                component: ShopApp,
                meta: {
                    name: 'Установка и настройка',
                    group: 'Приложение «Магазин»',
                    marked: true,
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Установка и настройка магазина' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/homepage',
                component: ShopHomepage,
                meta: {
                    name: 'Главная страница',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Главная страница магазина' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/product',
                component: ShopProduct,
                meta: {
                    name: 'Пошаговое создание товара',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Пошаговое создание товара' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/catalog-page',
                component: ShopCatalog,
                meta: {
                    name: 'Каталог',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Каталог' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/adding-blocks',
                component: ShopAddingBlocks,
                meta: {
                    name: 'Блоки и элементы',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блоки и элементы для Orion Shop' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/accordion',
                component: BlockShopAccordion,
                meta: {
                    name: 'Блок «Аккордеон»',
                    type: 'block',
                    category: 'shop-interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Аккордеон»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/promotions',
                component: BlockShopPromotions,
                meta: {
                    name: 'Блок «Акции»',
                    type: 'block',
                    category: 'shop-slider',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Акции»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/banner',
                component: BlockShopBanner,
                meta: {
                    name: 'Блок «Баннер»',
                    type: 'block',
                    category: 'shop-slider',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Баннер»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/blog',
                component: BlockShopBlog,
                meta: {
                    name: 'Блок «Блог»',
                    type: 'block',
                    category: 'shop-cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Блог»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/brands',
                component: BlockShopBrands,
                meta: {
                    name: 'Блок «Бренды»',
                    type: 'block',
                    category: 'shop-cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Бренды»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/tabs',
                component: BlockShopTabs,
                meta: {
                    name: 'Блок «Вкладки»',
                    type: 'block',
                    category: 'shop-interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Вкладки»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/cards',
                component: BlockShopCards,
                meta: {
                    name: 'Блок «Карточки»',
                    type: 'block',
                    category: 'shop-cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Карточки»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/carousel',
                component: BlockShopCarousel,
                meta: {
                    name: 'Блок «Карусель»',
                    type: 'block',
                    category: 'shop-slider',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Карусель»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/catalog',
                component: BlockShopCatalog,
                meta: {
                    name: 'Блок «Каталог»',
                    type: 'block',
                    category: 'shop-interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Каталог»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/columns',
                component: BlockShopColumns,
                meta: {
                    name: 'Блок «Колонки»',
                    type: 'block',
                    category: 'shop-text',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Колонки»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/contacts',
                component: BlockShopContacts,
                meta: {
                    name: 'Блок «Контакты»',
                    type: 'block',
                    category: 'shop-interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Контакты»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/tags',
                component: BlockShopTags,
                meta: {
                    name: 'Блок «Метки»',
                    type: 'block',
                    category: 'shop-interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Метки»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/new',
                component: BlockShopNew,
                meta: {
                    name: 'Блок «Новинки»',
                    type: 'block',
                    category: 'shop-cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Новинки»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/reviews',
                component: BlockShopReviews,
                meta: {
                    name: 'Блок «Отзывы»',
                    type: 'block',
                    category: 'shop-interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Отзывы»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/advantages',
                component: BlockShopAdvantages,
                meta: {
                    name: 'Блок «Преимущества»',
                    type: 'block',
                    category: 'shop-cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Преимущества»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/cta',
                component: BlockShopCta,
                meta: {
                    name: 'Блок «Призыв к действию»',
                    type: 'block',
                    category: 'shop-interactive',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Призыв к действию»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/viewed',
                component: BlockShopViewed,
                meta: {
                    name: 'Блок «Просмотренные товары»',
                    type: 'block',
                    category: 'shop-cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Просмотренные товары»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/grid',
                component: BlockShopGrid,
                meta: {
                    name: 'Блок «Сетка»',
                    type: 'block',
                    category: 'shop-cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Сетка»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/slider',
                component: BlockShopSlider,
                meta: {
                    name: 'Блок «Слайдер»',
                    type: 'block',
                    category: 'shop-slider',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Слайдер»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/payments',
                component: BlockShopPayments,
                meta: {
                    name: 'Блок «Способы оплаты»',
                    type: 'block',
                    category: 'shop-text',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Способы оплаты»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/story',
                component: BlockShopStory,
                meta: {
                    name: 'Блок «Рассказ»',
                    type: 'block',
                    category: 'shop-text',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Рассказ»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/text',
                component: BlockShopText,
                meta: {
                    name: 'Блок «Текст»',
                    type: 'block',
                    category: 'shop-text',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Текст»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/sale',
                component: BlockShopSale,
                meta: {
                    name: 'Блок «Товары дня»',
                    type: 'block',
                    category: 'shop-cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Товары дня»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/products',
                component: BlockShopProducts,
                meta: {
                    name: 'Блок «Товары»',
                    type: 'block',
                    category: 'shop-cards',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Товары»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/element-faq',
                component: ElementShopFaq,
                meta: {
                    name: 'Элемент «Вопрос-ответ»',
                    type: 'block',
                    category: 'shop-element',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Вопрос-ответ»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/element-title',
                component: ElementShopTitle,
                meta: {
                    name: 'Элемент «Заголовок»',
                    type: 'block',
                    category: 'shop-element',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Заголовок»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/element-card',
                component: ElementShopCard,
                meta: {
                    name: 'Элемент «Карточка»',
                    type: 'block',
                    category: 'shop-element',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Карточка»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/element-button',
                component: ElementShopButton,
                meta: {
                    name: 'Элемент «Кнопка»',
                    type: 'block',
                    category: 'shop-element',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Кнопка»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/element-line',
                component: ElementShopLine,
                meta: {
                    name: 'Элемент «Линия»',
                    type: 'block',
                    category: 'shop-element',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Линия»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/element-list',
                component: ElementShopList,
                meta: {
                    name: 'Элемент «Список»',
                    type: 'block',
                    category: 'shop-element',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Список»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/element-postcard',
                component: ElementShopPostcard,
                meta: {
                    name: 'Элемент «Пост»',
                    type: 'block',
                    category: 'shop-element',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Пост»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/element-item',
                component: ElementShopItem,
                meta: {
                    name: 'Элемент «Преимущество»',
                    type: 'block',
                    category: 'shop-element',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Преимущество»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/element-link',
                component: ElementShopLink,
                meta: {
                    name: 'Элемент «Ссылка»',
                    type: 'block',
                    category: 'shop-element',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Ссылка»' },
                    ],
                },
            },
            {
                path: '/documentation/shop-app/element-product',
                component: ElementShopProduct,
                meta: {
                    name: 'Элемент «Товар»',
                    type: 'block',
                    category: 'shop-element',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Элемент «Товар»' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app',
                component: BlogApp,
                meta: {
                    name: 'Установка',
                    group: 'Приложение «Блог»',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Установка блога' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/homepage',
                component: BlogHomepage,
                meta: {
                    name: 'Главная страница',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Главная страница блога' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/posts',
                component: BlogPosts,
                meta: {
                    name: 'Пошаговое создание статьи',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Пошаговое создание статьи' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/adding-blocks',
                component: BlogAddingBlocks,
                meta: {
                    name: 'Блоки',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блоки для Orion Blog' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/banner',
                component: BlockBlogBanner,
                meta: {
                    name: 'Блок «Баннер»',
                    type: 'block',
                    category: 'blog',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Баннер»' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/trending',
                component: BlockBlogTrending,
                meta: {
                    name: 'Блок «В тренде»',
                    type: 'block',
                    category: 'blog',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «В тренде»' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/mainscreen',
                component: BlockBlogMainscreen,
                meta: {
                    name: 'Блок «Главный экран»',
                    type: 'block',
                    category: 'blog',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Главный экран»' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/carousel',
                component: BlockBlogCarousel,
                meta: {
                    name: 'Блок «Карусель»',
                    type: 'block',
                    category: 'blog',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Карусель»' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/categories',
                component: BlockBlogCategories,
                meta: {
                    name: 'Блок «Категории»',
                    type: 'block',
                    category: 'blog',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Категории»' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/stream',
                component: BlockBlogStream,
                meta: {
                    name: 'Блок «Поток статей»',
                    type: 'block',
                    category: 'blog',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Поток статей»' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/featured',
                component: BlockBlogFeatured,
                meta: {
                    name: 'Блок «Рекомендации»',
                    type: 'block',
                    category: 'blog',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Рекомендации»' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/grid',
                component: BlockBlogGrid,
                meta: {
                    name: 'Блок «Сетка»',
                    type: 'block',
                    category: 'blog',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Сетка»' },
                    ],
                },
            },
            {
                path: '/documentation/blog-app/slider',
                component: BlockBlogSlider,
                meta: {
                    name: 'Блок «Слайдер»',
                    type: 'block',
                    category: 'blog',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Блок «Слайдер»' },
                    ],
                },
            },
            {
                path: '/documentation/update-list',
                component: UpdateList,
                meta: {
                    name: 'Список обновлений',
                    group: 'Обновление темы',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Список обновлений' },
                    ],
                },
            },
            {
                path: '/documentation/update',
                component: Update,
                meta: {
                    name: 'Рекомендации по обновлению',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Рекомендации по обновлению' },
                    ],
                },
            },
            {
                path: '/documentation/installing-archive',
                component: InstallingArchive,
                meta: {
                    name: 'Установка архива c настройками',
                    type: 'page',
                    breadcrumb: [
                        { name: 'Документация', link: '/documentation' },
                        { name: 'Установка архива c настройками' },
                    ],
                },
            },
        ],
    },
    {
        path: '/support',
        component: Support,
        meta: {
            name: 'Поддержка',
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = `${process.env.VUE_APP_TITLE}, Webasyst — ${to.meta.name}`;
    const keywords = document.querySelector("meta[name='Keywords']");
    const description = document.querySelector("meta[name='description']");
    keywords.setAttribute(
        'content',
        `${process.env.VUE_APP_KEYWORDS}, ${to.meta.name}`
    );
    description.setAttribute(
        'content',
        `${to.meta.name}. ${process.env.VUE_APP_DESCRIPTION}`
    );
    next();
});

export default router;
