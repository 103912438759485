<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Товары»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_products_1.png" alt="Блок «Товары»" description="Блок «Товары»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — products"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Товары» --&gt;

{$props = [
  'color_scheme' => 'semitone',
  'no_margin' => false,
  'set_list' => 'new, hits, promo',
  'name_list' => 'Новинки, Популярные, Скидки',
  'product' => [
    'template' => 'four',
    'color_scheme' => 'semitone',
    'lazy' => false,
    'image_type' => 'gallery',
    'use_link' => true,
    'image_size' => '400',
    'image_margins' => true,
    'show_desc' => false,
    'show_rating' => true,
    'show_stocks' => true,
    'show_controls' => true,
    'hide_button' => true,
    'show_compare' => true,
    'show_favorite' => true,
    'button' => 'В корзину',
    'button_added' => 'Оформить заказ'
  ]
]}

{call add_block block="products" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopProducts from '@/assets/data/shop-products.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopProducts',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopProducts,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>