<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Список»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_list_1.png" alt="Блок «Список»" description="Блок «Список»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — list"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Список» --&gt;

{$props = [
    'template' => 'two',
    'color_scheme' => 'alter',
    'no_margin' => false,
    'badge' => 'Список',
    'animation' => true,
    'title' => [
        'align' => 'center',
        'size' => 'small, H3',
        'text' => 'Ключевые этапы по настройке темы дизайна «Orion»'
    ],
    'text' => 'Прежде чем наполнять сайт, стоит изучить своих конкурентов, понять их сильные стороны и на чем сосредоточить свои усилия.',
    'list' => [
        '1' => [
            'show' => true,
            'name' => 'Установка и настройка',
            'text' => 'Раздел документации содержит инструкцию по установке темы «Orion» для приложения Сайт и приложения Фото.'
        ],
        '2' => [
            'show' => true,
            'name' => 'Настройка главной страницы',
            'text' => 'В этом разделе вы познакомитесь с настройкой блоков главной страницы сайта.'
        ],
        '3' => [
            'show' => true,
            'name' => 'Готовые блоки',
            'text' => 'Вы можете самостоятельно создавать контент на страницах сайта.'
        ],
        '4' => [
            'show' => false,
            'name' => '',
            'text' => ''
        ],
        '5' => [
            'show' => false,
            'name' => '',
            'text' => ''
        ],
        '6' => [
            'show' => false,
            'name' => '',
            'text' => ''
        ]
    ]
]}

{call add_block block="list" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import list from '@/assets/data/list.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockList',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: list,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>