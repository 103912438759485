<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Аккордеон»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_accordion_1.png" alt="Блок «Аккордеон»" description="Блок «Аккордеон»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — accordion"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Аккордеон» --&gt;

{$props = [
  'color_scheme' => 'main',
  'lazy' => false,
  'no_margin' => false,
  'title' => [
    'align' => 'left',
    'size' => 'medium, H2',
    'text' => 'Тема дизайна «Orion Shop»'
  ],
  'tabs' => [
    '1' => [
      'name' => 'Классический текст Lorem Ipsum',
      'desc' => '&lt;p&gt;Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов, но это не совсем так.&lt;/p&gt;&lt;p&gt;Его корни уходят в один фрагмент классической латыни 45 года н.э., то есть более двух тысячелетий назад.&lt;/p&gt;',
      'image' => 'img/175x175.png',
      'buttons' => [
        '1' => [
          'template' => 'button-one',
          'mode' => 'link',
          'link' => '#',
          'target' => '_self',
          'type' => 'primary',
          'anchor' => true,
          'text' => 'Подробнее'
        ]
      ]
    ],
    '2' => [
      'name' => 'Классический текст Lorem Ipsum',
      'desc' => '&lt;p&gt;Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов, но это не совсем так.&lt;/p&gt;&lt;p&gt;Его корни уходят в один фрагмент классической латыни 45 года н.э., то есть более двух тысячелетий назад.&lt;/p&gt;',
      'image' => 'img/175x175.png',
      'buttons' => [
        '1' => [
          'template' => 'button-one',
          'mode' => 'link',
          'link' => '#',
          'target' => '_self',
          'type' => 'primary',
          'anchor' => true,
          'text' => 'Подробнее'
        ]
      ]
    ],
    '3' => [
      'name' => 'Классический текст Lorem Ipsum',
      'desc' => '&lt;p&gt;Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов, но это не совсем так.&lt;/p&gt;&lt;p&gt;Его корни уходят в один фрагмент классической латыни 45 года н.э., то есть более двух тысячелетий назад.&lt;/p&gt;',
      'image' => 'img/175x175.png',
      'buttons' => [
        '1' => [
          'template' => 'button-one',
          'mode' => 'link',
          'link' => '#',
          'target' => '_self',
          'type' => 'primary',
          'anchor' => true,
          'text' => 'Подробнее'
        ]
      ]
    ]
  ]
]}

{call add_block block="accordion" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopAccordion from '@/assets/data/shop-accordion.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopAccordion',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopAccordion,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>