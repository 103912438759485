<template>
    <div>
        <router-link
            v-if="router"
            :to="url"
            class="button"
            :class="[`button--${type}`, { 'button--wide': wide }]"
            >{{ text }}
        </router-link>
        <a
            v-else
            class="button"
            :class="[`button--${type}`, { 'button--wide': wide }]"
            :href="url"
            >{{ text }}</a
        >
    </div>
</template>

<script>
export default {
    name: 'AppButton',
    props: {
        text: String,
        url: String,
        type: String,
        wide: {
            type: Boolean,
            default: false,
        },
        router: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="less">
.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    box-sizing: border-box;
    padding: 0.7rem 1.2rem;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    line-height: 1.4em;
    text-transform: uppercase;
    font-size: 0.75em;
    letter-spacing: 0.07em;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    user-select: none;

    &--wide {
        width: 100%;
        max-width: 350px;
    }

    &--secondary,
    &--primary {
        transition: all 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        border: 2px solid;
        border-radius: 8px;
        will-change: background-color, border-color;
    }

    &--primary {
        background-color: @primary;
        border-color: @primary;
        color: @text-inverse;

        &:hover {
            background-color: lighten(@primary, 10%);
            border-color: lighten(@primary, 10%);
        }
    }

    &--secondary {
        background-color: darken(@secondary, 15%);
        border-color: darken(@secondary, 15%);
        color: @text-inverse;

        &:hover {
            background-color: @primary;
            border-color: @primary;
            color: @text-inverse;
        }
    }
}
</style>