<template>
    <div class="icon-box">
        <div class="icon-box__group" v-for="(group, key) of set" :key="key">
            <p class="icon-box__group-name">{{ group.name }}</p>
            <ul class="icon-box__list">
                <li
                    class="icon-box__item"
                    v-for="(icon, key) of group.value"
                    :key="key"
                >
                    <span class="icon-box__icon"
                        ><img v-lazy-src="`/img/set/${icon}.svg`"
                    /></span>
                    <span class="icon-box__name">{{ icon }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IconBox',
    props: {
        set: Array,
    },
};
</script>

<style lang="less">
.icon-box {
    &:not(:last-of-type) {
        margin-bottom: 4rem;
    }

    &:last-of-type {
        margin-bottom: 20px;
    }

    &__group {
        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }

    &__group-name {
        font-family: @title-font;
        font-weight: 600;
        margin-bottom: 20px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        margin-bottom: 10px;

        &:not(:last-of-type) {
            margin-right: 10px;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        background-color: @background;
        border: 1px solid darken(@secondary, 4%);
        border-radius: 4px;

        & img {
            width: 40px;
            height: 40px;
        }
    }

    &__name {
        display: block;
        padding: 0.2rem;
        margin-top: 5px;
        background-color: @secondary;
        border: 1px solid darken(@secondary, 4%);
        border-radius: 4px;
        text-align: center;
        font-size: 0.9em;
        line-height: 1em;
    }
}
</style>