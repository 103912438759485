<template>
    <div class="about">
        <app-title text="Настройка главной страницы" />

        <p class="documentation__text">
            Перейдите в бэкенд и вручную добавьте главную страницу сайта. В
            разделе «Страницы» создайте страницу с
            <accent text="пустым адресом" />
            — она и будет главной страницей вашего сайта.
        </p>

        <app-image
            url="/img/documentation/installation_3.png"
            alt="Установка темы дизайна"
        />

        <p class="documentation__text">
            Главная страница темы дизайна «Orion» состоит из блоков — логически
            выделенных фрагментов сайта.
        </p>

        <app-subtitle text="Отображение и сортировка блоков" number="1" />
        <p class="documentation__text">
            Настройка главной страницы осуществляется через фронтенд сайта.
            Откройте настройки темы, перейдите во вкладку
            <accent text="«Главная страница»" />
            🠖
            <accent text="«Порядок блоков»" />
            . Расположите блоки в нужном вам порядке путем перетягивания
            карточки с названием блока. Переключатель отвечает за
            скрытие/отображение блока на главной странице.
        </p>

        <p class="documentation__text">
            Блок
            <accent text="«Стартовый»" />
            — технический блок, который приветствует пользователя и
            предоставляет ссылку на документацию темы. Он не имеет собственных
            настроек, его необходимо скрыть в меню «Порядок блоков».
        </p>

        <app-image
            url="/img/documentation/homepage_blocks_1.png"
            alt="Порядок блоков главной страницы"
        />

        <p class="documentation__text">
            Для того, чтобы определиться какой блок вам нужен, воспользуйтесь
            демо-версиями темы, откройте демо-панель и включите настройку
            «Показывать название блоков при наведении».
        </p>

        <app-subtitle text="Настройка блоков" number="2" />

        <app-image-block
            :reverse="true"
            :image="{
                url: '/img/documentation/homepage_blocks_2.png',
                alt: 'Настройка блоков',
            }"
        >
            <p class="documentation__text">
                Вы вывели на экран блоки, подходящие для вашей задачи, пришло
                время их настроить. Проще всего нажать на иконку
                <accent text="«Карандаш»" />
                в правом верхнем углу для того, чтобы сразу начать редактировать
                выбранный блок. Эта опция доступна только администраторам сайта.
            </p>
            <p class="documentation__text">
                Рядом расположена еще одна кнопка с иконкой фигурных скобок. С
                ее помощью можно получить код блока, чтобы потом вставить его на
                любую страницу вашего сайта. О том как это сделать читайте в
                разделе
                <router-link
                    class="documentation__link"
                    to="/documentation/adding-blocks"
                    >Добавление блоков и элементов c помощью Smarty
                </router-link>
            </p>
            <p class="documentation__text">
                При настройке блоков используйте изображения подходящих
                форматов. Размер изображений, даже больших, не должен превышать
                200-300 Кбайт. Используйте специальные программные средства или
                <a
                    href="https://imagecompressor.com/ru/"
                    class="documentation__link"
                    target="_blank"
                    >онлайн сервисы</a
                >, чтобы оптимизировать файлы перед загрузкой в тему.
            </p>
        </app-image-block>

        <app-subtitle text="Свой код на главной странице" number="3" />
        <p class="documentation__text">
            Вы можете добавить свой код после любого активного блока на главной
            странице. Для этого достаточно создать новый шаблон в разделе
            <accent text="«Блоки»" />
            бэкенда Webasyst и назвать его по следующему алгоритму:
            <accent text="site.after_НАЗВАНИЕ_БЛОКА" />
            .
        </p>

        <p class="documentation__text">
            К примеру, <b>site.after_slider</b> добавит код после блока
            «Слайдер».
        </p>

        <app-image
            url="/img/documentation/homepage_blocks_3.png"
            alt="Свой код на главной странице"
        />

        <app-subtitle text="Ссылка-якорь на блок" number="4" />
        <p class="documentation__text">
            Якорем называется закладка с уникальным именем на определенном месте
            веб-страницы, предназначенная для создания перехода к ней по ссылке.
            В теме дизайна «Orion» вы можете использовать следующий синтаксис
            ссылок для плавного перехода внутри страницы к определенному блоку
            <accent text="#ID_БЛОКА" />
            . К примеру <b>#slider</b> переместит вас к блоку «Слайдер»
            соответственно.
        </p>

        <p class="documentation__text">
            ID каждого блока указан в начале вкладки с его настройками.
        </p>

        <app-image
            url="/img/documentation/homepage_blocks_4.png"
            alt="Ссылка-якорь на блок"
        />

        <p class="documentation__text">
            В случае, когда вам нужно сделать переход с другой страницы к
            определенному блоку, URL ссылки может выглядеть так
            <b>https://your-domain.com/#slider</b>.
        </p>
    </div>
</template>

<script>
export default {
    name: 'HomepageBlocks',
    data() {
        return {};
    },
};
</script>