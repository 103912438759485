<template>
    <component
        :is="card.noLink ? 'div' : 'a'"
        :href="card.url"
        class="demo-card"
        target="_blank"
    >
        <span class="demo-card__image-wrapper">
            <img :src="card.image" alt="card.alt" class="demo-card__image" />
        </span>
        <span class="demo-card__content">
            <span v-if="card.badge" class="demo-card__badge">{{
                card.badge
            }}</span>
            <span v-if="card.title" class="demo-card__title">{{
                card.title
            }}</span>
            <span v-if="card.desc" class="demo-card__desc">{{
                card.desc
            }}</span>
            <button
                v-if="card.button"
                class="demo-card__button button button--secondary button--wide"
            >
                {{ card.button }}
            </button>
        </span>
    </component>
</template>

<script>
export default {
    name: 'DemoCard',
    props: {
        card: Object,
    },
};
</script>

<style lang="less">
.demo-card {
    max-width: 380px;
    color: @text;
    background-color: @background;
    border-radius: 8px;
    text-decoration: none;
    border: 1px solid darken(@secondary, 4%);
    transition: all 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    overflow: hidden;

    &:hover {
        box-shadow: @medium-shadow-hover;
    }

    &__image-wrapper {
        position: relative;
        display: block;
        overflow: hidden;
    }

    &__image {
        display: block;
        width: 100%;
        border-bottom: 1px solid @secondary;
    }

    &__content {
        display: block;
        padding: 1rem;
    }

    &__button {
        margin-top: 10px;
    }

    &__badge {
        display: inline-block;
        padding: 0.1em 0.8em;
        margin-bottom: 10px;
        border-radius: 4px;
        background-color: @contrast;
        color: @text-inverse;
        font-size: 0.9em;
        line-height: 1.4em;
        white-space: nowrap;
    }

    &__title {
        display: block;
        font-family: @title-font;
        font-weight: 600;
    }

    &__desc {
        display: block;
        font-size: 0.85em;
        color: lighten(@text, 30%);
    }
}
</style>