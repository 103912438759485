<template>
    <div>
        <div class="documentation__header">
            <app-title text="Блок «Баннеры»"></app-title>
            <app-go-back
                :link="{
                    name: 'Набор блоков',
                    path: '/documentation/block-list',
                }"
            ></app-go-back>
        </div>

        <app-image
            url="/img/documentation/block_banners_1.png"
            alt="Блок «Баннеры»"
            description="Блок «Баннеры»"
            border
        />

        <app-subtitle
            text="Вызов блока на страницах сайта"
            label="ID — banners"
        />

        <info-box
            text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах"
            :link="infoLink"
        />

        <!-- prettier-ignore -->
        <div class="documentation__code">
            <ssh-pre language="html" label="Smarty" :copy-button="true">
                <template v-slot:copy-button>
                    <icon-copy />
</template>&lt;!-- Блок «Баннеры» --&gt;

{$props = [
    'color_scheme' => 'main',
    'no_margin' => false,
    'autoplay' => false,
    'gradient' => true,
    'banners' => [
        '1' => [
            'image' => 'img/1300x470.png',
            'image_mobile' => 'img/570x400.png',
            'url' => '#',
            'text' => ''
        ],
        '2' => [
            'image' => 'img/1300x470.png',
            'image_mobile' => 'img/570x400.png',
            'url' => '#',
            'text' => ''
        ],
        '3' => [
            'image' => 'img/1300x470.png',
            'image_mobile' => 'img/570x400.png',
            'url' => '#',
            'text' => ''
        ],
        '4' => [
            'image' => 'img/1300x470.png',
            'image_mobile' => 'img/570x400.png',
            'url' => '#',
            'text' => ''
        ],
        '5' => [
            'image' => 'img/1300x470.png',
            'image_mobile' => 'img/570x400.png',
            'url' => '#',
            'text' => ''
        ]
    ]
]}


{call add_block block="banners" props=$props}</ssh-pre>
        </div>

        <app-table :data="tableData" />
    </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import IconCopy from '@/components/icons/IconCopy.vue';
import banners from '@/assets/data/banners.json';
import AppGoBack from '../../components/AppGoBack';

export default {
    name: 'BlockBanners',
    components: {
        SshPre,
        IconCopy,
        AppGoBack,
    },
    data() {
        return {
            tableData: banners,
            infoLink: {
                name: 'Подробнее',
                path: '/documentation/adding-blocks',
            },
        };
    },
};
</script>
