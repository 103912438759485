<template>
    <div class="doc-image">
        <img
            :src="url"
            :alt="alt"
            class="doc-image__image"
            :class="{
                'doc-image__image--shadow': shadow,
                'doc-image__image--border': border,
            }"
        />
        <em class="doc-image__desc" v-if="description">{{ description }}</em>
    </div>
</template>

<script>
export default {
    name: 'AppImage',
    props: {
        url: String,
        alt: String,
        description: String,
        shadow: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="less">
.doc-image {
    margin-bottom: 20px;

    &__image {
        display: block;
        border-radius: 4px;
        max-width: 100%;
        height: auto;

        &--shadow {
            box-shadow: @medium-shadow;
        }

        &--border {
            border: 1px solid darken(@secondary, 4%);
        }
    }

    &__desc {
        display: block;
        margin-top: 10px;
        font-size: 0.95em;
        text-align: center;
    }
}
</style>