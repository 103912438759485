<template>
    <div>
        <app-title text="Создание новых страниц" />

        <p class="documentation__text">
            В Webasyst в приложении «Сайт» перейдите во вкладку «Страницы» и
            создайте новую страницу. Далее наполните страницу контентом в режиме
            Визуальный редактор.
        </p>

        <app-image
            url="/img/documentation/new_page_1.png"
            alt="Создание новых страниц"
        />

        <p class="documentation__text">
            Написанный вами контент сразу получит стили оформления темы. Если
            вам нужна чистая страница, то в <b>режиме HTML</b> в начале страницы
            добавьте переменную
            <accent text="userCode" />
            в значении true.
        </p>

        <!-- prettier-ignore -->
        <div class="documentation__code">
            <ssh-pre language="html" label="Smarty" :copy-button="true">
                <template v-slot:copy-button> <icon-copy /> </template>
{$wa->globals("userCode", true)}</ssh-pre>
        </div>

        <p class="documentation__text">
            Вместо простого текстового контента вы можете наполнить страницу
            готовыми блоками или добавить в текст элементы темы. Об этом вы
            можете прочитать в разделе
            <router-link
                class="documentation__link"
                to="/documentation/adding-blocks"
                >блоки и элементы</router-link
            >
            .
        </p>
    </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import IconCopy from '@/components/icons/IconCopy.vue';

export default {
    name: 'NewPage',
    components: {
        SshPre,
        IconCopy,
    },
};
</script>
