<template>
    <div class="header">
        <div class="header__logo-wrapper">
            <router-link class="header__logo" to="/">
                <app-logo></app-logo>
            </router-link>
            <button
                class="header__toggler"
                @click="sidebarToggle()"
                :class="{ 'is-opened': sidebarIsOpened }"
            >
                <icon-toggle-navigation />
                <app-tooltip :text="tooltipText()"></app-tooltip>
            </button>
        </div>
        <div class="header__navigation">
            <icon-burger @toggleNavigation="opened = $event" />
            <navigation :links="navigation" :opened="opened"></navigation>
        </div>

        <div class="header__button">
            <app-button
                type="primary"
                text="Магазин Webasyst"
                url="https://www.webasyst.ru/store/theme/orion/"
            ></app-button>
        </div>
    </div>
</template>

<script>
import AppLogo from '@/components/icons/AppLogo.vue';
import AppTooltip from './AppTooltip';
import Navigation from '@/components/Navigation.vue';
import IconBurger from '@/components/icons/IconBurger.vue';
import IconToggleNavigation from '@/components/icons/IconToggleNavigation.vue';

export default {
    name: 'AppHeader',
    components: {
        Navigation,
        AppLogo,
        AppTooltip,
        IconBurger,
        IconToggleNavigation,
    },
    data() {
        return {
            opened: false,
        };
    },
    computed: {
        navigation() {
            return this.$router.options.routes;
        },
        sidebarIsOpened() {
            return this.$store.state.sidebarIsOpened;
        },
    },
    methods: {
        sidebarToggle() {
            this.$store.state.sidebarIsOpened = !this.sidebarIsOpened;
        },
        tooltipText() {
            return this.sidebarIsOpened ? `Свернуть` : `Развернуть`;
        },
    },
};
</script>

<style lang="less">
.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    height: 45px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    box-sizing: border-box;
    background-color: @background;
    border-bottom: 1px solid darken(@secondary, 4%);
    z-index: 100;

    &__logo-wrapper {
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    &__logo {
        display: block;
        max-width: 150px;

        & svg {
            display: block;
            width: 100%;
            max-height: 70px;
        }
    }

    &__toggler {
        position: relative;
        display: flex;
        align-items: center;
        padding: 6px;
        background-color: unset;
        border: unset;
        border-radius: 4px;
        cursor: pointer;
        will-change: transform;
        transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        z-index: 1;

        & svg {
            transform: rotate(180deg);
            transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            will-change: transform;
        }

        &:hover {
            background-color: @secondary;
        }

        &:active {
            transform: scale(0.9);

            & svg {
                transform: rotate(180deg);
            }
        }

        &.is-opened {
            & svg {
                transform: rotate(0);

                &:active {
                    transform: scale(0.9);
                }
            }
        }
    }

    &__button {
        display: none;
    }

    &__navigation {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
    }
}

@media (hover: hover) and (pointer: fine) {
    .header__toggler:hover {
        & .tooltip {
            display: block;
        }
    }
}

@media (min-width: 1200px) {
    .header {
        height: 65px;

        &__logo {
            max-width: 220px;
        }

        &__button {
            display: flex;
        }
    }
}
</style>