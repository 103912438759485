<template>
	<div>
        <div class="documentation__header">
            <app-title text="Блок «Слайдер»"></app-title>
            <app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
        </div>

		<app-image url="/img/documentation/block_slider_1.png" alt="Блок «Слайдер»" description="Блок «Слайдер»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — slider"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Слайдер» --&gt;

{$props = [
    'gradient' => true,
    'lazy' => false,
    'autoplay' => true,
    'hash' => true,
    'animate' => true,
    'show_menu' => true,
    'navigation' => 'custom',
    'slides' => [
        '1' => [
            'show' => true,
            'color' => '#ffffff',
            'align' => 'center',
            'half_screen' => false,
            'background' => 'img/2200x1220.png',
            'name' => 'Адаптивная тема',
            'title' => [
                'align' => 'center',
                'size' => 'extra, H1',
                'text' => 'Адаптивная тема дизайна «Orion»'
            ],
            'text' => 'Тема дизайна Orion для фреймворка Webasyst позволит вам создать современный лендинг или корпоративный сайт, оптимизированный для любых устройств.',
            'large_text' => 'за 3 599 р.',
            'buttons' => [
                '1' => [
                    'template' => 'button-one',
                    'mode' => 'link',
                    'link' => '#',
                    'target' => '_self',
                    'type' => 'secondary',
                    'anchor' => true,
                    'text' => 'Подробнее'
                ]
            ]
        ],
        '2' => [
            'show' => true,
            'color' => '#ffffff',
            'align' => 'center',
            'half_screen' => false,
            'background' => 'img/2200x1220.png',
            'name' => 'Сайт и фотогалерея',
            'title' => [
                'align' => 'center',
                'size' => 'extra, H2',
                'text' => 'Сайт и фотогалерея'
            ],
            'text' => 'Создайте уникальный, креативный веб-сайт и галерею на основе темы Orion для приложений «Сайт» и «Фото»',
            'large_text' => '',
            'buttons' => [
                '1' => [
                    'template' => 'button-one',
                    'mode' => 'link',
                    'link' => '#',
                    'target' => '_self',
                    'type' => 'secondary',
                    'anchor' => true,
                    'text' => 'Подробнее'
                ]
            ]
        ],
        '3' => [
            'show' => true,
            'color' => '#ffffff',
            'align' => 'center',
            'half_screen' => false,
            'background' => 'img/2200x1220.png',
            'name' => 'Готовые блоки',
            'title' => [
                'align' => 'center',
                'size' => 'extra, H2',
                'text' => 'Готовые блоки'
            ],
            'text' => 'Получите правильный UX/UI интерфейс – соберите страницы вашего проекта из нашей постоянно пополняемой коллекции готовых блоков.',
            'large_text' => '',
            'buttons' => [
                '1' => [
                    'template' => 'button-one',
                    'mode' => 'link',
                    'link' => '#',
                    'target' => '_self',
                    'type' => 'secondary',
                    'anchor' => true,
                    'text' => 'Подробнее'
                ]
            ]
        ],
        '4' => [
            'show' => true,
            'color' => '#ffffff',
            'align' => 'center',
            'half_screen' => false,
            'background' => 'img/2200x1220.png',
            'name' => 'Лендинг на Orion',
            'title' => [
                'align' => 'center',
                'size' => 'extra, H2',
                'text' => 'От лендинга до корпоративного сайта'
            ],
            'text' => 'Тема «Orion» прекрасно адаптирована для одностраничных промо сайтов. В то же время она дает вам хорошие возможности для построения веб-ресурсов с большим количеством контента.',
            'large_text' => '',
            'buttons' => [
                '1' => [
                    'template' => 'button-one',
                    'mode' => 'link',
                    'link' => '#',
                    'target' => '_self',
                    'type' => 'secondary',
                    'anchor' => true,
                    'text' => 'Подробнее'
                ]
            ]
        ],
        '5' => [
            'show' => true,
            'color' => '#ffffff',
            'align' => 'center',
            'half_screen' => false,
            'background' => 'img/2200x1220.png',
            'name' => 'Гибкая настройка',
            'title' => [
                'align' => 'center',
                'size' => 'extra, H2',
                'text' => 'Настройте сайт для вашего бизнеса'
            ],
            'text' => 'Большое количество настроек дает вам широкий простор для творчества. Меняйте цветовые схемы, шрифты, блоки, элементы интерфейса по вашему желанию.',
            'large_text' => '',
            'buttons' => [
                '1' => [
                    'template' => 'button-one',
                    'mode' => 'link',
                    'link' => '#',
                    'target' => '_self',
                    'type' => 'secondary',
                    'anchor' => true,
                    'text' => 'Подробнее'
                ]
            ]
        ]
    ],
    'links' => [
        '0' => [
            'url' => '#',
            'name' => 'Первая ссылка'
        ],
        '1' => [
            'url' => '#',
            'name' => 'Вторая ссылка'
        ],
        '2' => [
            'url' => '#',
            'name' => 'Третья ссылка'
        ],
        '3' => [
            'url' => '#',
            'name' => 'Четвертая ссылка'
        ],
        '4' => [
            'url' => '#',
            'name' => 'Пятая ссылка'
        ],
        '5' => [
            'url' => '#',
            'name' => 'Шестая ссылка'
        ]
    ]
]}

{call add_block block="slider" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import slider from '@/assets/data/slider.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockSlider',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: slider,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>