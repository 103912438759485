<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Этапы»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_steps_1.png" alt="Блок «Этапы»" description="Блок «Этапы»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — steps"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Этапы» --&gt;

{$props = [
    'color_scheme' => 'main',
    'no_margin' => false,
    'icon_style' => 'is-linear',
    'title' => [
        'align' => 'center',
        'size' => 'medium, H2',
        'text' => 'Этапы установки темы'
    ],
    'items' => [
        '1' => [
            'iconset' => '1',
            'icon' => '',
            'name' => 'Установка Webasyst',
            'desc' => 'Скачайте архив со скриптами фреймворка Webasyst, распакуйте и загрузите их на удаленный хостинг или на локальный сервер.'
        ],
        '2' => [
            'iconset' => '2',
            'icon' => '',
            'name' => 'Установка приложений',
            'desc' => 'В бэкенде фреймворка создайте требуемые страницы в приложении «Сайт», установите приложение «Фото» и необходимые вам плагины.'
        ],
        '3' => [
            'iconset' => '3',
            'icon' => '',
            'name' => 'Приобретение темы Orion',
            'desc' => 'В приложении «Сайт» перейдите во вкладку «Дизайн» и выберите раздел «Темы дизайна». Вбейте в поиск Orion, выберите тему и нажмите «Купить или активировать».'
        ],
        '4' => [
            'iconset' => '4',
            'icon' => '',
            'name' => 'Настройка и наполнения контентом',
            'desc' => 'Во вкладке «Дизайн» перейдите в раздел «Оформление» и начните кастомизмровать сайт.'
        ],
        '5' => [
            'iconset' => '5',
            'icon' => '',
            'name' => '',
            'desc' => ''
        ],
        '6' => [
            'iconset' => '6',
            'icon' => '',
            'name' => '',
            'desc' => ''
        ]
    ]
]}

{call add_block block="steps" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import steps from '@/assets/data/steps.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockSteps',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: steps,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>