import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import LazyloadVue from 'lazyload-vue'
import AppButton from '@/components/AppButton'
import AppTitle from "@/components/AppTitle";
import AppBadge from "@/components/AppBadge";
import AppSubtitle from "@/components/AppSubtitle";
import AppList from "@/components/AppList";
import AppTable from "./components/AppTable";
import AppImage from "@/components/AppImage";
import AppImageBlock from "@/components/AppImageBlock";
import AppColumn from "@/components/AppColumn";
import InfoBox from '@/components/InfoBox';
import Accent from '@/components/Accent';
import SmartTable from 'vuejs-smart-table';

Vue.config.productionTip = false;

Vue.use(SmartTable);
Vue.use(LazyloadVue);

Vue.component('app-button', AppButton);
Vue.component('app-title', AppTitle);
Vue.component('app-badge', AppBadge);
Vue.component('app-subtitle', AppSubtitle);
Vue.component('app-image', AppImage);
Vue.component('app-image-block', AppImageBlock);
Vue.component('app-table', AppTable);
Vue.component('app-list', AppList);
Vue.component('app-column', AppColumn);
Vue.component('info-box', InfoBox);
Vue.component('accent', Accent);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');



