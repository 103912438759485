<template>
	<div>
        <div class="documentation__header">
            <app-title text="Блок «Тарифы»"></app-title>
            <app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
        </div>

		<app-image url="/img/documentation/block_pricing_1.png" alt="Блок «Тарифы»" description="Блок «Тарифы»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — pricing"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Тарифы» --&gt;

{$props = [
    'cards_quantity' => '3',
    'color_scheme' => 'alter',
    'no_margin' => false,
    'icon_style' => 'is-linear',
    'title' => [
        'align' => 'center',
        'size' => 'medium, H2',
        'text' => 'Универсальный блок для цен и тарифов'
    ],
    'cards' => [
        '1' => [
            'lazy' => false,
            'scale' => false,
            'badge' => '',
            'name' => 'Стартовый',
            'price' => '15 000 ₽',
            'iconset' => '',
            'icon' => 'img/500x250.png',
            'buttons' => [
                '1' => [
                    'template' => 'button-one',
                    'mode' => '',
                    'link' => '#',
                    'target' => '_self',
                    'type' => 'secondary',
                    'anchor' => 'true',
                    'text' => 'Подробнее'
                ]
            ],
            'items' => [
                '0' => [
                    'text' => 'Первое преимущество стартового тарифа'
                ],
                '1' => [
                    'text' => 'Вторая выгода стартового тарифа'
                ],
                '2' => [
                    'text' => 'Третья выгода тарифа'
                ],
                '3' => [
                    'text' => 'Четвертая выгода тарифа'
                ],
                '4' => [
                    'text' => 'Пятое преимущество стартового тарифа'
                ]
            ]
        ],
        '2' => [
            'lazy' => false,
            'scale' => true,
            'badge' => 'Рекомендуемый',
            'name' => 'Стандартный',
            'price' => '20 000 ₽',
            'iconset' => '',
            'icon' => 'img/500x250.png',
            'buttons' => [
                '1' => [
                    'template' => 'button-one',
                    'mode' => '',
                    'link' => '#',
                    'target' => '_self',
                    'type' => 'secondary',
                    'anchor' => 'true',
                    'text' => 'Подробнее'
                ]
            ],
            'items' => [
                '0' => [
                    'text' => 'Первое преимущество стандартного тарифа'
                ],
                '1' => [
                    'text' => 'Второе преимущество тарифа'
                ],
                '2' => [
                    'text' => 'Третье преимущество стандартного тарифа'
                ],
                '3' => [
                    'text' => 'Четвертая выгода'
                ],
                '4' => [
                    'text' => 'Пятая выгода'
                ]
            ]
        ],
        '3' => [
            'lazy' => false,
            'scale' => false,
            'badge' => '',
            'name' => 'Продвинутый',
            'price' => '25 000 ₽',
            'iconset' => '',
            'icon' => 'img/500x250.png',
            'buttons' => [
                '1' => [
                    'template' => 'button-one',
                    'mode' => '',
                    'link' => '#',
                    'target' => '_self',
                    'type' => 'secondary',
                    'anchor' => 'true',
                    'text' => 'Подробнее'
                ]
            ],
            'items' => [
                '0' => [
                    'text' => 'Первая выгода тарифа'
                ],
                '1' => [
                    'text' => 'Второе преимущество продвинутого тарифа'
                ],
                '2' => [
                    'text' => 'Третья выгода продвинутого тарифа'
                ],
                '3' => [
                    'text' => 'Четвертое преимущество тарифа'
                ],
                '4' => [
                    'text' => 'Пятая выгода'
                ]
            ]
        ]
    ]
]}

{call add_block block="pricing" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import pricing from '@/assets/data/pricing.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockPricing',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: pricing,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>