<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Поток статей»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/blog-app/adding-blocks'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_blog_stream_1.png" alt="Блок «Поток статей»" description="Блок «Поток статей»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — stream"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Получение списка статей --&gt;
{$posts = $wa->blog->posts()}</ssh-pre>
		</div>

		<p class="documentation__text">
			Получить список статей достаточно один раз на страницу, далее блок можно использовать несколько раз, если это необходимо.
		</p>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Поток статей» --&gt;

{$props = [
    'color_scheme' => 'main',
    'pagination' => 'lazyloading',
    'title' => [
        'align' => 'left',
        'size' => 'medium',
        'text' => ''
    ],
    'post_settings' => [
        'color_scheme' => 'main',
        'lazy' => true,
        'preview_mode' => 'theme',
        'show_views' => true,
        'show_comments' => true,
        'gradient' => true,
        'publisher' => [
            'logo_url' => '',
            'phone' => '8 (800) 000 00 00',
            'city' => 'г. Москва'
        ]
    ]
]}

{call add_block block="stream" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import blogStream from '@/assets/data/blog-stream.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockBlogStream',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: blogStream
            }
        }
    }
</script>