<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Акции»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_promotions_1.png" alt="Блок «Акции»" description="Блок «Акции»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — promotions"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Акции» --&gt;

{$props = [
  'color_scheme' => 'main',
  'no_margin' => false,
  'title' => [
    'align' => 'left',
    'size' => 'medium, H2',
    'text' => ''
  ],
  'promotions' => [
    '1' => [
      'image' => 'img/850x300.png',
      'image_mobile' => 'img/630x500.png',
      'link' => '#'
    ],
    '2' => [
      'image' => 'img/850x300.png',
      'image_mobile' => 'img/630x500.png',
      'link' => '#'
    ],
    '3' => [
      'image' => 'img/850x300.png',
      'image_mobile' => 'img/630x500.png',
      'link' => '#'
    ],
    '4' => [
      'image' => 'img/850x300.png',
      'image_mobile' => 'img/630x500.png',
      'link' => '#'
    ],
    '5' => [
      'image' => 'img/850x300.png',
      'image_mobile' => 'img/630x500.png',
      'link' => '#'
    ],
    '6' => [
      'image' => 'img/850x300.png',
      'image_mobile' => 'img/630x500.png',
      'link' => '#'
    ]
  ]
]}

{call add_block block="promotions" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopPromotions from '@/assets/data/shop-promotions.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopPromotions',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopPromotions,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>