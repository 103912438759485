<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Контакты»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_contacts_1.png" alt="Блок «Контакты»" description="Блок «Контакты»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — contacts"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Контакты» --&gt;

{$props = [
  'no_margin' => false,
  'color_scheme' => 'main',
  'direction' => 'reverse',
  'title' => [
    'align' => 'left',
    'size' => 'tiny, h3',
    'text' => 'Контакты'
  ],
  'text' => 'Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
  'show_phone' => true,
  'show_email' => true,
  'manual_schedule' => 'Пн—Сб 08:00–18:00',
  'phone' => [
    'template' => 'link-one',
    'uppercase' => 'false',
    'anchor' => true,
    'color' => 'primary',
    'target' => '_self',
    'link' => 'tel:+12345551234',
    'text' => '+1 (234) 555-1234'
  ],
  'email' => [
    'template' => 'link-one',
    'uppercase' => 'false',
    'anchor' => true,
    'color' => 'primary',
    'target' => '_self',
    'link' => 'mailto:organization@mail.com',
    'text' => 'organization@mail.com'
  ],
  'addresses' => [
    '1' => [
      'city' => 'г. Москва',
      'address' => 'ул. Театральная 1, офис 10',
      'coordinates' => '55.759643, 37.618948'
    ],
    '2' => [
      'city' => 'г. Москва',
      'address' => 'ул. Садовая-Спасская, 28 офис 230',
      'coordinates' => '55.768917, 37.647475'
    ]
  ]
]}

{call add_block block="contacts" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopContacts from '@/assets/data/shop-contacts.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopContacts',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopContacts,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>