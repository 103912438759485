<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Метки»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_tags_1.png" alt="Блок «Метки»" description="Блок «Метки»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — tags"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Метки» --&gt;

{$props = [
  'color_scheme' => 'main',
  'lazy' => false,
  'no_margin' => false,
  'title' => [
    'align' => 'left',
    'size' => 'medium, H2',
    'text' => 'Тема дизайна «Orion Shop»'
  ],
  'text' => '&lt;p&gt;Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться.&lt;/p&gt;',
  'cards' => [
    '1' => [
      'image' => 'img/550x750.png',
      'products' => [
        '0' => '1',
        '1' => '2'
      ],
      'tags' => [
        '0' => '30,30',
        '1' => '70,35',
        '2' => '40,75'
      ]
    ],
    '2' => [
      'image' => 'img/550x750.png',
      'products' => [
        '0' => '1',
        '1' => '2',
        '2' => '3'
      ],
      'tags' => [
        '0' => '55,20',
        '1' => '70,50',
        '2' => '30,75'
      ]
    ],
    '3' => [
      'image' => 'img/550x750.png',
      'products' => [
        '0' => '1',
        '1' => '2'
      ],
      'tags' => [
        '0' => '25,50',
        '1' => '60,60',
        '2' => '60,30'
      ]
    ]
  ]
]}

{call add_block block="tags" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopTags from '@/assets/data/shop-tags.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopTags',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopTags,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>