<template>
    <span class="badge">
        {{ text }}
    </span>
</template>

<script>
export default {
    name: 'AppBadge',
    props: {
        text: String,
    },
};
</script>

<style lang="less">
.badge {
    display: inline-block;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    border-radius: 4px;
    background-color: @primary;
    color: @text-inverse;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-size: 0.75em;
}
</style>