<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Видео»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<p class="documentation__text">
			Блок «Видео» содержит всплывающее окно с видеоплеером youtube.
		</p>

		<app-image url="/img/documentation/block_video_1.png" alt="Блок «Видео»" description="Блок «Видео»" border />

		<p class="documentation__text">
			Для корректной работы вам нужно вписать ID видео в настройках темы, он состоит из 11 символов. Откройте любое видео для просмотра. В верху страницы нажмите по поисковому окну браузера и скопируйте ID видео.
		</p>

		<app-image url="/img/documentation/block_video_2.png" alt="Блок «Видео»" shadow />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — video"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Видео» --&gt;

{$props = [
    'id' => '',
    'color_scheme' => 'main',
    'no_margin' => false,
    'align' => 'left',
    'gradient' => true,
    'lazy' => false,
    'background' => 'img/1200x700.png',
    'title' => [
        'align' => 'left',
        'size' => 'small, H3',
        'text' => 'Для чего используют лендинг пейдж?'
    ],
    'text' => '&lt;p&gt;Лендинг – это продающий одностраничник с минимумом информации, ее изложение направлено на достижение конкретной цели. Если сайт выполняет больше одной цели, то посадочной страницей его назвать нельзя. Недопустимо рассеивать внимание вероятного клиента, так как это негативно сказывается на результативности продаж и конверсии.&lt;/p&gt;',
    'list' => [
        'template' => 'list-one',
        'align' => 'left',
        'list' => 'Увеличение количества посетителей * Меньшие расходы * Поиск партнеров'
    ],
    'buttons' => [
        '1' => [
            'template' => 'button-one',
            'mode' => 'feedback',
            'link' => '#',
            'target' => '_self',
            'type' => 'primary',
            'anchor' => true,
            'wide' => true,
            'icon' => 'phone',
            'text' => 'Заказать консультацию'
        ]
    ],
    'video_button' => [
        'template' => 'video-button-one',
        'video_id' => 'rY-zV-CcBZ8'
    ]
]}

{call add_block block="video" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import video from '@/assets/data/video.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockVideo',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: video,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>