<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Карусель»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/blog-app/adding-blocks'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_blog_carousel_1.png" alt="Блок «Карусель»" description="Блок «Карусель»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — carousel"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Карусель» --&gt;

{$props = [
    'content' => 'settings',
    'color_scheme' => 'alter',
    'no_margin' => false,
    'blog_id' => '1',
    'limit' => '6',
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Выбор редакции'
    ],
    'posts' => [
        '1' => [
            'title' => 'Есть много вариантов Lorem Ipsum',
            'user' => [
                'name' => 'Иван'
            ],
            'datetime' => '2021-01-30',
            'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
            'link' => '#',
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ],
        '2' => [
            'title' => 'Этот трактат по теории этики был очень популярен в эпоху Возрождения',
            'user' => [
                'name' => 'Анна'
            ],
            'datetime' => '2021-01-30',
            'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
            'link' => '#',
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ],
        '3' => [
            'title' => 'Многие думают, что Lorem Ipsum - взятый с потолка',
            'user' => [
                'name' => 'Сергей'
            ],
            'datetime' => '2021-01-30',
            'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
            'link' => '#',
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ],
        '4' => [
            'title' => 'Есть много вариантов Lorem Ipsum',
            'user' => [
                'name' => 'Иван'
            ],
            'datetime' => '2021-01-30',
            'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
            'link' => '#',
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ],
        '5' => [
            'title' => 'Lorem Ipsum - это текст-рыба, часто используемый в печати и вэб-дизайне',
            'user' => [
                'name' => 'Дмитрий'
            ],
            'datetime' => '2021-01-30',
            'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
            'link' => '#',
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ]
    ]
]}

{call add_block block="carousel" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import blogCarousel from '@/assets/data/blog-carousel.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockBlogCarousel',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: blogCarousel
            }
        }
    }
</script>