<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Карточки»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_cards_1.png" alt="Блок «Карточки»" description="Блок «Карточки»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — cards"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Карточки» --&gt;

{$props = [
  'color_scheme' => 'main',
  'no_margin' => false,
  'title' => [
    'align' => 'left',
    'size' => 'medium, H2',
    'text' => ''
  ],
  'text' => 'Карточки — это неотъемлемая часть современной веб-разработки.',
  'buttons' => [
    '1' => [
      'template' => 'button-one',
      'mode' => 'link',
      'link' => '#',
      'target' => '_self',
      'type' => 'secondary',
      'anchor' => true,
      'icon' => 'none',
      'text' => 'Смотреть все'
    ]
  ],
  'cards' => [
    '1' => [
      'template' => 'card-one',
      'color_scheme' => 'main',
      'lazy' => false,
      'name' => 'Навигация по разделам',
      'link' => '#',
      'desc' => 'Карточки используются как ссылки на основные разделы или страницы с подробной информацией',
      'badge' => 'Ссылки',
      'image' => 'img/360x260.png'
    ],
    '2' => [
      'template' => 'card-one',
      'color_scheme' => 'main',
      'lazy' => false,
      'name' => 'Проекты и портфолио',
      'link' => '#',
      'desc' => 'В этом блоке может быть от 1 до 3 карточек. Их можно использовать как со ссылками, так и без.',
      'badge' => '',
      'image' => 'img/360x260.png'
    ],
    '3' => [
      'template' => 'card-one',
      'color_scheme' => 'main',
      'lazy' => false,
      'name' => 'Просты и понятны',
      'link' => '#',
      'desc' => 'Карточки визуально выделены и воспринимаются посетителями как цельная единица информации.',
      'badge' => '',
      'image' => 'img/360x260.png'
    ]
  ]
]}

{call add_block block="cards" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopCards from '@/assets/data/shop-cards.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopCards',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopCards,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>