<template>
	<div>
		<app-title text="Видео-уроки по настройке темы «Orion»" />
		<video-stream :stream="stream"></video-stream>
	</div>
</template>

<script>
	import VideoStream from "../../components/VideoStream";

    export default {
        name: 'VideoGuides',
        components: {
            VideoStream
        },
		data() {
            return {
				stream: [
					{
						id: `rY-zV-CcBZ8`,
						name: `Настройка сайта: начало работы`,
						description: `Начало работы с темой: как открыть админ-панель, основные настройки. Импорт архива с настройками демо-версий на свой сайт.`,
                        tags: [`Сайт`],
						duration: `2:34`
					},
					{
						id: `kyhNLIjvF_0`,
						name: `Настройка сайта: главная страница`,
						description: `Как вывести блоки на главную страницу, редактирование блока «Слайдер», немного о навигации. Настройка кнопок.`,
                        tags: [`Сайт`],
						duration: `5:53`
					},
                    {
                        id: `DIb_MY75Zrc`,
                        name: `Настройка сайта: элементы`,
                        description: `Настройка основных элементов сайта: шаблоны шапки, редактирование подвала, встроенная форма обратной связи`,
                        tags: [`Сайт`],
                        duration: `3:17`
                    },
                    {
                        id: `JteBr9zFzBs`,
                        name: `Настройка сайта: блок «Контакты»`,
                        description: `Настройка блока «Контакты», как задать координаты карты 2ГИС и настроить масштаб.`,
                        tags: [`Сайт`],
                        duration: `1:41`
                    },
                    {
                        id: `HPAMFplaI6k`,
                        name: `Настройка сайта: создание новых страниц`,
                        description: `Добавление новых страниц через визуальный редактор, вставка блоков на страницу, дублирование блоков.`,
                        tags: [`Сайт`],
                        duration: `5:42`
                    },
                    {
                        id: `AXYetLVGFKU`,
                        name: `Настройка сайта: фотогалерея`,
                        description: `Установка приложения Фото, создание альбомов, вывод и настройка блока «Галерея»`,
                        tags: [`Фото`],
                        duration: `2:59`
                    },
				]
            }
        }
    }
</script>