<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Галерея»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<p class="documentation__text">
			Блок «Галерея» выводит изображения из приложения «Фото» на страницы сайта в виде слайдера.
		</p>

		<app-image url="/img/documentation/block_gallery_1.png" alt="Блок «Галерея»" description="Блок «Галерея»" border />

		<p class="documentation__text">
			Для того, чтобы сделать слайдер из определенного альбома, создайте альбом в бэкенде фотогалереи, добавьте в него нужные изображения. В настройках блока впишите значение фильтра <accent text="album/1"/>, где <b>1</b> &mdash; это ID альбома.
		</p>

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — gallery"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Галерея» --&gt;

{$props = [
    'color_scheme' => 'main',
    'no_margin' => false,
    'lazy' => false,
    'lightbox' => false,
    'filter' => '',
    'limit' => '6',
    'size' => 'big',
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Ваше портфолио, проекты и фотогалерея'
    ],
    'link' => [
        'template' => 'link-two',
        'anchor' => true,
        'uppercase' => true,
        'color' => 'secondary',
        'target' => '_self',
        'link' => '#',
        'text' => 'Смотреть все'
    ]
]}

{call add_block block="gallery" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import gallery from '@/assets/data/gallery.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockGallery',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: gallery,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>