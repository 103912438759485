<template>
  <div>
    <div class="documentation__header">
      <app-title text="Элемент «Заголовок»"></app-title>
      <app-go-back :link="{name: 'Набор элементов', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-subtitle text="Вызов элемента на страницах магазина"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Элемент «Заголовок» --&gt;
{$props = [
  'align' => 'left',
  'size' => 'medium, H2',
  'text' => 'Ваш текст заголовка'
]}

{call add_element element="title" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopTitle from '@/assets/data/shop-element-title.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'ElementShopTitle',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopTitle,
    }
  }
}
</script>