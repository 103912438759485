<template>
    <div>
        <div class="documentation__header">
            <app-title text="Элемент «Товар»"></app-title>
            <app-go-back
                :link="{
                    name: 'Набор элементов',
                    path: '/documentation/shop-app/adding-blocks',
                }"
            ></app-go-back>
        </div>

        <app-subtitle text="Вызов элемента на страницах магазина" />

        <!-- prettier-ignore -->
        <div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Элемент «Товар» --&gt;
{$props = [
  'id' => '1',
  'template' => 'one',
  'color_scheme' => 'main',
  'lazy' => false,
  'image_type' => 'single',
  'use_link' => true,
  'image_size' => '400',
  'image_mode' => 'margins',
  'show_desc' => false,
  'show_rating' => true,
  'show_stocks' => true,
  'show_controls' => true,
  'hide_button' => true,
  'show_compare' => true,
  'show_favorite' => true,
  'button' => 'В корзину',
  'button_added' => 'Оформить заказ'
]}

{$_product = $wa->shop->product($props.id)}
{call add_element element="product/product-`$props.template`" props=$props product=$_product}</ssh-pre>
		</div>

        <app-table :data="tableData" />
    </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import IconCopy from '@/components/icons/IconCopy.vue';
import shopProduct from '@/assets/data/shop-element-product.json';
import AppGoBack from '../../components/AppGoBack';

export default {
    name: 'ElementShopProduct',
    components: {
        SshPre,
        IconCopy,
        AppGoBack,
    },
    data() {
        return {
            tableData: shopProduct,
        };
    },
};
</script>
