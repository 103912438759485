<template>
    <div>
        <app-title text="Встроенный набор иконок" />
        <p class="documentation__text">
            Для того чтобы добавить или сменить иконку в блок через Smarty
            впишите ее номер в $props в свойство <accent text="iconset" />, а
            свойство <accent text="icon" /> оставьте пустым. Блок должен
            поддерживать встроенные иконки.
        </p>

        <!-- prettier-ignore -->
        <div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>'iconset' => '1',
'icon' => '',</ssh-pre>
		</div>

        <p class="documentation__text">
            Если вы хотите загрузить свою иконку оставьте свойство
            <accent text="iconset" /> пустым, загрузите ваше изображение через
            файл-менеджер приложения «Сайт», пропишите путь относительно папки с
            темой в свойство <accent text="icon" />.
        </p>

        <!-- prettier-ignore -->
        <div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>'iconset' => '',
'icon' => 'img/100x100.png',</ssh-pre>
		</div>

        <icon-box :set="set" />

        <p class="documentation__text documentation__text--tiny">
            Icons made by
            <a
                class="documentation__link"
                target="_blank"
                rel="nofollow"
                href="https://www.freepik.com"
                title="Freepik"
                >Freepik</a
            >
            from
            <a
                class="documentation__link"
                target="_blank"
                rel="nofollow"
                href="https://www.flaticon.com/"
                title="Flaticon"
                >www.flaticon.com</a
            >
        </p>
    </div>
</template>

<script>
import IconBox from '../../components/IconBox';
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import IconCopy from '@/components/icons/IconCopy.vue';

export default {
    name: 'IconSet',
    components: {
        IconBox,
        SshPre,
        IconCopy,
    },
    data() {
        return {
            set: [
                {
                    name: `Общение`,
                    value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
                },
                {
                    name: `Электронная коммерция`,
                    value: [
                        15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                        29, 30,
                    ],
                },
                {
                    name: `Интерфейс`,
                    value: [
                        31, 53, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
                        44, 45, 46, 47, 48, 49, 50, 51, 52, 54, 55, 56, 57, 58,
                        59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
                    ],
                },
                {
                    name: `Дата и время`,
                    value: [
                        69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
                        83, 84,
                    ],
                },
                {
                    name: `Еда и рестораны`,
                    value: [
                        85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
                        99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109,
                        110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
                        121,
                    ],
                },
                {
                    name: `Природа`,
                    value: [
                        122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132,
                        133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143,
                        144, 145, 146, 147,
                    ],
                },
                {
                    name: `Медицина`,
                    value: [
                        148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158,
                        159, 160, 161, 162, 163, 164, 165, 166, 167,
                    ],
                },
                {
                    name: `Бизнес и работа`,
                    value: [
                        168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178,
                        179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189,
                        190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200,
                        201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211,
                        212, 213, 214, 215, 216,
                    ],
                },
                {
                    name: `Транспорт и путешествия`,
                    value: [
                        217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227,
                        228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238,
                        239, 240, 241, 242, 243, 244, 245, 246, 247, 248,
                    ],
                },
                {
                    name: `Город`,
                    value: [
                        249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259,
                        260, 261, 262, 263, 264, 265, 266, 267, 268,
                    ],
                },
                {
                    name: `Стрелки`,
                    value: [
                        269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279,
                        280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290,
                        291, 292, 293, 294, 295,
                    ],
                },
                {
                    name: `Социальные сети`,
                    value: [
                        296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306,
                        307, 308, 309, 310, 311, 312, 313, 314, 315,
                    ],
                },
            ],
        };
    },
};
</script>
