<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Текст»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_text_1.png" alt="Блок «Текст»" description="Блок «Текст»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — text"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Текст» --&gt;

{$props = [
  'color_scheme' => 'main',
  'no_margin' => false,
  'full_width' => true,
  'use_css' => true,
  'title' => [
    'align' => 'left',
    'size' => 'medium, H2',
    'text' => 'Тема дизайна «Orion Shop»'
  ],
  'content' => '&lt;p&gt;Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение шаблона, а также реальное распределение букв и пробелов в абзацах, которое не получается при простой дубликации.&lt;/p&gt;&lt;ul&gt;&lt;li&gt;Тема дизайна Orion Shop для фреймворка Webasyst позволит вам создать современный лендинг или корпоративный сайт, оптимизированный для любых устройств.&lt;/li&gt;&lt;li&gt;Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации.&lt;/li&gt;&lt;/ul&gt;&lt;p&gt;Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение шаблона, а также реальное распределение букв и пробелов в абзацах, которое не получается при простой дубликации.&lt;/p&gt;&lt;h3&gt;Дополнительный текст&lt;/h3&gt;&lt;p&gt;Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации.&lt;/p&gt;&lt;blockquote&gt;Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.&lt;/blockquote&gt;'
]}

{call add_block block="text" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopText from '@/assets/data/shop-text.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopText',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopText,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>