<template>
    <div>
        <div class="documentation__header">
            <app-title text="Блок «Рассказ»"></app-title>
            <app-go-back
                :link="{
                    name: 'Набор блоков',
                    path: '/documentation/shop-app/adding-blocks',
                }"
            ></app-go-back>
        </div>

        <app-image
            url="/img/documentation/block_story_1.png"
            alt="Блок «Рассказ»"
            description="Блок «Рассказ»"
            border
        />

        <app-subtitle
            text="Вызов блока на страницах сайта"
            label="ID — story"
        />

        <info-box
            text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах"
            :link="infoLink"
        />

        <!-- prettier-ignore -->
        <div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Рассказ» --&gt;

{$props = [
    'direction' => 'forward',
    'color_scheme' => 'main',
    'no_margin' => false,
    'lazy' => false,
    'align' => 'left',
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Если вам нужен Lorem Ipsum для проекта'
    ],
    'text' => '&lt;p&gt;Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.&lt;/p&gt;',
    'link' => [
        'template' => 'link-two',
        'anchor' => true,
        'uppercase' => true,
        'color' => 'secondary',
        'target' => '_self',
        'link' => '#',
        'text' => 'Подробнее'
    ],
    'image' => 'img/710x500.png',
    'list' => [
        'template' => 'list-one',
        'inverse' => false,
        'align' => 'left',
        'list' => 'Есть много вариантов Lorem Ipsum * Но большинство из них имеет не всегда приемлемые модификации'
    ]
]}

{call add_block block="story" props=$props}</ssh-pre>
		</div>

        <app-table :data="tableData" />
    </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import IconCopy from '@/components/icons/IconCopy.vue';
import shopStory from '@/assets/data/shop-story.json';
import AppGoBack from '../../components/AppGoBack';

export default {
    name: 'BlockShopStory',
    components: {
        SshPre,
        IconCopy,
        AppGoBack,
    },
    data() {
        return {
            tableData: shopStory,
            infoLink: {
                name: 'Подробнее',
                path: '/documentation/shop-app/adding-blocks',
            },
        };
    },
};
</script>
