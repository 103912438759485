<template>
    <div>
        <app-title text="Тема дизайна «Orion»"></app-title>

        <video-banner
            :button="{ text: `Смотреть`, url: `/documentation/video-guides` }"
            title="Видео-уроки по настройке темы"
            text="Серия видео о теме «Orion»"
        />

        <p class="documentation__text">
            Тема дизайна «Orion» — это удобный и гибко настраиваемый конструктор
            для приложения «Сайт» и современная фотогалерея для приложения
            «Фото». Тема позволяет создавать как промо-сайты, так и
            многостраничные ресурсы с использованием встроенного визуального
            редактора или с помощью кода из готовых блоков.
        </p>

        <app-subtitle text="Начало работы" number="1" />

        <div class="documentation__grid">
            <app-column
                :image="{
                    url: '/img/documentation/start_1.png',
                    alt: 'Тема дизайна «Orion»',
                }"
                name="Авторизуйтесь в Webasyst как администратор"
                text="Для того, чтобы получить доступ к настройкам темы, перейдите и авторизуйтесь в бэкенде Webasyst. По умолчанию используется адрес /webasyst/"
            />
            <app-column
                :image="{
                    url: '/img/documentation/start_2.png',
                    alt: 'Тема дизайна «Orion»',
                }"
                name="Перейдите во фронтенд"
                text="Настройки главной страницы, оформления темы, а также дополнительных элементов осуществляются через фронтенд."
            />
            <app-column
                :image="{
                    url: '/img/documentation/start_3.png',
                    alt: 'Тема дизайна «Orion»',
                }"
                name="Откройте настройки темы"
                text="На каждой странице вашего сайта слева появится иконка с шестерней. Нажмите на нее, чтобы открыть настройки."
            />
        </div>

        <p class="documentation__text">
            Если на одной установке Webasyst у вас подключено несколько доменов,
            убедитесь, что вы авторизовались в Webasyst со страницы домена, на
            котором установлена тема. Например, если вы используете «Orion» на
            сайте
            <accent text="my-domain.com" />
            , зайти в свою учетную запись следует со страницы
            <accent text="my-domain.com/webasyst" />
            .
        </p>

        <app-subtitle text="Установка темы дизайна" number="2" />

        <p class="documentation__text">
            Webasyst можно устанавливать
            <a
                href="https://developers.webasyst.ru/docs/installation/remote/"
                class="documentation__link"
                target="_blank"
                >на хостинг</a
            >, поддерживающий PHP и MySQL, на
            <a
                href="https://developers.webasyst.ru/docs/installation/windows/"
                class="documentation__link"
                target="_blank"
                >локальный веб-сервер</a
            >
            или воспользоваться
            <a
                href="https://www.webasyst.ru/platform/cloud/"
                class="documentation__link"
                target="_blank"
                >облаком</a
            >. Купите и установите тему дизайна через Инсталлер:
        </p>

        <app-image
            url="/img/documentation/installation_1.png"
            alt="Установка темы дизайна"
        />

        <p class="documentation__text">
            Откройте приложение «Сайт» 🠖 «Дизайн» 🠖 «Настройки». Выберите Orion
            в качестве
            <accent text="темы оформления" />
            и мобильной темы оформления.
        </p>

        <app-image
            url="/img/documentation/installation_2.png"
            alt="Установка темы дизайна"
        />

        <p class="documentation__text">
            Перейдите во фронтенд и откройте боковую панель с настройками.
        </p>

        <app-subtitle text="Основные настройки" number="3" />

        <p class="documentation__text">
            В разделе «Основные настройки» во вкладке «Оформление» можно
            стилизовать тему по вашему вкусу. Вы можете выбрать одну из 18
            цветовых схем, настроить шрифты для заголовков и сайта в целом,
            определиться с внешним видом кнопок, настроить интенсивность теней.
        </p>

        <app-image
            url="/img/documentation/main_settings_1.png"
            alt="Общие настройки"
        />

        <p class="documentation__text">
            После завершения конфигурации темы нажмите кнопку «Сохранить», чтобы
            применить изменения. Или воспользуйтесь комбинацией клавиш
            <accent text="CTRL + S" />
            .
        </p>

        <app-subtitle text="Свои стили и скрипт" number="4" />

        <p class="documentation__text">
            Свой CSS и JavaScript код следует размещать в файлах
            <accent text="user.css" />
            и
            <accent text="user.js" />
            соответственно, эти файлы не будут перезаписаны при обновлении темы.
            Найти их можно в приложении «Сайт» 🠖 «Дизайн» 🠖 «Шаблоны»
        </p>

        <p class="documentation__text">
            Мы не рекомендуем вносить изменения в оригинальный код темы. Если
            такие изменения привели к ошибкам на странице, зайдите в
            отредактированные файл и нажмите «Посмотреть оригинал». Далее
            нажмите на кнопку «Сбросить мои изменения к оригинальной версии
            файла».
        </p>

        <app-image
            url="/img/documentation/main_settings_3.png"
            alt="Общие настройки"
        />

        <p class="documentation__text">
            Если вам все же необходимо отредактировать css стили темы, вы можете
            для удобства заменить минифицированный файл стилей. Для этого в
            head.html найдите строку orion.min.css и исправьте ее на orion.css
        </p>

        <app-subtitle text="Отключение демо-панели" number="5" />

        <p class="documentation__text">
            Настроить отображение демо-панели можно в бэкенде темы в приложении
            «Сайт» 🠖 «Дизайн» 🠖 «Оформление».
        </p>

        <app-image
            url="/img/documentation/main_settings_2.png"
            alt="Общие настройки"
        />

        <p class="documentation__text">Демо-панель может быть:</p>

        <app-list
            text="«Скрыта для всех»;«Видна только администраторам» — отображает панель настроек темы, если вы авторизованы как администратор;«Видна всем пользователям» — отображает панель настроек темы, если вы авторизованы как администратор, отображает демо-панель для всех остальных пользователей"
        />

        <p class="documentation__text">
            Когда ваш сайт будет полностью готов, рекомендуется установить эту
            настройку в значение «Скрыта для всех». Это сократит
            <accent text="время загрузки" />
            сайта у пользователей.
        </p>

        <p class="documentation__text">
            Также, если вы хотите скрыть содержимое сайта во время настройки, вы
            можете активировать функцию «Включить технические работы на сайте»
            во вкладке «Прочее» 🠖 «Технические работы». В этом режиме все, кроме
            администраторов, будут видеть приветственный экран с сообщением от
            тех работах.
        </p>

        <info-box
            text="Нашли ошибку теме или документации? Сообщите о ней на support@active-vision.ru"
            :link="infoLink"
        />

        <app-subtitle text="Форма обратной связи" number="6" />

        <p class="documentation__text">
            Форма обратной связи предлагает пользователю заполнить контактную
            информацию. Эти данные будут отправлены на вашу электронную почту.
            Элементы формы:
        </p>

        <app-list
            text="Имя — обязательное поле;Email — необязательное поле, отключается в настройках;Номер телефона — необязательное поле; Согласие на обработку персональных данных — необязательно; Капча — обязательный элемент, его нельзя удалить, но можно заменить на Google reCAPTCHA"
        />

        <p class="documentation__text">
            Для работы формы необходимо заполнить Email адрес для уведомлений и,
            если это необходимо, дополнительный Email. На эти две почты будут
            приходить уведомления от сайта. Отправителем будет почтовый ящик,
            указанный в настройках Webasyst.
        </p>

        <app-image
            url="/img/documentation/feedback_form_1.png"
            alt="Настройки формы"
        />

        <p class="documentation__text">
            Включив функцию
            <accent text="Свой код формы" />
            , вы сможете заменить стандартную форму на свою. Для этого в
            Webasyst создайте в приложении «Сайт» ➔ «Блоки» новый блок с именем
            <b>site.feedback_form</b>. Добавьте в этот блок любой код или текст,
            теперь он будет отображаться вместо формы.
        </p>

        <app-subtitle text="Капча" number="7" />

        <p class="documentation__text">
            Выражаясь простыми словами, CAPTCHA - это тест является ли
            пользователь реальным или спам-роботом. Вы можете заменить
            стандартную капчу на
            <a
                class="documentation__link"
                href="https://www.google.com/recaptcha/about/"
                target="_blank"
                >Google reCAPTCHA</a
            >. Для этого зарегистрируйтесь в сервисе, перейдите в Admin Console,
            создайте
            <accent text="reCAPTCHA v2" />
            или
            <accent text="reCAPTCHA v3" />
            для своего сайта.
        </p>

        <p class="documentation__text">
            Далее перейдите в бэкенд Webasyst «Настройки» ➔ «Капча» и добавьте
            полученные ключи. Для версии v3 доступен невидимый режим.
        </p>

        <app-image
            url="/img/documentation/feedback_form_2.png"
            alt="Настройки формы"
        />

        <app-subtitle text="Дополнительные элементы" number="8" />

        <p class="documentation__text">
            В теме имеются дополнительные элементы, которые присутствуют на всех
            страницах.
        </p>

        <p class="documentation__text">
            <b>Фиксированная кнопка</b> — это дополнительный элемент, который
            зафиксирован поверх страницы и появляется через 3 секунды после
            загрузки сайта. Он является кнопкой, которая открывает форму
            обратной связи. Если в настройках включено отображение окна
            «Политика Cookie» и соглашение не принято, кнопка не появится.
        </p>

        <app-image
            url="/img/documentation/additional_elements_2.png"
            alt="Фиксированная кнопка"
        />

        <p class="documentation__text">
            <b>Cookie</b> — это файл с данными, который сохраняется на
            компьютере пользователя после посещения сайта. Webasyst в целом и
            тема «Orion» в частности использует cookie и другие аналогичные
            технологии. Законодательство многих стран обязывает получать
            согласие пользователей на обработку персональных данных, cookie
            файлы не редко хранят информацию о поведении пользователей, что
            попадает под это определение. Для этих целей в тему добавлен элемент
            «Политика Cookie».
        </p>

        <p class="documentation__text">
            Пользователь должен принять политику Cookie, нажав кнопку на
            элементе. В этом случае окно будет показано только один раз. Элемент
            настраивается во фронтенде «Прочее» 🠖 «Политика Cookie».
        </p>

        <app-image
            url="/img/documentation/additional_elements_1.png"
            alt="Политика Cookie"
        />

        <p class="documentation__text">
            Он будет располагаться в правом нижнем или левом нижнем углу сайта и
            меть только одну кнопку, при нажатии на которую будет считаться, что
            пользователь согласился с политикой Cookie.
        </p>

        <app-subtitle text="Поддерживаемые плагины" number="9" />

        <p class="documentation__text">
            Начиная с версии 2.6.0 тема поддерживает плагин для поиска по сайту.
            Установка этого плагина не является обязательной.
        </p>

        <ul class="documentation__cards">
            <li class="documentation__card">
                <a
                    href="https://www.webasyst.ru/store/plugin/site/search/"
                    class="documentation__card-image"
                    target="_blank"
                >
                    <app-image
                        url="/img/documentation/start_4.png"
                        alt="Плагин «Поиск»"
                        border
                    />
                </a>
                <a
                    href="https://www.webasyst.ru/store/plugin/site/search/"
                    class="documentation__card-name"
                    target="_blank"
                    >Плагин «Поиск»</a
                >
                <p class="documentation__card-desc">
                    Позволяет искать по текстовым страницам сайта.
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
import VideoBanner from '../../components/VideoBanner';

export default {
    name: 'Start',
    components: {
        VideoBanner,
    },
    data() {
        return {
            infoLink: {},
        };
    },
};
</script>
