<template>
    <div class="support">
        <app-box>
            <template v-slot:header>
                <app-title text="У вас есть вопрос по теме?"></app-title>
                <p class="support__desc">
                    Напишите нам на почту
                    <a
                        class="support__link"
                        href="mailto:support@active-vision.ru?subject=Тема дизайна ORION. Запрос в поддержку"
                        >support@active-vision.ru</a
                    >. Обычно мы отвечаем в день обращения, если вы не получили
                    наш ответ в течение нескольких дней, возможно ваша заявка до
                    нас не дошла. Попробуйте отправить письмо повторно.
                </p>
            </template>
            <template v-slot:content>
                <p class="support__desc">
                    Что входит в
                    <accent text="бесплатную" />
                    поддержку?
                </p>
                <ul class="support__list">
                    <app-list-item
                        >Консультация по использованию и настройке
                        темы.</app-list-item
                    >
                    <app-list-item
                        >Консультация по вопросам приобретения лицензии темы
                        дизайна.</app-list-item
                    >
                    <app-list-item
                        >Выявление и исправление ошибок в работе
                        темы.</app-list-item
                    >
                </ul>
                <div class="support__price">Бесплатно</div>
            </template>
        </app-box>

        <app-box>
            <template v-slot:content>
                <p class="support__desc">
                    Что относится к
                    <accent text="платной" />
                    поддержке?
                </p>
                <ul class="support__list">
                    <app-list-item
                        >Доработка темы дизайна под ваши
                        бизнес-задачи.</app-list-item
                    >
                    <app-list-item
                        >Редактирование или добавление новых блоков, элементов,
                        страниц по вашему макету.
                    </app-list-item>
                    <app-list-item
                        >Интеграция плагинов, не поддерживаемых
                        по-умолчанию.</app-list-item
                    >
                    <app-list-item
                        >Наполнение сайта контентом (текст, изображения,
                        разметка страниц).</app-list-item
                    >
                    <app-list-item
                        >Помощь в установке CMS, темы, плагинов.</app-list-item
                    >
                </ul>
                <div class="support__price">1500 руб/час</div>
            </template>
        </app-box>
    </div>
</template>

<script>
import AppBox from '../components/AppBox';
import AppListItem from '../components/AppListItem';

export default {
    name: 'Support',
    data() {
        return {};
    },
    components: {
        AppBox,
        AppListItem,
    },
};
</script>

<style lang="less">
.support {
    &__desc {
        max-width: 800px;
    }

    &__link {
        color: @primary;
        transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:hover {
            color: darken(@primary, 15%);
        }
    }

    &__list {
        margin-top: 10px;
    }

    &__price {
        position: relative;
        display: inline-block;
        padding: 5px 10px 5px 24px;
        margin-top: 15px;
        border-radius: 4px;
        background-color: @secondary;
        font-size: 1.05em;
        font-family: @title-font;
        font-weight: 600;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 8px;
            transform: translateY(-50%);
            display: block;
            background-color: @background;
            width: 8px;
            height: 8px;
            border-radius: 4px;
        }
    }
}
</style>