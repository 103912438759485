<template>
    <ul class="list">
        <li class="list__item" v-for="(item, key) in parsedList" :key="key">
            {{ item }}
        </li>
    </ul>
</template>

<script>
export default {
    name: 'AppList',
    props: {
        text: String,
    },
    computed: {
        parsedList() {
            return this.text.split(`;`);
        },
    },
};
</script>

<style lang="less">
.list {
    margin-bottom: 20px;

    &__item {
        position: relative;
        margin-bottom: 10px;
        padding-left: 32px;

        &::before {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            left: 8px;
            top: 10px;
            border-radius: 50%;
            background: darken(@secondary, 20%);
        }
    }
}
</style>