<template>
	<div class="video-stream">
		<div class="video-stream__player">
			<div class="video-stream__embed">
				<iframe width="720" height="405" :src="`https://youtube.com/embed/${current.id}?autoplay=1&rel=0&showinfo=0`" frameborder="0" allowfullscreen></iframe>
			</div>
			<div class="video-stream__info">
				<h4 class="video-stream__name">{{ current.name }}</h4>

				<div class="video-stream__info-grid">
					<p class="video-stream__duration">{{ current.duration }}</p>
					<div class="video-stream__tags">
						<span v-for="(tag, key) of current.tags" :key="key" class="video-stream__tag">{{ tag }}</span>
					</div>
				</div>
				<p class="video-stream__description">{{ current.description }}</p>

				<button class="video-stream__next" :class="{'is-hidden': disableNext}" @click="next()">
					Следующее видео
					<icon-arrow></icon-arrow>
				</button>
			</div>
		</div>
		<ul class="video-stream__list">
			<li ref="streamItem" v-for="(video, key) of stream" :key="key" class="video-stream__item" @click="switchCurrent(key)">
				<div class="video-stream__item-header">
					<picture>
						<source :srcset="`https://i.ytimg.com/vi_webp/${video.id}/maxresdefault.webp`" type="image/webp">
						<img :src="`https://i.ytimg.com/vi/${video.id}/maxresdefault.jpg`" :alt="video.name" class="video-stream__item-image">
					</picture>
					<button class="video-stream__item-button" aria-label="Запустить видео">
						<span class="video-stream__item-message">Воспроизводится</span>
						<svg viewBox="0 0 68 48">
							<path class="video-stream__item-shape" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" />
							<path class="video-stream__item-icon" d="M 45,24 27,14 27,34" />
						</svg>
					</button>
				</div>
				<div class="video-stream__item-content">
					<p class="video-stream__item-name">{{ video.name }}</p>
					<p class="video-stream__item-duration">{{ video.duration }}</p>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
    import IconArrow from '@/components/icons/IconArrow.vue';

    export default {
        name: 'VideoStream',
        props: {
            stream: Array
        },
		components: {
          IconArrow
		},
        data() {
            return {
				current: this.stream[0],
				currentIndex: 0,
				disableNext: false,
            }
        },
		computed: {
            videoAmount() {
                return this.stream.length - 1;
			}
		},
		methods: {
            switchCurrent(i) {
                this.current = this.stream[i];
                this.currentIndex = i;
                this.setNextButton();
                this.switchActiveVideoIndicator(i);

                const content = document.querySelector(`.app__content`);
                content.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            },
			next() {
                this.switchCurrent(++this.currentIndex);
			},
			setNextButton() {
                this.currentIndex < this.videoAmount ? this.disableNext = false : this.disableNext = true;
            },
			switchActiveVideoIndicator(i) {
                this.$refs.streamItem.forEach((item, key) => {
                    key === i ? this.$refs.streamItem[key].classList.add(`is-active`) : this.$refs.streamItem[key].classList.remove(`is-active`);
                });
			}
		},
		mounted: function () {
            this.switchActiveVideoIndicator(0);
        }
    }
</script>

<style lang="less">
	.video-stream {
		&__player {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 1.5rem;
			margin-bottom: 4rem;
		}

		&__embed {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			background-color: #000;
			overflow: hidden;

			& iframe {
				position: absolute;
				top: -2px;
				left: -2px;
				width: calc(100% + 4px);
				height: calc(100% + 4px);
				border: none;
			}
		}

		&__info {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		&__name {
			margin-bottom: 20px;
			font-family: @title-font;
			font-weight: 600;
			font-size: 1.3em;
		}

		&__description {
			margin-bottom: 10px;
			font-size: .85em;
			color: lighten(@text, 30%);
		}

		&__info-grid {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-gap: 1.5rem;
			margin-bottom: 10px;
		}

		&__tags {
			display: flex;
			flex-wrap: wrap;
			gap: .5rem;
			justify-content: flex-end;
		}

		&__tag {
			display: block;
			padding: 0 0.6rem;
			border-radius: 4px;
			background-color: @contrast;
			color: @text-inverse;
			font-size: .9em;
			white-space: nowrap;
		}

		&__duration {
			font-size: .85em;
			color: lighten(@text, 30%);
		}

		&__next {
			display: flex;
			align-items: center;
			align-self: flex-end;
			gap: 1rem;
			padding: unset;
			margin-top: auto;
			border: none;
			background-color: transparent;
			font: inherit;
			letter-spacing: 0.07em;
			text-transform: uppercase;
			font-size: 0.75em;
			cursor: pointer;

			&.is-hidden {
				display: none;
			}

			& svg {
				border-radius: 50%;
				background-color: @contrast;
				fill: @background;
			}
		}

		&__list {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 1rem;
		}

		&__item {
			background-color: @background;
			border-radius: 8px;
			text-decoration: none;
			border: 1px solid darken(@secondary, 4%);
			transition: all 0.125s cubic-bezier(0.250, 0.460, 0.450, 0.940);
			overflow: hidden;
			cursor: pointer;

			&:hover {
				box-shadow: @medium-shadow-hover;

				& .video-stream__item-button {
					& svg {
						opacity: 1;
					}
				}
			}

			&.is-active {
				& .video-stream__item-button {
					& span {
						display: block;
					}

					& svg {
						display: none;
					}
				}
			}
		}

		&__item-header {
			position: relative;
		}

		&__item-button {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 1;
			display: block;
			padding: 0;
			border: none;
			font: inherit;
			background-color: transparent;
			transform: translate(-50%, -50%);
			cursor: pointer;

			&.is-hidden {
				display: none;
			}

			&:focus {
				outline: none;
			}

			&:focus,
			&:hover {
				& .video-stream__item-shape {
					fill: #ff0000;
					fill-opacity: 1;
				}
			}

			& svg {
				display: block;
				opacity: 0;
				transition: all 0.125s cubic-bezier(0.250, 0.460, 0.450, 0.940);
				width: 58px;
			}
		}

		&__item-message {
			display: none;
			padding: 0 .6rem;
			border-radius: 4px;
			color: @text-inverse;
			letter-spacing: 0.07em;
			text-transform: uppercase;
			font-size: 0.75em;
			background-color: #212121;
			opacity: 0.8;
		}

		&__item-shape {
			fill: #212121;
			fill-opacity: 0.8;
			transition: all 0.125s cubic-bezier(0.250, 0.460, 0.450, 0.940);
		}

		&__item-icon {
			fill: @text-inverse;
		}

		&__item-image {
			display: block;
			width: 100%;
			height: auto;
			margin-top: -1px;
		}

		&__item-content {
			display: grid;
			grid-template-columns: 1fr auto;
			grid-gap: 1rem;
			padding: 1rem;
		}

		&__item-name {
			font-family: @title-font;
			font-weight: 600;
		}

		&__item-duration {
			font-size: .85em;
			color: lighten(@text, 30%);
		}
	}

	@media (min-width: 480px) {
		.video-stream {
			&__player {
				padding: 1.5rem;
				border-radius: 8px;
				border: 1px solid darken(@secondary, 4%);
				background-color: @background;
			}

			&__list {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	@media (min-width: 992px) {
		.video-stream {
			&__player {
				grid-template-columns: 2fr 1fr;
			}

			&__list {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
</style>