import { render, staticRenderFns } from "./IconBack.vue?vue&type=template&id=0af25540&scoped=true&"
var script = {}
import style0 from "./IconBack.vue?vue&type=style&index=0&id=0af25540&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0af25540",
  null
  
)

export default component.exports