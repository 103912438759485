<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Колонки»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_columns_1.png" alt="Блок «Колонки»" description="Блок «Колонки»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — columns"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Колонки» --&gt;

{$props = [
    'id' => '',
    'color_scheme' => 'main',
    'no_margin' => false,
    'template' => '3',
    'circle' => true,
    'icon_style' => 'is-linear',
    'title' => [
        'align' => 'center',
        'size' => 'medium, H2',
        'text' => 'Современные технологии для выражения вашего бизнеса в сети'
    ],
    'badge' => 'Готовые блоки',
    'columns' => [
        '1' => [
            'show' => true,
            'iconset' => '1',
            'image' => '',
            'image_size' => 'small',
            'name' => 'Быстрый старт',
            'text' => '&lt;p&gt;Тема «Orion» для CMS Webasyst позволяет вам создать качественный веб-ресурс за короткое время без знания языков программирования. Сайт на «Orion» состоит из готовых элементов, настроить которые можно всего за один день. Простые и гибкие настройки делают работу по изменению сайта удобной и быстрой.&lt;/p&gt;'
        ],
        '2' => [
            'show' => true,
            'iconset' => '2',
            'image' => '',
            'image_size' => 'small',
            'name' => 'Удобный интерфейс',
            'text' => '&lt;p&gt;Дружественный интерфейс не позволит вашим посетителям запутаться среди элементов сайта. При разработке темы мы старались сделать привлекательный дизайн с простым функционалом. Мы уделили внимание всем мелочам: от плавности прокрутки, до комфортной работы с формой.&lt;/p&gt;'
        ],
        '3' => [
            'show' => true,
            'iconset' => '3',
            'image' => '',
            'image_size' => 'small',
            'name' => 'Адаптивность и кроссбраузерность',
            'text' => '&lt;p&gt;«Orion» отлично оптимизирован для работы на мобильных устройствах. В то же время, на ноутбуках и ПК сайт будет смотреться отлично. Верстка адаптирована ко всем разрешениям, в том числе для показа на широкоформатных экранах с соотношением сторон 21:9.&lt;/p&gt;'
        ],
        '4' => [
            'show' => false,
            'iconset' => '4',
            'image' => '',
            'image_size' => 'small',
            'name' => 'Валидный код',
            'text' => '&lt;p&gt;Приобретая тему, вы получаете валидный и чистый код. Ваш сайт будет работать быстро и правильно. Используемые в теме технологии: HTML, LESS, Smarty, БЭМ, TypeScript.&lt;/p&gt;'
        ]
    ]
]}

{call add_block block="columns" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import columns from '@/assets/data/columns.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockColumns',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: columns,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>