<template>
    <div>
        <div class="documentation__header">
            <app-title text="Блок «Товары»"></app-title>
            <app-go-back
                :link="{
                    name: 'Набор блоков',
                    path: '/documentation/block-list',
                }"
            ></app-go-back>
        </div>

        <app-image
            url="/img/documentation/block_products_1.png"
            alt="Блок «Товары»"
            description="Блок «Товары»"
            border
        />

        <p class="documentation__text">
            Для работы блока требуется Shop-Script. В приложении «Магазин» 🠖
            «Товары», в боковом меню создайте новый список с именем
            <accent text="user_set" /> или любым другим, главное, чтобы ID
            списка совпадало с тем, которое указано в настройках блока. Далее
            добавьте в список товары.
        </p>

        <app-image
            url="/img/documentation/block_cards_2.png"
            alt="Блок «Карточки»"
            border
        />

        <app-subtitle
            text="Вызов блока на страницах сайта"
            label="ID — products"
        />

        <info-box
            text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах"
            :link="infoLink"
        />

        <!-- prettier-ignore -->
        <div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Товары» --&gt;

{$props = [
    'color_scheme' => 'alter',
    'no_margin' => false,
    'template' => 'product-one',
    'set' => 'user_set',
    'limit' => '8',
    'title' => [
        'align' => 'center',
        'size' => 'medium, H2',
        'text' => ''
    ],
    'product' => [
        'color_scheme' => 'alter',
        'lazy' => false,
        'image_type' => 'gallery',
        'use_link' => false,
        'image_size' => '400',
        'image_mode' => 'margins',
        'show_desc' => false,
        'show_rating' => true,
        'show_stocks' => true,
        'hide_button' => false,
        'button_name' => 'Подробнее'
    ],
    'buttons' => [
        '1' => [
            'template' => 'button-one',
            'mode' => 'link',
            'link' => '#',
            'target' => '_self',
            'type' => 'primary',
            'anchor' => true,
            'icon' => 'none',
            'wide' => true,
            'text' => 'Перейти в магазин'
        ]
    ]
]}

{call add_block block="products" props=$props}</ssh-pre>
		</div>

        <app-table :data="tableData" />
    </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import IconCopy from '@/components/icons/IconCopy.vue';
import products from '@/assets/data/products.json';
import AppGoBack from '../../components/AppGoBack';

export default {
    name: 'BlockProducts',
    components: {
        SshPre,
        IconCopy,
        AppGoBack,
    },
    data() {
        return {
            tableData: products,
            infoLink: {
                name: 'Подробнее',
                path: '/documentation/adding-blocks',
            },
        };
    },
};
</script>
