<template>
    <div class="error">
        <h1 class="error__title">404</h1>
        <h3 class="error__message">Страница не найдена</h3>
        <app-button
            type="primary"
            text="На главную"
            url="/"
            router
        ></app-button>
    </div>
</template>

<script>
export default {
    name: 'AppError',
};
</script>

<style lang="less">
.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;

    &__title {
        font-family: @title-font;
        font-weight: 600;
        font-size: 5em;
    }

    &__message {
        font-family: @title-font;
        font-weight: 600;
        font-size: 1.4em;
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .error {
        &__title {
            font-size: 7em;
        }

        &__message {
            font-size: 2em;
        }
    }
}
</style>