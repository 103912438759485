<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Описание»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_description_1.png" alt="Блок «Описание»" description="Блок «Описание»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — description"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Описание» --&gt;

{$props = [
    'align' => 'left',
    'color_scheme' => 'main',
    'no_margin' => false,
    'lazy' => false,
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Блок [«Описание»] — прекрасный способ рассказать о вашем товаре или услуге'
    ],
    'badge' => 'Описание',
    'text' => 'Этот блок содержит интуитивно понятный элемент — переключатель изображений. Это универсальный экран сайта, который можно использовать, чтобы рассказать о сильных сторонах ваших продуктов, показать вашу команду, описать услугу, рассказать о компании.',
    'buttons' => [
        '1' => [
            'template' => 'button-one',
            'mode' => 'link',
            'link' => '#',
            'target' => '_self',
            'type' => 'primary',
            'anchor' => 'true',
            'wide' => 'true',
            'icon' => 'none',
            'text' => 'Подробнее'
        ]
    ],
    'image_tabs' => [
        'alt' => 'Блок [«Описание»] — прекрасный способ рассказать о вашем товаре или услуге',
        'images' => [
            '0' => 'img/800x400_1.png',
            '1' => 'img/800x400_2.png',
            '2' => 'img/800x400_3.png'
        ]
    ]
]}

{call add_block block="description" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import description from '@/assets/data/description.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockDescription',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: description,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>