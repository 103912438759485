<template>
  <div>
    <app-title text="Установка магазина" />

    <p class="documentation__text">
      Купите и установите тему дизайна через Инсталлер. Откройте приложение «Магазин» 🠖 «Витрина» 🠖 «Настройки». Выберите «Orion Shop» в качестве <accent text="темы оформления"/> и мобильной темы оформления.
    </p>

    <app-image url="/img/documentation/shop_installation_6.png" alt="Shop-Script установка темы оформления" />

    <app-subtitle text="Настройка темы" />

    <p class="documentation__text">
      Авторизуйтесь в бэкенде на домене, на котором установлена тема дизайна как администратор. По умолчанию авторизоваться можно по ссылке: ваш домен + <accent text="/webasyst"/>.
      Далее перейдите на ваш сайт и нажмите на иконку шестеренки в левой части экрана, чтобы открыть панель с настройками.
    </p>

    <p class="documentation__text">
      Тема содержит сотни настроек, которые помогут сделать ваш сайт уникальным. Отредактируйте их и нажмите кнопку «Сохранить», чтобы применить изменения. Или воспользуйтесь комбинацией клавиш <accent text="CTRL + S"/>.
    </p>

    <info-box text="Нашли ошибку теме или документации? Сообщите о ней на support@active-vision.ru" :link="infoLink"/>

    <p class="documentation__text">
      После того, как вы завершили конфигурацию темы отключите панель с настройками. Перейдите в бэкенд, «Сайт» 🠖 «Дизайн» 🠖 «Оформление»,
      установите настройку <accent text="Боковая панель с настройками темы"/> в состояние «Скрыта для всех». Это позволит увеличить скорость загрузки магазина.
    </p>

    <app-subtitle text="Личный кабинет" />

    <p class="documentation__text">
      Включите личный кабинет пользователя в Webasyst. Перейдите в приложение «Сайт» 🠖 «Личный кабинет» 🠖 «Настройки входа и
      регистрации» и выберите <accent text="Магазин"/> в качестве раздела сайта для авторизации и регистрации.
    </p>

    <app-image url="/img/documentation/shop_installation_7.png" alt="Личный кабинет Orion Shop-Script" />

    <app-subtitle text="Поддерживаемые плагины" />

    <p class="documentation__text">
      Установка этих плагинов не является обязательной.
    </p>

    <ul class="documentation__cards">
      <li class="documentation__card">
        <a href="https://www.webasyst.ru/store/plugin/shop/skcatimage/" class="documentation__card-image" target="_blank">
          <app-image url="/img/documentation/shop_installation_1.png" alt="Плагин «Изображения для категории»" border />
        </a>
        <a href="https://www.webasyst.ru/store/plugin/shop/skcatimage/" class="documentation__card-name" target="_blank">Плагин «Изображения для категории»</a>
        <p class="documentation__card-desc">Привязывает любые изображения к категориям</p>
      </li>
      <li class="documentation__card">
        <a href="https://www.webasyst.ru/store/plugin/shop/brand/" class="documentation__card-image" target="_blank">
          <app-image url="/img/documentation/shop_installation_2.png" alt="Плагин «Бренды PRO»" border />
        </a>
        <a href="https://www.webasyst.ru/store/plugin/shop/brand/" class="documentation__card-name" target="_blank">Плагин «Бренды PRO»</a>
        <p class="documentation__card-desc">Расширенные возможности для формирования и продвижения страниц брендов.</p>
      </li>
      <li class="documentation__card">
        <a href="https://www.webasyst.ru/store/plugin/shop/buy1click/" class="documentation__card-image" target="_blank">
          <app-image url="/img/documentation/shop_installation_3.png" alt="Плагин «Купить в 1 клик»" border />
        </a>
        <a href="https://www.webasyst.ru/store/plugin/shop/buy1click/" class="documentation__card-name" target="_blank">Плагин «Купить в 1 клик»</a>
        <p class="documentation__card-desc">Размещает кнопку «Купить в 1 клик», открывающую форму оформления заказа.</p>
      </li>
      <li class="documentation__card">
        <a href="https://www.webasyst.ru/store/plugin/shop/regions/" class="documentation__card-image" target="_blank">
          <app-image url="/img/documentation/shop_installation_4.png" alt="Плагин «SEO-регионы»" border />
        </a>
        <a href="https://www.webasyst.ru/store/plugin/shop/regions/" class="documentation__card-name" target="_blank">Плагин «SEO-регионы»</a>
        <p class="documentation__card-desc">Плагин используется для регионального продвижения на основе поддоменной системы.</p>
      </li>
      <li class="documentation__card">
        <a href="https://www.webasyst.ru/store/plugin/shop/seo/" class="documentation__card-image" target="_blank">
          <app-image url="/img/documentation/shop_installation_5.png" alt="Плагин «SEO-оптимизация»" border />
        </a>
        <a href="https://www.webasyst.ru/store/plugin/shop/seo/" class="documentation__card-name" target="_blank">Плагин «SEO-оптимизация»</a>
        <p class="documentation__card-desc">Инструмент для настройки поисковой оптимизации.</p>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: 'ShopApp',
  data() {
    return {
      infoLink: {}
    }
  }
}
</script>