<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Колонки»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_columns_1.png" alt="Блок «Колонки»" description="Блок «Колонки»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — columns"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Колонки» --&gt;

{$props = [
  'no_margin' => false,
  'color_scheme' => 'main',
  'title' => [
    'align' => 'left',
    'size' => 'medium, H2',
    'text' => 'Нужна помощь?'
  ],
  'columns' => [
    '1' => [
      'name' => 'Чат службы поддержки 24/7',
      'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь.',
      'icon_style' => 'is-linear',
      'icon_color' => 'is-primary',
      'iconset' => '1',
      'icon' => '',
      'link' => [
        'template' => 'link-two',
        'anchor' => true,
        'uppercase' => true,
        'color' => 'secondary',
        'target' => '_self',
        'link' => '#',
        'text' => 'Подробнее'
      ]
    ],
    '2' => [
      'name' => 'FAQ',
      'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь.',
      'icon_style' => 'is-linear',
      'icon_color' => 'is-primary',
      'iconset' => '2',
      'icon' => '',
      'link' => [
        'template' => 'link-two',
        'anchor' => true,
        'uppercase' => true,
        'color' => 'secondary',
        'target' => '_self',
        'link' => '#',
        'text' => 'Подробнее'
      ]
    ],
    '3' => [
      'name' => 'Блог',
      'text' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь.',
      'icon_style' => 'is-linear',
      'icon_color' => 'is-primary',
      'iconset' => '3',
      'icon' => '',
      'link' => [
        'template' => 'link-two',
        'anchor' => true,
        'uppercase' => true,
        'color' => 'secondary',
        'target' => '_self',
        'link' => '#',
        'text' => 'Подробнее'
      ]
    ]
  ]
]}

{call add_block block="columns" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopColumns from '@/assets/data/shop-columns.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopColumns',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopColumns,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>