<template>
    <div class="goback__wrapper">
        <router-link class="goback" :to="link.path">
            {{ link.name }}
            <icon-back />
        </router-link>
    </div>
</template>

<script>
import IconBack from '@/components/icons/IconBack.vue';

export default {
    name: 'AppGoBack',
    components: {
        IconBack,
    },
    props: {
        link: Object,
    },
};
</script>

<style lang="less">
.goback {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    align-items: center;
    padding: 0 0.8em 0.1em 0.8em;
    border-radius: 20px;
    text-decoration: none;
    color: lighten(@text, 30%);
    font-size: 0.9em;
    font-weight: bold;
    font-family: @title-font;
    line-height: 1.4em;
    transition: all 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &:hover {
        background-color: @secondary;
    }

    &__wrapper {
        display: flex;
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .goback {
        &__wrapper {
            margin-bottom: unset;
        }
    }
}
</style>