<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Вкладки»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_tabs_1.png" alt="Блок «Вкладки»" description="Блок «Вкладки»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — tabs"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Вкладки» --&gt;

{$props = [
  'color_scheme' => 'main',
  'no_margin' => false,
  'template' => 'widecard-one',
  'tabs' => [
    '1' => [
      'color_scheme' => 'main',
      'lazy' => false,
      'name' => 'Первая вкладка',
      'title' => [
        'align' => 'left',
        'size' => 'medium, H3',
        'text' => 'Что такое Lorem Ipsum?'
      ],
      'text' => 'Многие думают, что Lorem Ipsum - взятый с потолка латинский набор слов, но это не совсем так. Его корни уходят в один фрагмент классической латыни 45 года н.э.',
      'image' => 'img/500x300.png',
      'buttons' => [
        '1' => [
          'template' => 'button-one',
          'mode' => 'link',
          'link' => '#',
          'target' => '_self',
          'type' => 'primary',
          'wide' => true,
          'anchor' => true,
          'text' => 'Подробнее'
        ]
      ]
    ],
    '2' => [
      'color_scheme' => 'main',
      'lazy' => false,
      'name' => 'Вторая вкладка',
      'title' => [
        'align' => 'left',
        'size' => 'medium, H3',
        'text' => 'Почему используется Lorem Ipsum?'
      ],
      'text' => 'Многие думают, что Lorem Ipsum - взятый с потолка латинский набор слов, но это не совсем так. Его корни уходят в один фрагмент классической латыни 45 года н.э.',
      'image' => 'img/500x300.png',
      'buttons' => [
        '1' => [
          'template' => 'button-one',
          'mode' => 'link',
          'link' => '#',
          'target' => '_self',
          'type' => 'primary',
          'wide' => true,
          'anchor' => true,
          'text' => 'Подробнее'
        ]
      ]
    ],
    '3' => [
      'color_scheme' => 'main',
      'lazy' => false,
      'name' => 'Третья вкладка',
      'title' => [
        'align' => 'left',
        'size' => 'medium, H3',
        'text' => 'Откуда он появился Lorem Ipsum?'
      ],
      'text' => 'Многие думают, что Lorem Ipsum - взятый с потолка латинский набор слов, но это не совсем так. Его корни уходят в один фрагмент классической латыни 45 года н.э.',
      'image' => 'img/500x300.png',
      'buttons' => [
        '1' => [
          'template' => 'button-one',
          'mode' => 'link',
          'link' => '#',
          'target' => '_self',
          'type' => 'primary',
          'wide' => true,
          'anchor' => true,
          'text' => 'Подробнее'
        ]
      ]
    ],
    '4' => [
      'color_scheme' => 'main',
      'lazy' => false,
      'name' => 'Четвертая вкладка',
      'title' => [
        'align' => 'left',
        'size' => 'medium, H3',
        'text' => 'Где взять Lorem Ipsum?'
      ],
      'text' => 'Многие думают, что Lorem Ipsum - взятый с потолка латинский набор слов, но это не совсем так. Его корни уходят в один фрагмент классической латыни 45 года н.э.',
      'image' => 'img/500x300.png',
      'buttons' => [
      '1' => [
          'template' => 'button-one',
          'mode' => 'link',
          'link' => '#',
          'target' => '_self',
          'type' => 'primary',
          'wide' => true,
          'anchor' => true,
          'text' => 'Подробнее'
        ]
      ]
    ]
  ]
]}

{call add_block block="tabs" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopTabs from '@/assets/data/shop-tabs.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopTabs',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopTabs,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>