<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Каталог»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_catalog_1.png" alt="Блок «Каталог»" description="Блок «Каталог»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — catalog"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Каталог» --&gt;

{$props = [
  'mode' => 'column',
  'color_scheme' => 'semitone',
  'no_margin' => false,
  'title' => [
    'align' => 'left',
    'size' => 'medium, H2',
    'text' => ''
  ],
  'text' => '',
  'product_list' => '1,2,3',
  'product' => [
    'template' => 'one',
    'color_scheme' => 'semitone',
    'lazy' => false,
    'image_type' => 'gallery',
    'use_link' => 'true',
    'image_size' => '400',
    'image_margins' => true,
    'show_desc' => false,
    'show_rating' => true,
    'show_stocks' => true,
    'show_controls' => true,
    'hide_button' => true,
    'show_compare' => true,
    'show_favorite' => true,
    'button_on_hover' => true,
    'button' => 'В корзину',
    'button_added' => 'Оформить заказ'
  ],
  'catalog' => [
    'stretch' => false,
    'color_scheme' => 'semitone',
    'icon_style' => 'is-linear',
    'icon_color' => 'is-primary',
    'limit' => 10,
    'sub_limit' => 5,
    'columns' => 'two'
  ]
]}

{call add_block block="catalog" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopCatalog from '@/assets/data/shop-catalog.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopCatalog',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopCatalog,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>