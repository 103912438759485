<template>
  <transition name="fade">
    <div v-if="settings.show" class="modal__overlay" @click.self="closeModal">
      <div class="modal" :class="{'is-opened': settings.show}">
        <div class="modal__header">
          <span class="modal__title">{{ settings.title }}</span>
          <div class="modal__close" @click="closeModal"></div>
        </div>
        <div class="modal__content" v-html="settings.content"></div>
        <div class="modal__scroll">
          <icon-pointer />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconPointer from '@/components/icons/IconPointer.vue'

export default {
  name: 'Modal',
  components: {
    IconPointer
  },
  computed: {
    settings() {
      return this.$store.state.modal
    }
  },
  methods: {
    closeModal() {
      this.$store.state.modal.show = false;
      this.$store.state.modal.content = undefined;
    }
  }
}
</script>

<style lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 20px);
  max-width: 560px;
  border-radius: 4px;
  background-color: @background;
  box-shadow: @light-shadow;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid @secondary;
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
  }

  &__close {
    position: relative;
    display: block;
    width: 22px;
    height: 22px;
    margin-left: 10px;
    border-radius: 4px;
    background-color: lighten(@secondary, 4%);
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 3px;
      left: 10px;
      height: 16px;
      width: 2px;
      border-radius: 2px;
      transition: background-color .3s ease 0s;
      background-color: darken(@secondary, 15%);
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      &::after,
      &::before {
        background-color: darken(@secondary, 35%);
      }
    }
  }

  &__content {
    height: 470px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: @secondary;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
    }
  }

  &.is-opened {
    & .modal__scroll {
      top: 0;
    }
  }

  &__scroll {
    position: absolute;
    top: 100px;
    right: -40px;
    display: none;
    opacity: 0;
    animation-name: pointer-animation;
    animation-delay: 1s;
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
    animation-iteration-count: 2;
    animation-fill-mode: forwards;
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: hsla(360, 100%, 100%, 0.95);
    z-index: 200;
  }
}

@media (min-width: 768px) {
  .modal {
    width: 560px;
    top: calc(50% - 235px);
    left: calc(50% - 280px);
    transform: scale(.95);
    opacity: 0;
    animation-name: modal-animation;
    animation-duration: .25s;
    animation-delay: .1s;
    animation-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
    animation-fill-mode: forwards;

    &__scroll {
      display: block;
    }
  }
}

@keyframes modal-animation {
  0% {
    opacity: 0;
    transform: scale(.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pointer-animation {
  0% {
    top: 300px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 200px;
    opacity: 0;
  }
}
</style>