<template>
	<div>
		<app-title text="Установка архива с готовыми настройками темы" />

		<p class="documentation__text">
			На <router-link to="/" class="documentation__link">главной странице</router-link> скачайте архив с интересующими вас настройками темы. Убедитесь, что ваша версия темы соответствует актуальной.
		</p>

		<p class="documentation__text">
			В бэкенде Webasyst перейдите в соответствующее приложение. Если вы скачали архива для сайта, то в приложение «Сайт» соответственно, выберите вкладку «Дизайн» 🠖 «Оформление». Выберите <b>оригинальную тему</b> «Orion» (не клон), в противном случае настройки установятся не корректно. Нажмите «Импорт настроек темы».
		</p>

		<p class="documentation__text">
			Загрузите ранее скачанный архив. Архив должен быть в формате <accent text="tar.gz"/>, его <b>не нужно</b> распаковывать.
		</p>

		<app-image url="/img/documentation/update_3.png" alt="Установка архива с готовыми настройками темы" />
	</div>
</template>

<script>
    export default {
        name: 'InstallingArchive'
    }
</script>