<template>
    <div class="video-banner">
		<div class="video-banner__content">
			<h3 class="video-banner__title">{{ title }}</h3>
			<p class="video-banner__text">{{ text }}</p>
		</div>
		<router-link
			class="video-banner__button"
			:to="button.url"
		>
			<icon-youtube />
			{{ button.text }}
		</router-link>
    </div>
</template>

<script>
    import IconYoutube from '@/components/icons/IconYoutube.vue'

    export default {
        name: 'VideBanner',
        props: {
            title: String,
            text: String,
			button: Object
        },
		components: {
            IconYoutube
		}
    }
</script>

<style lang="less">
	.video-banner {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 1.5rem;
		align-items: center;
		justify-content: center;
		max-width: 600px;
		padding: 1.5rem;
		margin-bottom: 20px;
		background-color: @background;
		border: 1px solid darken(@secondary, 4%);
		border-radius: 8px;

		&__title {
			font-family: 'Exo 2', sans-serif;
			font-weight: 600;
			margin-bottom: 5px;
		}

		&__text {
			font-size: 0.85em;
			color: lighten(@text, 30%);
		}

		&__button {
			position: relative;
			display: grid;
			grid-template-columns: auto 1fr;
			grid-gap: 1rem;
			justify-content: center;
			align-items: center;
			max-width: 200px;
			box-sizing: border-box;
			padding: 0.7rem 1.2rem;
			border-radius: 6px;
			text-decoration: none;
			text-align: center;
			line-height: 1.4em;
			text-transform: uppercase;
			font-size: 0.75em;
			letter-spacing: 0.07em;
			font-weight: bold;
			cursor: pointer;
			outline: none;
			user-select: none;
			background: @contrast;
			color: @text-inverse;
			transition: all 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			will-change: background-color;

			&:hover {
				background: darken(@contrast, 10%);
			}
		}
	}

	@media (min-width: 480px) {
		.video-banner {
			grid-template-columns: 1fr max-content;
		}
	}
</style>