<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Сетка»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_grid_1.png" alt="Блок «Сетка»" description="Блок «Сетка»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — grid"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Сетка» --&gt;

{$props = [
  'template' => 'one',
  'color_scheme' => 'main',
  'no_margin' => false,
  'title' => [
    'align' => 'left',
    'size' => 'medium, H2',
    'text' => ''
  ],
  'cards' => [
    '1' => [
      'template' => 'card-two',
      'color_scheme' => 'main',
      'lazy' => false,
      'gradient' => true,
      'name' => 'Что такое Lorem Ipsum?',
      'link' => '#',
      'desc' => 'Есть много вариантов Lorem Ipsum',
      'image' => 'img/600x600.png',
      'badge' => 'Популярное',
      'width' => 700,
      'height' => 700
    ],
    '2' => [
      'template' => 'card-two',
      'color_scheme' => 'main',
      'lazy' => false,
      'gradient' => true,
      'name' => 'Почему он используется?',
      'link' => '#',
      'desc' => 'При оценке композиции читаемый текст мешает сосредоточиться.',
      'image' => 'img/600x600.png',
      'badge' => '',
      'width' => 700,
      'height' => 700
    ],
    '3' => [
      'template' => 'card-two',
      'color_scheme' => 'main',
      'lazy' => false,
      'gradient' => true,
      'name' => 'Откуда он появился?',
      'link' => '#',
      'desc' => 'Есть много вариантов Lorem Ipsum',
      'image' => 'img/600x600.png',
      'badge' => '',
      'width' => 700,
      'height' => 700
    ],
    '4' => [
      'template' => 'card-two',
      'color_scheme' => 'main',
      'lazy' => false,
      'gradient' => true,
      'name' => 'Где его взять?',
      'link' => '#',
      'desc' => 'При оценке композиции читаемый текст мешает сосредоточиться.',
      'image' => 'img/600x600.png',
      'badge' => '',
      'width' => 700,
      'height' => 700
    ],
    '5' => [
      'template' => 'card-two',
      'color_scheme' => 'main',
      'lazy' => false,
      'gradient' => true,
      'name' => 'Что такое Lorem Ipsum?',
      'link' => '#',
      'desc' => 'Есть много вариантов Lorem Ipsum',
      'image' => 'img/600x600.png',
      'badge' => '',
      'width' => 700,
      'height' => 700
    ]
  ]
]}

{call add_block block="grid" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopGrid from '@/assets/data/shop-grid.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopGrid',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopGrid,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>