<template>
    <div v-if="price > 0" class="discount">
        <div v-if="discountIsValid()" class="discount__grid">
            <icon-discount />
            <div class="discount__content">
                <span class="discount__number">-{{ discount }}% сейчас</span>
                <span class="discount__text">на тему «{{ name }}»</span>
            </div>
        </div>
        <div v-if="showPrice" class="discount__price-wrapper">
            <span
                class="discount__price"
                :class="{ 'discount__price--old': discountIsValid() }"
                >{{ price }}</span
            >
            <span v-if="discountIsValid()" class="discount__new-price">{{
                newPrice
            }}</span>
            <span class="discount__currency">₽</span>
        </div>
    </div>
</template>

<script>
import IconDiscount from '@/components/icons/IconDiscount.vue';

export default {
    name: 'Discount',
    props: {
        showPrice: Boolean,
        app: {
            type: String,
            default: `site`,
        },
        text: String,
    },
    components: {
        IconDiscount,
    },
    computed: {
        finalDate() {
            const days = this.$store.state.themePrice[this.app].duration;
            const start = new Date(this.startDate.getTime());

            return start.setDate(start.getDate() + days);
        },
        startDate() {
            return this.$store.state.themePrice[this.app].start;
        },
        name() {
            return this.$store.state.themePrice[this.app].name;
        },
        discount() {
            return this.$store.state.themePrice[this.app].discount;
        },
        price() {
            return this.$store.state.themePrice[this.app].price;
        },
        newPrice() {
            return Math.round((this.price / 100) * (100 - this.discount));
        },
    },
    methods: {
        discountIsValid() {
            let now = new Date();
            return (
                this.discount > 0 &&
                this.finalDate > now &&
                now > this.startDate
            );
        },
    },
};
</script>

<style lang="less">
.discount {
    &__grid {
        display: grid;
        grid-template-columns: max-content auto;
        grid-gap: 1rem;
        margin-right: 2rem;
    }

    &__number {
        display: block;
        font-family: @title-font;
        font-weight: 600;
        line-height: 1.4em;
    }

    &__text {
        display: block;
        font-size: 0.85em;
        line-height: 1.4em;
        color: lighten(@text, 30%);
    }

    &__price-wrapper {
        margin-bottom: 20px;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid darken(@secondary, 4%);
    }

    &__price {
        font-family: @title-font;
        font-weight: 600;
        font-size: 1.3em;

        &--old {
            margin-right: 0.3rem;
            font-size: 1em;
            font-weight: normal;
            text-decoration: line-through;
            color: lighten(@text, 30%);
        }
    }

    &__currency,
    &__new-price {
        font-family: @title-font;
        font-weight: 600;
        font-size: 1.3em;
    }
}
</style>