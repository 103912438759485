<template>
  <div>
    <app-title text="Пошаговое создание товара" />

    <p class="documentation__text">
      Примеры настроенных страниц товара из демо-версии темы:
    </p>

    <ul class="list">
      <li class="list__item">
        <a class="documentation__link" target="_blank" rel="nofollow" href="/demo/shop-two/apple-watch-ultra-gps-cellular-49-mm-korpus-iz-titana-remeshok-alpine-tsveta-siyayushchaya-zvezda/">Пример товара</a> с добавлением подстраницы.
      </li>
      <li class="list__item">
        <a class="documentation__link" target="_blank" rel="nofollow" href="/demo/shop-one/podvesnoy-svetilnik-ideal-lux-mr-jack-sp1-big-legno/">Пример страницы</a> со вставленным баннером.
      </li>
      <li class="list__item">
        <a class="documentation__link" target="_blank" rel="nofollow" href="/demo/shop-two/apple-iphone-13-pro-max-128-gb-serebristyy/">Страница</a> с добавленными услугами и рекомендациями.
      </li>
      <li class="list__item">
        <a class="documentation__link" target="_blank" rel="nofollow" href="/demo/shop-two/apple-watch-ultra-gps-cellular-49-mm-korpus-iz-titana-remeshok-ocean-belogo-tsveta/">Товар</a> с ниспадающим окном-подсказкой.
      </li>
      <li class="list__item">
        <a class="documentation__link" target="_blank" rel="nofollow" href="/demo/shop-two/iphone-14-pro/">Пример страницы</a> с настроенными параметрами товара.
      </li>
      <li class="list__item">
        <a class="documentation__link" target="_blank" rel="nofollow" href="/demo/shop-one/pled-dlya-piknika-oranzhevyy-iconic-voyager_1/">Страница товара</a> с добавлением вариантов покупки.
      </li>
      <li class="list__item">
        <a class="documentation__link" target="_blank" rel="nofollow" href="/demo/shop-one/11/">Страница</a> с добавлением своего кода или элементов.
      </li>
    </ul>

    <app-subtitle text="Создание карточки товара" number="1"/>

    <p class="documentation__text">
      В бэкенде во вкладке <accent text="Товары"/> нажмите «Новый товар».
    </p>

    <app-image url="/img/documentation/shop_product_1.png" alt="Webasyst создание товара" />

    <p class="documentation__text">
      Введите название, далее выберите тип и категорию товара.
    </p>

    <app-subtitle text="Настройка вариантов покупки" number="2"/>

    <p class="documentation__text">
      Если ваш товар имеет несколько разновидностей, например, разные цвета или размеры, настройте выбор параметров товара.
      В разделе <accent text="Настройки"/> 🠖 <accent text="Типы и характеристики товаров"/> создадим новую характеристику — <b>"Цвет корпуса"</b>.
    </p>

    <app-image url="/img/documentation/shop_product_2.png" alt="Orion Shop варианты покупки" />

    <p class="documentation__text">
      Потребуется выбрать формат <accent text="Выбор нескольких значений из списка"/> и заполнить несколько цветов. Отметим пункт "Возможность задать значения характеристики для артикулов товаров".
    </p>

    <app-image url="/img/documentation/shop_product_3.png" alt="Orion Shop цвет корпуса" />

    <p class="documentation__text">
      Создадим еще одну характеристику — <b>"Размер корпуса"</b>.
    </p>

    <app-image url="/img/documentation/shop_product_4.png" alt="Orion Shop размер корпуса" />

    <p class="documentation__text">
      Теперь в редакторе товаров во вкладке <accent text="Характеристики"/> появилась возможность отметить чекбоксы — цвета и размеры, которые есть у нас в наличии.
    </p>

    <app-image url="/img/documentation/shop_product_5.png" alt="Orion Shop характеристики товара" />

    <p class="documentation__text">
      Вернемся в раздел <accent text="Основные данные"/> и нажмем на кнопку <accent text="Выбор параметров"/>. Отметим цвета, размеры и сохраним. Изменим цены и прочие отличающиеся параметры.
    </p>

    <app-image url="/img/documentation/shop_product_6.png" alt="Orion Shop выбор параметров" />

    <p class="documentation__text">
      В результате в теме добавится следующий интерфейс:
    </p>

    <app-image url="/img/documentation/shop_product_7.png" alt="Orion Shop параметры" />

    <p class="documentation__text">
      Для примера создадим новый товар, но в этот раз вместо параметров добавим дополнительный артикул.
    </p>

    <app-image url="/img/documentation/shop_product_8.png" alt="Orion Shop новый артикул" />

    <p class="documentation__text">
      В этом случае выбор товара во фронтенде будет отличаться:
    </p>

    <app-image url="/img/documentation/shop_product_9.png" alt="Orion Shop выбор артикула" />

    <app-subtitle text="Описание товара" number="3"/>

    <p class="documentation__text">
      В редакторе товара перейдите в раздел <accent text="Описание и SEO"/>, заполните краткое описание. Поле Описание рекомендуется заполнить через визуальный редактор. Вы можете использовать заголовки, списки и изображения, чтобы разнообразить ваш текст.
    </p>

    <app-subtitle text="Изображения товара" number="4"/>

    <p class="documentation__text">
      В разделе <accent text="Фото и видео"/>, добавьте несколько изображений.
    </p>

    <p class="documentation__text">
      В случае, если вы добавляете товар с разными параметрами, на вкладке <accent text="Основные данные"/>
      вы можете отметить для каждого артикула свое изображение из загруженных ранее. Для этого нажмите кнопку «Еще» с иконкой шестеренки.
    </p>

    <app-image url="/img/documentation/shop_product_14.png" alt="Orion Shop Изображения товара" />

    <app-subtitle text="Добавление характеристик и рекомендаций" number="5"/>

    <p class="documentation__text">
      Характеристики - важная часть карточки товара. Убедитесь, что тип товара выбран правильно, заполните не менее пяти характеристик.
    </p>

    <p class="documentation__text">
      Во вкладке <accent text="Рекомендуемые товары"/> настройте Сопутствующие товары, а так же Схожие и альтернативные товары.
    </p>

    <app-subtitle text="Добавление наклейки" number="6"/>

    <p class="documentation__text">
      Вернитесь ко списку товаров. В этом режиме вы можете отметить товар, а затем добавить одну наклейку из списка.
      Если вам нужен другой текст, нажмите кнопку «Другой». Предложенный HTML код менять не нужно, достаточно изменить название и, если требуется, поменять цвет наклейки.
    </p>

    <app-image url="/img/documentation/shop_product_15.png" alt="Orion Shop Наклейка" />

    <app-subtitle text="Дополнительные возможности" number="7"/>

    <p class="documentation__text">
      Внизу раздела <accent text="Описание и SEO"/> вы увидите поле <accent text="Дополнительные параметры"/>. Это поле позволит добавить больше контента на страницу
      товара. Вставим баннер, который будет показываться только для этого товара: загрузим через FTP 3 изображения баннера
      (для ПК, планшетов и мобильных устройств). Заполните дополнительные параметры ниже, пути к изображениям необходимо указывать относительно папки с темой.
    </p>

    <app-list text="«banner» — ссылка на изображение для ПК, рекомендуемый размер 1200 x 195 пикселей;«banner_tablet» — ссылка на изображение для планшетов, рекомендуемый размер 1060 x 300 пикселей;«banner_mobile» — ссылка на изображение для мобильных, рекомендуемый размер 710 x 500 пикселей;«banner_link» — ссылка баннера;«banner_alt» — значение атрибута alt" />

    <app-image url="/img/documentation/shop_product_10.png" alt="Orion Shop Дополнительные возможности" />

    <p class="documentation__text">
      Вы можете добавить свой HTML код или готовые элементы темы в некоторые участки страницы товара. Для этого перейдите в
      приложение <accent text="Сайт"/> 🠖 <accent text="Блоки"/> и создайте новый блок, назовите его произвольно, например <b>shop.sidebar_after</b>.
      Добавьте в этот блок ваш контент. Для примера вставим в него элемент <router-link class="documentation__link" to="/documentation/shop-app/element-list">«Список»</router-link>.
      Далее добавьте созданный блок в одно из следующих мест:
    </p>

    <app-list text="«sidebar_before_hook» — id блока, который будет добавлен перед сайдбаром;«sidebar_after_hook» — id блока, который будет добавлен после сайдбара;«summary_before_hook» — id блока, который будет добавлен перед коротким описанием;«summary_after_hook» — id блока, который будет добавлен после короткого описания" />

    <app-image url="/img/documentation/shop_product_11.png" alt="Orion Shop Дополнительные возможности" />

    <p class="documentation__text">
      Рядом с кнопкой «Поделиться» в теме предусмотрено всплывающее окно-подсказка. В окне «Дополнительные параметры» задайте название и содержимое этого окна, как и в предыдущем случае, с помощью блоков. В качестве контента может быть HTML код, изображение или элемент темы.
    </p>

    <app-image url="/img/documentation/shop_product_12.png" alt="Orion Shop Дополнительные возможности" />

    <app-list text="«popup» — текст ссылки на всплывающее окно;«popup_hook» — id блока с содержимым всплывающего окна" />

    <app-image url="/img/documentation/shop_product_13.png" alt="Orion Shop Дополнительные возможности" />

  </div>
</template>

<script>
export default {
  name: 'ShopProduct',
  data() {
    return {

    }
  },
}
</script>