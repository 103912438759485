<template>
    <div class="column">
        <img
            v-if="image"
            class="column__image"
            :src="image.url"
            :alt="image.alt"
        />
        <h3 v-if="name" class="column__name">{{ name }}</h3>
        <p v-if="text" class="column__text">{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: 'AppColumn',
    props: {
        image: Object,
        name: String,
        text: String,
    },
};
</script>

<style lang="less">
.column {
    margin-bottom: 40px;

    &__image {
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }

    &__name {
        font-family: @title-font;
        font-weight: 600;
        margin-bottom: 10px;
    }
}
</style>