<template>
	<div>
		<app-title text="Пошаговое создание статьи" />

    <app-subtitle text="Создание статьи" number="1"/>

		<p class="documentation__text">
      В бэкенде приложения Блог нажмите на кнопку <accent text="Новая запись"/>. Далее впишите заголовок и наполните
      статью контентом через визуальный редактор. Опубликуйте статью.
		</p>

		<app-image url="/img/documentation/blog_posts_1.png" alt="Статья через визуальный редактор" />

    <app-subtitle text="Добавление метапараметров" number="2"/>

		<p class="documentation__text">
			Справа в сайдбаре отредактируйте метапараметры статьи. Заполните поля <accent text="Title"/>,
      <accent text="Meta Keywords"/> и <accent text="Meta Description"/>. Правильно заполненные данные будут способствовать
      продвижению вашей статьи в поисковых системах.
		</p>

		<app-image url="/img/documentation/blog_posts_2.png" alt="Метапараметры статьи" />

    <app-subtitle text="Создание категорий" number="3"/>

    <p class="documentation__text">
      Ниже в сайдбаре вы увидите раздел <accent text="Категории"/>, при условии, что вы добавили в Webasyst одноименный плагин.
      В настройках плагина необходимо предварительно создать список категорий.
    </p>

    <app-image url="/img/documentation/blog_posts_4.png" alt="Категории блога Webasyst" />

    <p class="documentation__text">
      Далее в сайдбаре статьи, нажав на чекбокс, нужно отметить к какой категории относится пост.
    </p>

    <app-image url="/img/documentation/blog_posts_5.png" alt="Категории блога Webasyst" />

    <app-subtitle text="Загрузка превью-изображения" number="4"/>

		<p class="documentation__text">
			Если у вас установлен соответствующий плагин, загрузите превью-изображение во вкладке «Главная картинка». Рекомендуемый размер <accent text="850 х 450"/> пикселей. Сохраните изменения, далее необходимо обрезать изображение так, чтобы получился квадрат. В таком виде картинка будет отображаться в карточках на главной странице.
		</p>

		<app-image url="/img/documentation/blog_posts_3.png" alt="Webasyst главная картинка" />

	</div>
</template>

<script>
    export default {
        name: 'BlogPosts',
        data() {
            return {

            }
        },
    }
</script>