<template>
	<div>
		<app-title text="Блоки для Orion Blog" />

		<p class="documentation__text">
			В бэкенде Webasyst (приложение «Блог») создайте новую страницу и перейдите в режим редактирования HTML. Как и в случае с приложением «Сайт», добавьте Smarty код <b>один раз</b> в начале страницы.
		</p>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Подключает утилиты --&gt;
{include "{$wa->blog->themePath(waRequest::getTheme())}utils.html"}

&lt;!-- Убирает стандартные стили --&gt;
{$wa->globals("userCode", true)}

&lt;!-- Настройки шрифта и кнопок --&gt;
{$_primary_font = 'philosopher'}
{$_button_type = 'button-one'}</ssh-pre>
		</div>

		<p class="documentation__text">
			Страница подготовлена, далее вы можете добавлять блоки из набора в любом количестве:
		</p>

		<navigation-box
			icon="pen"
			title="Набор блоков"
			description="Для темы дизайна «Orion Blog»"
			:links="navigationLinks(`blog`)"
		/>

	</div>
</template>

<script>
    import NavigationBox from '@/components/NavigationBox.vue'
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'

    export default {
        name: 'BlogAddingBlocks',
        components: {
            NavigationBox,
            SshPre,
            IconCopy
        },
        data() {
            return {

            }
        },
        computed: {
            navigation() {
                const navigation = this.$router.options.routes.find(element => element.name === `Documentation`);
                return navigation.children.filter(function (link) {
                    return link.meta.type === `block`;
                });
            }
        },
        methods: {
            navigationLinks(category) {
                const navigation =  this.navigation.filter(function(link) {
                    return link.meta.category === category;
                });
                return navigation.map(function (link) {
                    return {url: link.path, text: link.meta.name};
                });
            }
        }
    }
</script>