<template>
	<div>
        <div class="documentation__header">
            <app-title text="Блок «Шесть карточек»"></app-title>
            <app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
        </div>

		<app-image url="/img/documentation/block_sixcards_1.png" alt="Блок «Шесть карточек»" description="Блок «Шесть карточек»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — six-cards"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Шесть карточек» --&gt;

{$props = [
    'color_scheme' => 'alter',
    'no_margin' => false,
    'background' => '',
    'animation' => true,
    'title' => [
        'align' => 'center',
        'size' => 'medium, H2',
        'text' => 'Попробуйте разные формы преподнесения контента'
    ],
    'statistics' => [
        '1' => [
            'number' => '45',
            'text' => 'Готовых блоков'
        ],
        '2' => [
            'number' => '6',
            'text' => 'Цветовых схем'
        ],
        '3' => [
            'number' => '8',
            'text' => 'Встроенных шрифтов'
        ]
    ],
    'cards' => [
        '1' => [
            'template' => 'card-one',
            'color_scheme' => 'alter',
            'lazy' => false,
            'name' => 'Текстовые блоки',
            'link' => '#',
            'badge' => 'Основной контент',
            'desc' => 'Уникальный релевантный текст позволит вам занять хорошие позиции в поисковой выдаче.',
            'image' => 'img/360x260.png'
        ],
        '2' => [
            'template' => 'card-one',
            'color_scheme' => 'alter',
            'lazy' => false,
            'name' => 'Карточки',
            'link' => '#',
            'badge' => 'Навигация',
            'desc' => 'Небольшой блок информации, который работает как точка входа в подробное изучение вопроса.',
            'image' => 'img/360x260.png'
        ],
        '3' => [
            'template' => 'card-one',
            'color_scheme' => 'alter',
            'lazy' => false,
            'name' => 'Списки',
            'link' => '#',
            'badge' => 'Структура',
            'desc' => 'Стуктурируйте ваш контент в удобный для посетителя сайта вид.',
            'image' => 'img/360x260.png'
        ],
        '4' => [
            'template' => 'card-one',
            'color_scheme' => 'alter',
            'lazy' => false,
            'name' => 'Full-size блоки',
            'link' => '#',
            'badge' => 'Презентация',
            'desc' => 'Графическая часть — ключ к тому, как пользователь будет воспринимать веб-ресурс.',
            'image' => 'img/360x260.png'
        ],
        '5' => [
            'template' => 'card-one',
            'color_scheme' => 'alter',
            'lazy' => false,
            'name' => 'Призывы к действию',
            'link' => '#',
            'badge' => 'Выполнение целей',
            'desc' => 'Call to action, призыв, мотивирующий пользователя к совершению того или иного действия.',
            'image' => 'img/360x260.png'
        ],
        '6' => [
            'template' => 'card-one',
            'color_scheme' => 'alter',
            'lazy' => false,
            'name' => 'Переключатели',
            'link' => '#',
            'badge' => 'Интерактив',
            'desc' => 'Call to action, призыв, мотивирующий пользователя к совершению того или иного действия.',
            'image' => 'img/360x260.png'
        ]
    ]
]}

{call add_block block="six-cards" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import sixcards from '@/assets/data/six-cards.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockSixcards',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: sixcards,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>