<template>
    <div class="home">
        <div class="home__demos">
            <div class="home__badge">
                <app-badge text="Тема дизайна" />
            </div>
            <div class="home__header">
                <app-title text="Демоверсии «Orion»:" align="left" oversize />
                <div class="home__cms">
                    <icon-webasyst />
                </div>
            </div>
            <p class="home__text">
                Тема «Orion» — это гибко настраиваемый конструктор
                многостраничных ресурсов и промо-сайтов, а так же блог и
                магазин. Простота настроек и широкие возможности позволят вам
                создать современный и качественный сайт за короткий срок.
            </p>
            <div class="home__columns">
                <demo-card
                    v-for="(card, key) of sortCards(1)"
                    :key="key"
                    :card="card"
                />
            </div>

            <div class="home__columns">
                <div class="home__column">
                    <app-title text="Настройки темы в графическом интерфейсе" />
                    <p class="home__text">
                        Создайте уникальный сайт с помощью сотен настроек.
                        Конфигурация темы выполняется прямо во фронтенде в
                        специальной админ-панели. Узнайте как начать работу с
                        темой «Orion».
                    </p>
                    <app-button
                        type="primary"
                        text="Начало работы"
                        url="/documentation"
                        router
                    ></app-button>
                </div>
                <demo-card
                    v-for="(card, key) of sortCards(2)"
                    :key="key"
                    :card="card"
                />
            </div>

            <app-title text="Архивы с готовыми настройками" />
            <p class="home__text">
                Хотите сразу получить готовый сайт, как в наших демо-версиях?
                Скачайте архив с настройками темы и
                <router-link
                    class="home__link"
                    to="/documentation/installing-archive"
                    active-class="is-active"
                    >установите его
                </router-link>
                через бэкенд Webasyst.
            </p>
            <ul class="home__list">
                <download-card
                    v-for="(card, key) of downloadCards"
                    :key="key"
                    :card="card"
                />
            </ul>

            <app-title text="Состав темы «Orion»" />
            <p class="home__text">
                Цена указана без учета распродаж и скидок, уточняйте цену на
                странице темы в официальном магазине Webasyst.
            </p>
            <ul class="home__structure">
                <li class="home__theme home__theme--site">
                    <app-element
                        icon="/img/site_icon.png"
                        name="Тема для приложения «Сайт»"
                        :desc="`Актуальная версия ${getThemeVersion('site')}`"
                        app="site"
                        :button="{
                            style: 'secondary',
                            text: 'Купить тему',
                            url: 'https://www.webasyst.ru/buy/store/5563/',
                        }"
                    ></app-element>

                    <app-element
                        icon="/img/photos_icon.png"
                        name="Тема для приложения «Фото»"
                        :desc="`Актуальная версия ${getThemeVersion('photos')}`"
                        app="photos"
                    ></app-element>
                </li>
                <li class="home__theme home__theme--shop">
                    <app-element
                        icon="/img/shop_icon.png"
                        name="Тема для приложения «Магазин»"
                        :desc="`Актуальная версия ${getThemeVersion('shop')}`"
                        app="shop"
                        :button="{
                            style: 'secondary',
                            text: 'Купить тему',
                            url: 'https://www.webasyst.ru/buy/store/6705/',
                        }"
                    ></app-element>
                </li>
                <li class="home__theme home__theme--blog">
                    <app-element
                        icon="/img/blog_icon.png"
                        name="Тема для приложения «Блог»"
                        :desc="`Актуальная версия ${getThemeVersion('blog')}`"
                        app="blog"
                        :button="{
                            style: 'secondary',
                            text: 'Купить тему',
                            url: 'https://www.webasyst.ru/buy/store/6218/',
                        }"
                    ></app-element>
                </li>
            </ul>

            <p class="home__text">
                Тема для приложения «Фото» является дочерней, ее можно
                установить после покупки темы «Orion» для приложения «Сайт».
            </p>
        </div>
    </div>
</template>

<script>
import DemoCard from '@/components/DemoCard.vue';
import DownloadCard from '@/components/DownloadCard.vue';
import AppElement from '../components/AppElement';
import IconWebasyst from '@/components/icons/IconWebasyst.vue';

export default {
    name: 'Home',
    components: {
        DemoCard,
        DownloadCard,
        AppElement,
        IconWebasyst,
    },
    data() {
        return {
            cards: [
                {
                    id: 0,
                    url: 'https://orion.active-vision.ru/demo/demo-one/',
                    image: '/img/demo_1.png',
                    badge: 'Сайт',
                    title: 'Пример многостраничного сайта',
                    desc: 'Тема «Orion»',
                    button: 'Смотреть демо',
                },
                {
                    id: 1,
                    url: 'https://orion.active-vision.ru/demo/demo-two/',
                    image: '/img/demo_2.png',
                    badge: 'Лендинг',
                    title: 'Пример лендинга (промо-сайта)',
                    desc: 'Тема «Orion»',
                    button: 'Смотреть демо',
                },
                {
                    id: 2,
                    url: 'https://orion.active-vision.ru/demo/blog/',
                    image: '/img/demo_3.png',
                    badge: 'Блог',
                    title: 'Пример блога',
                    desc: 'Тема «Orion Blog»',
                    button: 'Смотреть демо',
                },
                {
                    id: 3,
                    url: 'https://orion.active-vision.ru/demo/shop-one/',
                    image: '/img/demo_4.png',
                    badge: 'Магазин',
                    title: 'Пример магазина (много товаров)',
                    desc: 'Тема «Orion Shop»',
                    button: 'Смотреть демо',
                },
                {
                    id: 4,
                    url: 'https://orion.active-vision.ru/demo/shop-two/',
                    image: '/img/demo_5.png',
                    badge: 'Магазин',
                    title: 'Пример магазина (мало товаров)',
                    desc: 'Тема «Orion Shop»',
                    button: 'Смотреть демо',
                },
            ],
            downloadCards: [
                {
                    url: 'https://orion.active-vision.ru/promo/demo-one/webasyst.site.theme.orion.settings.tar.gz',
                    type: 'gzip',
                    name: `Готовые настройки «Отель» v${this.getThemeVersion(
                        'site'
                    )}`,
                    desc: 'Для «Orion Сайт» — 3,35 МБ',
                    preview: '/img/preview_1.webp',
                },
                {
                    url: 'https://orion.active-vision.ru/promo/demo-two/webasyst.site.theme.orion.settings.tar.gz',
                    type: 'gzip',
                    name: `Готовые настройки «Дизайн интерьера» v${this.getThemeVersion(
                        'site'
                    )}`,
                    desc: 'Для «Orion Сайт» — 3,51 МБ',
                    preview: '/img/preview_2.webp',
                },
                {
                    url: 'https://orion.active-vision.ru/promo/demo-three/webasyst.site.theme.orion.settings.tar.gz',
                    type: 'gzip',
                    name: `Готовые настройки «Салон красоты» v${this.getThemeVersion(
                        'site'
                    )}`,
                    desc: 'Для «Orion Сайт» — 0,59 МБ',
                    preview: '/img/preview_3.webp',
                },
                {
                    url: 'https://orion.active-vision.ru/promo/demo-four/webasyst.site.theme.orion.settings.tar.gz',
                    type: 'gzip',
                    name: `Готовые настройки «Центр Йоги» v${this.getThemeVersion(
                        'site'
                    )}`,
                    desc: 'Для «Orion Сайт» — 1,34 МБ',
                    preview: '/img/preview_4.webp',
                },
            ],
        };
    },
    computed: {
        app() {
            const urlParams = new URLSearchParams(window.location.search),
                app = urlParams.get('app');
            return app ? app : `site`;
        },
    },
    methods: {
        getThemeVersion(app) {
            return this.$store.state.themeVersion.get(app);
        },
        sortCards(column) {
            const sort = order => {
                const filtered = this.cards.filter((card, key) =>
                    order.includes(key)
                );
                return filtered.sort(
                    (a, b) => order.indexOf(a.id) - order.indexOf(b.id)
                );
            };

            switch (this.app) {
                case `shop`:
                    if (column === 1) return sort([3, 4, 2]);
                    if (column === 2) return sort([0, 1]);
                    break;
                case `blog`:
                    if (column === 1) return sort([2, 0, 1]);
                    if (column === 2) return sort([3, 4]);
                    break;
                default:
                    if (column === 1) return sort([0, 1, 2]);
                    if (column === 2) return sort([3, 4]);
            }
        },
    },
    beforeMount() {
        this.$store.state.useContainer = true;
    },
};
</script>

<style lang="less">
.home {
    &__demos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
    }

    &__columns {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        margin-bottom: 3rem;
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        margin-bottom: 4rem;
    }

    &__header {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 20px;
        align-items: start;
    }

    &__text {
        max-width: 700px;
        margin-bottom: 40px;
    }

    &__badge {
        margin-bottom: 10px;
    }

    &__link {
        color: @primary;
        transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:hover {
            color: darken(@primary, 15%);
        }
    }

    &__structure {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 'site' 'shop' 'blog';
        grid-gap: 1.5rem;
        margin-bottom: 40px;
    }

    &__theme {
        grid-area: theme;

        &--site {
            grid-area: site;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 1.5rem;
            padding: 1.5rem;
            border-radius: 8px;
            border: 1px solid darken(@secondary, 4%);
            background-color: @background;
        }

        &--blog {
            grid-area: blog;
        }

        &--shop {
            grid-area: shop;
        }
    }
}

@media (min-width: 480px) {
    .home {
        &__theme {
            padding: 1.5rem 0;

            &--site {
                grid-template-columns: 1fr 1fr;
                padding: 1.5rem;
            }
        }
    }
}

@media (min-width: 768px) {
    .home {
        &__columns {
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
        }

        &__structure {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 'site site' 'shop blog';
        }

        &__list {
            grid-template-columns: 1fr 1fr;
        }

        &__header {
            grid-template-columns: 1fr max-content;
            grid-gap: 2rem;
            margin-bottom: unset;
        }
    }
}

@media (min-width: 992px) {
    .home {
        &__columns {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media (min-width: 1100px) {
    .home {
        &__structure {
            grid-template-columns: auto 1fr 1fr;
            grid-template-areas: 'site shop blog';
        }
    }
}
</style>
