<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Преимущества»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_advantages_1.png" alt="Блок «Преимущества»" description="Блок «Преимущества»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — advantages"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Преимущества» --&gt;

{$props = [
    'color_scheme' => 'main',
    'no_margin' => false,
    'animation' => true,
    'icon_style' => 'is-linear',
    'title' => [
        'align' => 'center',
        'size' => 'medium, H2',
        'text' => 'Какие блоки используют в лендинг пейдж?'
    ],
    'items' => [
        '1' => [
            'iconset' => '1',
            'icon' => '',
            'icon_size' => 'small',
            'name' => 'Преимущество',
            'text' => 'Основные преимущества описываются короткими текстами (одно-два предложения)',
            'link' => '#'
        ],
        '2' => [
            'iconset' => '2',
            'icon' => '',
            'icon_size' => 'small',
            'name' => 'Заголовок с УТП',
            'text' => 'Размещается заголовок в верхней части лендинга, на уровне глаз пользователя. Большой, яркий, интригующий',
            'link' => '#'
        ],
        '3' => [
            'iconset' => '3',
            'icon' => '',
            'icon_size' => 'small',
            'name' => 'Формы захвата',
            'text' => 'Одноэкранный лендинг содержит одну форму захвата, длинный – 2 или 3 формы.',
            'link' => '#'
        ],
        '4' => [
            'iconset' => '4',
            'icon' => '',
            'icon_size' => 'small',
            'name' => 'Отзывы клиентов',
            'text' => 'На лендинге должны быть реальные отзывы по меньшей мере от трех клиентов.',
            'link' => '#'
        ],
        '5' => [
            'iconset' => '5',
            'icon' => '',
            'icon_size' => 'small',
            'name' => 'Характеристики',
            'text' => 'Здесь должны размещены ключевые характеристики о товаре или услуге',
            'link' => '#'
        ],
        '6' => [
            'iconset' => '6',
            'icon' => '',
            'icon_size' => 'small',
            'name' => 'Блок-гарантии',
            'text' => 'Строится на основании возражений целевой аудитории и закрывает их',
            'link' => '#'
        ]
    ]
]}

{call add_block block="advantages" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import advantages from '@/assets/data/advantages.json'
    import AppGoBack from "../../components/AppGoBack";

    export default {
        name: 'BlockAdvantages',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: advantages,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>