<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Особенности»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_features_1.png" alt="Блок «Особенности»" description="Блок «Особенности»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — features"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Особенности» --&gt;

{$props = [
    'id' => '',
    'color_scheme' => 'main',
    'no_margin' => false,
    'animation' => true,
    'icon_style' => 'is-linear',
    'title' => [
        'align' => 'center',
        'size' => 'medium, H2',
        'text' => '4 шага для установки темы «Orion»'
    ],
    'buttons' => [
        '1' => [
            'template' => 'button-one',
            'mode' => 'feedback',
            'link' => '#',
            'target' => '_self',
            'type' => 'primary',
            'anchor' => true,
            'wide' => true,
            'icon' => 'phone',
            'text' => 'Заказать консультацию'
        ]
    ],
    'text' => 'Установить и настроить тему дизайна на сегодняшний день очень легко, достаточно внимательно изучить документацию или обратиться к специалисту.',
    'icons' => [
        '1' => [
            'iconset' => '1',
            'image' => '',
            'text' => 'Приобретение домна и хостинга'
        ],
        '2' => [
            'iconset' => '2',
            'image' => '',
            'text' => 'Установка CMS Webasyst'
        ],
        '3' => [
            'iconset' => '3',
            'image' => '',
            'text' => 'Установка и настройка "Orion"'
        ],
        '4' => [
            'iconset' => '4',
            'image' => '',
            'text' => 'Наполнение сайта контентом'
        ]
    ]
]}

{call add_block block="features" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import features from '@/assets/data/features.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockFeatures',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: features,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>