<template>
	<div>
        <div class="documentation__header">
            <app-title text="Блок «Проекты»"></app-title>
            <app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
        </div>

		<app-image url="/img/documentation/block_projects_1.png" alt="Блок «Проекты»" description="Блок «Проекты»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — projects"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Проекты» --&gt;

{$props = [
    'id' => '',
    'color_scheme' => 'main',
    'no_margin' => false,
    'animation' => fasle,
    'badge' => 'Проекты',
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Тема дизайна для приложения «Сайт»'
    ],
    'projects' => [
        '1' => [
            'show' => true,
            'lazy' => fasle,
            'label' => '2021',
            'text' => '&lt;p&gt;Orion — это шаблон Webasyst, фронтенд для системного приложения «Сайт», которое предоставляет возможности для построения веб-проектов.&lt;/p&gt;&lt;p&gt;Оно содержит необходимые инструменты для создания и работы сайта, управления роутингом (маршрутизацией), встроенный редактор шаблонов темы, интерфейс создания страниц, файловый менеджер и прочее.&lt;/p&gt;',
            'image' => 'img/700x450.png',
            'title' => [
                'align' => 'left',
                'size' => 'small',
                'text' => 'Первый шаг к собственному сайту'
            ],
            'link' => [
                'template' => 'link-two',
                'anchor' => true,
                'uppercase' => true,
                'color' => 'secondary',
                'target' => '_self',
                'link' => '#',
                'text' => 'Подробнее'
            ]
        ],
        '2' => [
            'show' => false,
            'lazy' => false,
            'label' => '2020',
            'text' => '&lt;p&gt;Orion — это шаблон Webasyst, фронтенд для системного приложения «Сайт», которое предоставляет возможности для построения веб-проектов.&lt;/p&gt;&lt;p&gt;Оно содержит необходимые инструменты для создания и работы сайта, управления роутингом (маршрутизацией), встроенный редактор шаблонов темы, интерфейс создания страниц, файловый менеджер и прочее.&lt;/p&gt;',
            'image' => 'img/700x450.png',
            'title' => [
                'align' => 'right',
                'size' => 'small',
                'text' => 'Первый шаг к собственному сайту'
            ],
            'link' => [
                'template' => 'link-two',
                'anchor' => true,
                'uppercase' => true,
                'color' => 'secondary',
                'target' => '_self',
                'link' => '#',
                'text' => 'Подробнее'
            ]
        ],
        '3' => [
            'show' => false,
            'lazy' => false,
            'label' => '2019',
            'text' => '&lt;p&gt;Orion — это шаблон Webasyst, фронтенд для системного приложения «Сайт», которое предоставляет возможности для построения веб-проектов.&lt;/p&gt;&lt;p&gt;Оно содержит необходимые инструменты для создания и работы сайта, управления роутингом (маршрутизацией), встроенный редактор шаблонов темы, интерфейс создания страниц, файловый менеджер и прочее.&lt;/p&gt;',
            'image' => 'img/700x450.png',
            'title' => [
                'align' => 'left',
                'size' => 'small',
                'text' => 'Первый шаг к собственному сайту'
            ],
            'link' => [
                'template' => 'link-two',
                'anchor' => true,
                'uppercase' => true,
                'color' => 'secondary',
                'target' => '_self',
                'link' => '#',
                'text' => 'Подробнее'
            ]
        ]
    ]
]}

{call add_block block="projects" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import projects from '@/assets/data/projects.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockProjects',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: projects,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>