<template>
    <li class="download-card" :class="{ 'download-card--shadow': shadow }">
        <keep-alive>
            <component v-bind:is="iconComponent"></component>
        </keep-alive>
        <span class="download-card__content">
            <span v-if="card.name" class="download-card__name">{{
                card.name
            }}</span>
            <span v-if="card.desc" class="download-card__desc">{{
                card.desc
            }}</span>
            <span
                v-if="card.preview"
                class="download-card__preview"
                @click="openPreview"
            >
                Предпросмотр
            </span>
        </span>
        <download-button
            :url="card.url"
            text="Скачать"
            type="primary"
        ></download-button>
    </li>
</template>

<script>
import IconGzip from '@/components/icons/IconGzip.vue';
import IconPdf from '@/components/icons/IconPdf.vue';
import DownloadButton from '@/components/DownloadButton.vue';

export default {
    name: 'DownloadCard',
    props: {
        card: Object,
    },
    data() {
        return {
            shadow: false,
        };
    },
    components: {
        IconGzip,
        IconPdf,
        DownloadButton,
    },
    computed: {
        iconComponent: function () {
            return 'icon-' + this.card.type.toLowerCase();
        },
    },
    methods: {
        openPreview() {
            this.$store.state.modal.show = true;
            this.$store.state.modal.title = this.card.name;
            this.$store.state.modal.content = `
          <img src="${this.card.preview}" alt="" class="modal__image">
        `;
        },
    },
};
</script>

<style lang="less">
.download-card {
    display: grid;
    grid-template-columns: 1fr 40px;
    grid-gap: 1rem;
    align-items: center;
    padding: 1.5rem 1rem;
    border-radius: 8px;
    border: 1px solid darken(@secondary, 4%);
    background-color: @background;
    line-height: 1.6em;
    transition: all 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &--shadow {
        box-shadow: @medium-shadow-hover;
    }

    & > svg {
        display: none;
    }

    &__name {
        display: block;
        font-family: @title-font;
        font-weight: 600;
    }

    &__desc {
        display: block;
        font-size: 0.85em;
        color: lighten(@text, 30%);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__preview {
        display: flex;
        align-items: center;
        padding: 0 10px;
        margin-top: 5px;
        border-radius: 4px;
        background-color: lighten(@secondary, 4%);
        font-size: 0.85em;
        transition: all 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        user-select: none;
        cursor: pointer;

        &:hover {
            background-color: @secondary;
        }
    }

    &__preloader {
        padding: 1rem;
    }
}

@media (min-width: 480px) {
    .download-card {
        grid-template-columns: auto 1fr 40px;

        & > svg {
            display: block;
        }
    }
}
</style>