<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Страница»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — page"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Страница» --&gt;

{$props = [
  'frame' => true,
  'color_scheme' => 'main',
  'no_margin' => false,
  'reduced' => false,
  'share' => [
    'show' => true,
    'share' => 'vkontakte, telegram, whatsapp',
    'share_lang' => 'ru'
  ]
]}

{$props.content = "
  &lt;h1&gt;Что такое Lorem Ipsum?&lt;/h1&gt;
  &lt;p&gt;
    Lorem Ipsum - это текст-рыба, часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной рыбой для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн.
  &lt;/p&gt;
  &lt;img src=&#039;{$wa_theme_url}img/900x330.png&#039; alt=&#039;Lorem Ipsum&#039;&gt;
  &lt;ul&gt;
    &lt;li&gt;Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться.&lt;/li&gt;
    &lt;li&gt;Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение шаблона&lt;/li&gt;
  &lt;/ul&gt;
"}

{call add_block block="page" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import page from '@/assets/data/page.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockPage',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: page,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>