<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Вкладки»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_tabs_1.png" alt="Блок «Вкладки»" description="Блок «Вкладки»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — tabs"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Вкладки» --&gt;

{$props = [
    'numbering' => true,
    'color_scheme' => 'main',
    'no_margin' => false,
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Шесть этапов создания хорошего сайта'
    ],
    'tabs' => [
        '1' => [
            'lazy' => false,
            'item' => 'Исследование рынка',
            'image' => 'img/500x600_1.png',
            'name' => 'Исследование рынка и анализ конкурентов',
            'text' => '&lt;p&gt;Анализ рынка начинается со сбора данных. Не зная своих конкурентов, невозможно понять где их сильные стороны и на чем сосредоточить свои усилия.&lt;/p&gt;&lt;p&gt;Основная часть потребителей пользуются интернетом для поиска информации об услуге или товаре при принятии решения о покупке. Поэтому крайне важно проанализировать преимущества, как свои, так и других участников рынка.&lt;/p&gt;'
        ],
        '2' => [
            'lazy' => false,
            'item' => 'Определение задач сайта',
            'image' => 'img/500x600_2.png',
            'name' => 'Определение целей и задач сайта',
            'text' => '&lt;p&gt;Перед созданием сайта нужно понять для каких целей он разрабатывается. Возможно, вам необходимо облегчить доступ пользователей к полезной информации, сократить количество лишних обращений в поддержку, заинтересовать клиентов и увеличить трафик, рассказать о своих товарах или услугах, поддержать имидж компании и т.д.&lt;/p&gt;&lt;p&gt;От ваших задач будет зависеть формат и структура веб-ресурса.&lt;/p&gt;'
        ],
        '3' => [
            'lazy' => false,
            'item' => 'Проектирование структуры',
            'image' => 'img/500x600_3.png',
            'name' => 'Проектирование структуры',
            'text' => '&lt;p&gt;Как правило, у пользователей уже сформирован какой-то опыт взаимодействия с веб-ресурсами. Популярность вашего сайта будет зависеть от того, насколько ваш контент и его расположение совпадут с их опытом.&lt;/p&gt;&lt;p&gt;Говоря о структуре сайта, мы в первую очередь имеем в виду расположение функциональных блоков, страниц и разделов сайта. При проектировании структуры предусмотрите простой доступ ко всем страницам, единообразие элементов сайта, не создавайте похожие страницы и не дублируйте разделы.&lt;/p&gt;'
        ],
        '4' => [
            'lazy' => false,
            'item' => 'Маркетинг',
            'image' => 'img/500x600_4.png',
            'name' => 'Маректинг сайта',
            'text' => '&lt;p&gt;Для того, чтобы добиться результата от привлеченных клиентов, понадобится грамотный маркетинг на сайте. Интернет маркетинг — это перечень действий, направленный на продвижение товаров или услуг в сети.&lt;/p&gt;&lt;p&gt;Точно определите вашу целевую аудиторию, составьте портрет своего покупателя. Изучите конкурентов, составьте четкую стратегию на определенный промежуток времени.&lt;/p&gt;'
        ],
        '5' => [
            'lazy' => false,
            'item' => 'Контент и копирайт',
            'image' => 'img/500x600_5.png',
            'name' => 'Контент и копирайт',
            'text' => '&lt;p&gt;Сайт создается ради контента и, конечно, этому этапу нужно уделить максимальное количество внимания. Контент — ваш основной инструмент в борьбе за клиента.&lt;/p&gt;&lt;p&gt;Написать текст самостоятельно — это лучший вариант, ведь кто еще, кроме вас лучше всего знает все тонкости вашей работы. Но не все имеют талант писателя, вы всегда можете заказать копирайт.&lt;/p&gt;'
        ],
        '6' => [
            'lazy' => false,
            'item' => 'Графические материалы',
            'image' => 'img/500x600_6.png',
            'name' => 'Фото, изображения и иллюстрации',
            'text' => '&lt;p&gt;Изображения способны моментально дать понять пользователю о чем и для кого сайт, на который он попал.&lt;/p&gt;&lt;p&gt;Идеальный вариант — нанять фотографа и сделать настоящие фото ваших товаров и услуг. Но время и деньги для этого есть не всегда. Альтернативным вариантом будет поиск изображений на фотостоках, это не так дорого и относительно быстро.&lt;/p&gt;'
        ]
    ]
]}

{call add_block block="tabs" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import tabs from '@/assets/data/tabs.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockTabs',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: tabs,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>