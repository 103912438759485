<template>
	<div>
		<app-title text="Набор элементов" />

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову элементов на дополнительных страницах" :link="infoLink"/>

		<navigation-box
			icon="element"
			title="Элементы"
			description="Темы дизайна «Orion»"
			:links="navigationLinks(`elements`)"
		/>

	</div>
</template>

<script>
    import NavigationBox from '@/components/NavigationBox.vue';

    export default {
        name: 'ListOfElements',
        components: {
            NavigationBox
        },
        data() {
            return {
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        },
        computed: {
            navigation() {
                const navigation = this.$router.options.routes.find(element => element.name === `Documentation`);
                return navigation.children.filter(function (link) {
                    return link.meta.type === `element`;
                });
            }
        },
        methods: {
            navigationLinks(category) {
                const navigation =  this.navigation.filter(function(link) {
                    return link.meta.category === category;
                });
                return navigation.map(function (link) {
                    return {url: link.path, text: link.meta.name};
                });
            }
        }
    }
</script>