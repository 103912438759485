<template>
    <div class="image-block" :class="{ 'image-block--reverse': reverse }">
        <img
            v-if="image"
            class="image-block__image"
            :src="image.url"
            :alt="image.alt"
        />
        <div class="image-block__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppImageBlock',
    props: {
        image: Object,
        reverse: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="less">
.image-block {
    display: grid;
    grid-template-areas: 'image' 'content';
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 2em;
    margin-bottom: 20px;

    &__image {
        grid-area: image;
        max-width: 100%;
        height: auto;
    }

    &__content {
        grid-area: content;
    }
}

@media (min-width: 786px) {
    .image-block {
        grid-template-areas: 'image content';
        grid-template-columns: 270px 1fr;

        &--reverse {
            grid-template-areas: 'content image';
            grid-template-columns: 1fr 270px;
        }
    }
}
</style>