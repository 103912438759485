<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Вопрос-ответ»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_faq_1.png" alt="Блок «Вопрос-ответ»" description="Блок «Вопрос-ответ»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — faq"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Вопрос-ответ» --&gt;

{$props = [
    'align' => 'left',
    'color_scheme' => 'main',
    'no_margin' => false,
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Остались вопросы?'
    ],
    'faqs' => [
        '0' => [
            'template' => 'faq-one',
            'color_scheme' => 'main',
            'question' => 'Что такое Lorem Ipsum и как его использовать?',
            'answer' => 'Lorem Ipsum - это текст-рыба, часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной рыбой для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.'
        ],
        '1' => [
            'template' => 'faq-one',
            'color_scheme' => 'main',
            'question' => 'Откуда он появился?',
            'answer' => 'Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов, но это не совсем так. Его корни уходят в один фрагмент классической латыни 45 года н.э., то есть более двух тысячелетий назад.'
        ],
        '2' => [
            'template' => 'faq-one',
            'color_scheme' => 'main',
            'question' => 'Почему используется Lorem Ipsum?',
            'answer' => 'Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение шаблона, а также реальное распределение букв и пробелов в абзацах, которое не получается при простой дубликации '
        ],
        '3' => [
            'template' => 'faq-one',
            'color_scheme' => 'main',
            'question' => '',
            'answer' => ''
        ],
        '4' => [
            'template' => 'faq-one',
            'color_scheme' => 'main',
            'question' => '',
            'answer' => ''
        ],
        '5' => [
            'template' => 'faq-one',
            'color_scheme' => 'main',
            'question' => '',
            'answer' => ''
        ],
        '6' => [
            'template' => 'faq-one',
            'color_scheme' => 'main',
            'question' => '',
            'answer' => ''
        ],
        '7' => [
            'template' => 'faq-one',
            'color_scheme' => 'main',
            'question' => '',
            'answer' => ''
        ],
        '8' => [
            'template' => 'faq-one',
            'color_scheme' => 'main',
            'question' => '',
            'answer' => ''
        ],
        '9' => [
            'template' => 'faq-one',
            'color_scheme' => 'main',
            'question' => '',
            'answer' => ''
        ]
    ]
]}

{call add_block block="faq" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import faq from '@/assets/data/faq.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockFaq',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: faq,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>