<template>
    <span class="tooltip" :class="tooltipPosition">{{ text }}</span>
</template>

<script>
export default {
    name: `AppTooltip`,
    props: {
        text: String,
        position: {
            type: String,
            default: `right`,
        },
    },
    computed: {
        tooltipPosition() {
            return `tooltip--${this.position}`;
        },
    },
};
</script>

<style lang="less">
.tooltip {
    position: absolute;
    display: none;
    padding: 6px;
    border-radius: 4px;
    background-color: @text;
    color: @text-inverse;

    &--left {
        top: 0;
        right: calc(100% + 10px);

        &::before {
            left: 100%;
            border-left-color: @text;
        }
    }

    &--right {
        top: 0;
        left: calc(100% + 10px);

        &::before {
            right: 100%;
            border-right-color: @text;
        }
    }

    &::before {
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        border-width: 4px;
        border-style: solid;
    }
}
</style>