<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Таблица»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_table_1.png" alt="Блок «Таблица»" description="Блок «Таблица»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — table"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Таблица» --&gt;

{$props = [
    'color_scheme' => 'alter',
    'no_margin' => false,
    'title' => [
        'align' => 'center',
        'size' => 'medium, H2',
        'text' => 'Lorem Ipsum - взятый с потолка набор слов'
    ],
    'spreadsheet' => [
        'settings' => [
            'columns' => '4',
            'rows' => '5'
        ],
        'content' => [
            '0' => [
                '0' => 'Опции тарифа',
                '1' => 'Стартовый',
                '2' => 'Расширенный',
                '3' => 'Максимальный'
            ],
            '1' => [
                '0' => 'Lorem Ipsum - это [текст-рыба], часто используемый в печати',
                '1' => '*',
                '2' => '*',
                '3' => '*'
            ],
            '2' => [
                '0' => 'Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться',
                '1' => '*',
                '2' => '*',
                '3' => '*'
            ],
            '3' => [
                '0' => 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации',
                '1' => '',
                '2' => '*',
                '3' => '*'
            ],
            '4' => [
                '0' => 'Этот трактат по теории этики был очень популярен в эпоху Возрождения',
                '1' => '',
                '2' => '',
                '3' => '*'
            ]
        ]
    ],
    'template' => 'two',
    'use_header' => '1',
    'header_align' => 'center'
]}

{call add_block block="table" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import table from '@/assets/data/table.json'
    import AppGoBack from "../../components/AppGoBack";

    export default {
        name: 'BlockTable',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: table,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>