<template>
    <div class="app">
        <app-header />
        <div class="app__wrapper" :class="{ 'use-sidebar': sidebarIsOpened }">
            <div class="app__sidebar">
                <sidebar />
            </div>
            <div class="app__content">
                <div v-show="preloader" class="app__container">
                    <preloader />
                </div>
                <div :class="{ app__container: useContainer }">
                    <router-view v-show="!preloader" />
                </div>
            </div>
            <support-button text="Помощь" url="/support" />
            <modal />
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import Sidebar from '@/components/Sidebar.vue';
import Preloader from '@/components/Preloader';
import Modal from '@/components/Modal.vue';
import SupportButton from '@/components/SupportButton.vue';

export default {
    name: 'Home',
    components: {
        AppHeader,
        Sidebar,
        Preloader,
        Modal,
        SupportButton,
    },
    data() {
        return {
            preloader: false,
        };
    },
    computed: {
        sidebarIsOpened() {
            return this.$store.state.sidebarIsOpened;
        },
        useContainer() {
            return this.$store.state.useContainer;
        },
    },
    methods: {
        togglePreloader() {
            this.preloader = true;
            setTimeout(() => {
                this.preloader = false;
            }, 800);
        },
    },
    watch: {
        $route() {
            this.togglePreloader();
        },
    },
};
</script>

<style lang="less">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;600;700&display=swap');
@import './assets/styles/main';

.app {
    &__wrapper {
        display: grid;
        min-height: calc(100vh - 45px);
        overflow: hidden;
        background-color: lighten(@secondary, 4%);
    }

    &__content {
        height: calc(100vh - 45px);
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: @secondary;
        }

        &::-webkit-scrollbar-track {
            background-color: @secondary;
        }

        &::-webkit-scrollbar-thumb {
            background-color: darken(@secondary, 15%);
        }
    }

    &__container {
        max-width: 1200px;
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 0 auto;
        box-sizing: border-box;
    }
}

@media (min-width: 768px) {
    .app {
        &__content {
            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }
        }

        &__container {
            padding-top: 4rem;
            padding-bottom: 6rem;
            padding-left: 4rem;
            padding-right: 4rem;
        }
    }
}

@media (min-width: 1200px) {
    .app {
        &__wrapper {
            min-height: calc(100vh - 65px);
        }

        &__content {
            height: calc(100vh - 65px);
        }
    }
}

@media (min-width: 1400px) {
    .app {
        &__wrapper {
            &.use-sidebar {
                grid-template-columns: 300px 1fr;
            }
        }
    }
}
</style>
