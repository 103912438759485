<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Призыв к действию»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_cta_1.png" alt="Блок «Призыв к действию»" description="Блок «Призыв к действию»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — cta"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Призыв к действию» --&gt;

{$props = [
    'align' => 'left',
    'color_scheme' => 'alter',
    'no_margin' => false,
    'lazy' => false,
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'Как работает блок Призыв к действию?'
    ],
    'image' => 'img/550x700.png',
    'name' => 'Иванов Иван',
    'position' => 'Старший специалист',
    'question' => 'На этот и другие вопросы Вам с радостью ответят наши специалисты',
    'buttons' => [
        '1' => [
            'template' => 'button-one',
            'mode' => 'feedback',
            'link' => '#',
            'target' => '_self',
            'type' => 'primary',
            'anchor' => true,
            'wide' => true,
            'icon' => 'phone',
            'text' => 'Задать вопрос'
        ]
    ],
    'text' => 'Среднее время консультации 7 минут'
]}

{call add_block block="cta" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import cta from '@/assets/data/cta.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockCta',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: cta,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>