<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Карточки»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_cards_1.png" alt="Блок «Карточки»" description="Блок «Карточки»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — cards"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Карточки» --&gt;

{$props = [
    'align' => 'center',
    'color_scheme' => 'alter',
    'no_margin' => false,
    'animation' => true,
    'title' => [
        'align' => 'center',
        'size' => 'medium, H2',
        'text' => 'Навигация по сайту — блоки с карточками'
    ],
    'text' => 'Карточки — это неотъемлемая часть современной веб-разработки. Они представляют из себя небольшие выделенные блоки с краткой информацией и позволяют быстро фильтровать потоки посетителей по разделам.',
    'cards' => [
        '1' => [
            'template' => 'card-one',
            'color_scheme' => 'alter',
            'lazy' => false,
            'name' => 'Навигация по разделам',
            'link' => '#',
            'badge' => 'Ссылки',
            'desc' => 'Карточки используются как ссылки на основные разделы или страницы с подробной информацией',
            'image' => 'img/360x260.png'
        ],
        '2' => [
            'template' => 'card-one',
            'color_scheme' => 'alter',
            'lazy' => false,
            'name' => 'Проекты и портфолио',
            'link' => '#',
            'badge' => 'Широкое применение',
            'desc' => 'В этом блоке может быть от 1 до 4 карточек. Их можно использовать как со ссылками, так и без.',
            'image' => 'img/360x260.png'
        ],
        '3' => [
            'template' => 'card-one',
            'color_scheme' => 'alter',
            'lazy' => false,
            'name' => 'Просты и понятны',
            'link' => '#',
            'badge' => 'Интуитивность',
            'desc' => 'Карточки визуально выделены и воспринимаются посетителями как цельная единица информации.',
            'image' => 'img/360x260.png'
        ],
        '4' => [
            'template' => 'card-one',
            'color_scheme' => 'alter',
            'lazy' => false,
            'name' => 'Улучшают UX сайта',
            'link' => '#',
            'badge' => 'Универсальность',
            'desc' => 'Подходят для любых случаев, хорошо выглядят, позитивно воспринимаются пользователями.',
            'image' => 'img/360x260.png'
        ]
    ]
]}

{call add_block block="cards" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import cards from '@/assets/data/cards.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockCards',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: cards,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>