<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Слайдер»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/blog-app/adding-blocks'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_blog_slider_1.png" alt="Блок «Слайдер»" description="Блок «Слайдер»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — slider"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
					</template>&lt;!-- Блок «Слайдер» --&gt;

{$props = [
    'color_scheme' => 'main',
    'lazy' => true,
    'no_margin' => false,
    'autoplay' => true,
    'hash' => true,
    'show_views' => true,
    'show_comments' => true,
    'slides' => [
        '1' => [
            'background' => 'img/1200x550.png',
            'shadow' => true,
            'badge' => 'Новинка',
            'id' => '1',
            'video' => true,
            'title' => [
                'align' => 'left',
                'size' => 'medium, H2'
            ],
            'width' => '1200',
            'height' => '550'
        ],
        '2' => [
            'background' => 'img/1200x550.png',
            'shadow' => true,
            'badge' => 'Новинка',
            'id' => '1',
            'video' => true,
            'title' => [
                'align' => 'left',
                'size' => 'medium, H2'
            ],
            'width' => '1200',
            'height' => '550'
        ],
        '3' => [
            'background' => 'img/1200x550.png',
            'shadow' => true,
            'badge' => 'Новинка',
            'id' => '1',
            'video' => false,
            'title' => [
                'align' => 'left',
                'size' => 'medium, H2'
            ],
            'width' => '1200',
            'height' => '550'
        ],
        '4' => [
            'background' => 'img/1200x550.png',
            'shadow' => true,
            'badge' => 'Это интересно',
            'id' => '1',
            'video' => false,
            'title' => [
                'align' => 'left',
                'size' => 'medium, H2'
            ],
            'width' => '1200',
            'height' => '550'
        ]
    ]
]}

{call add_block block="slider" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import blogSlider from '@/assets/data/blog-slider.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockBlogSlider',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: blogSlider
            }
        }
    }
</script>