<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Преимущества»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_advantages_1.png" alt="Блок «Преимущества»" description="Блок «Преимущества»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — advantages"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Преимущества» --&gt;

{$props = [
  'color_scheme' => 'main',
  'no_margin' => false,
  'template' => 'one',
  'columns' => 'x3',
  'items' => [
    '1' => [
      'color_scheme' => 'main',
      'desc' => 'Lorem Ipsum - это текст-рыба, часто используемый в печати.',
      'icon_style' => 'is-linear',
      'icon_color' => 'is-primary',
      'iconset' => '1',
      'icon' => ''
    ],
    '2' => [
      'color_scheme' => 'main',
      'desc' => 'Давно выяснено, что при оценке дизайна и композиции.',
      'icon_style' => 'is-linear',
      'icon_color' => 'is-primary',
      'iconset' => '2',
      'icon' => ''
    ],
    '3' => [
      'color_scheme' => 'main',
      'desc' => 'Многие думают, что Lorem Ipsum - взятый с потолка набор слов.',
      'icon_style' => 'is-linear',
      'icon_color' => 'is-primary',
      'iconset' => '3',
      'icon' => ''
    ],
    '4' => [
      'color_scheme' => 'main',
      'desc' => 'Lorem Ipsum - это текст-рыба, часто используемый в печати.',
      'icon_style' => 'is-linear',
      'icon_color' => 'is-primary',
      'iconset' => '4',
      'icon' => ''
    ],
    '5' => [
      'color_scheme' => 'main',
      'desc' => 'Давно выяснено, что при оценке дизайна и композиции.',
      'icon_style' => 'is-linear',
      'icon_color' => 'is-primary',
      'iconset' => '5',
      'icon' => ''
    ],
    '6' => [
      'color_scheme' => 'main',
      'desc' => 'Многие думают, что Lorem Ipsum - взятый с потолка набор слов.',
      'icon_style' => 'is-linear',
      'icon_color' => 'is-primary',
      'iconset' => '6',
      'icon' => ''
    ]
  ]
]}

{call add_block block="advantages" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopAdvantages from '@/assets/data/shop-advantages.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopAdvantages',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopAdvantages,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>