<template>
  <div>
    <app-title text="Главная страница магазина" />

    <p class="documentation__text">
      В панели настроек перейдите во вкладку «Главная страница» 🠖 «Порядок блоков» и отключите <accent text="«Стартовый»"/> блок.
      Затем выберите блоки, которые вы хотите использовать на главной странице и включите их отображение.
    </p>

    <p class="documentation__text">
      В бэкенде создайте несколько <accent text="товаров"/> и <accent text="списков"/>.
    </p>

    <app-image url="/img/documentation/shop_homepage_1.png" alt="Shop Script списки товаров" />

    <p class="documentation__text">
      Некоторые блоки требуют ввести ID товара. Узнать его можно в бэкенде в редакторе товара в правом верхнем углу.
    </p>

    <app-subtitle text="Свой код на главной странице" />

    <p class="documentation__text">
      Если требуется, как и в случае с приложением Сайт, после каждого блока на главной странице можно добавить свой HTML код.
      Для этого в бэкенде перейдите в приложение «Сайт» 🠖 «Блоки», создайте новый блок с названием <accent text="shop.after_НАЗВАНИЕ_БЛОКА"/>. Например, <b>shop.after_slider</b>.
      Используйте рамку, чтобы центрировать ваш контент:
    </p>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;section class=&quot;frame frame--main&quot;&gt;
  &lt;div class=&quot;frame__container&quot;&gt;
    &lt;div class=&quot;frame__center&quot;&gt;
      &lt;!-- Ваш HTML здесь --&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/section&gt;</ssh-pre>
    </div>

  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'

export default {
  name: 'ShopHomepage',
  components: {
    SshPre,
    IconCopy
  },
  data() {
    return {

    }
  },
}
</script>