<template>
    <span class="accent">
        {{ text }}
    </span>
</template>

<script>
export default {
    name: 'Accent',
    props: {
        text: String,
    },
};
</script>

<style lang="less">
.accent {
    display: inline-block;
    padding-left: 0.2em;
    padding-right: 0.2em;
    border-radius: 4px;
    background-color: fade(@primary, 12%);
}
</style>
