<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «В тренде»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/blog-app/adding-blocks'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_blog_trending_1.png" alt="Блок «В тренде»" description="Блок «В тренде»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — trending"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «В тренде» --&gt;

{$props = [
    'color_scheme' => 'main',
    'no_margin' => true,
    'title' => [
        'align' => 'left',
        'size' => 'medium, H2',
        'text' => 'В тренде'
    ],
    'items' => [
        '1' => [
            'id' => '1',
            'show_views' => true,
            'show_comments' => true,
            'color_scheme' => 'main',
            'lazy' => true,
            'preview' => '/wa-apps/blog/themes/orion_blog/img/1150x680.png',
            'width' => 730,
            'height' => 530,
            'shadow' => true,
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ],
        '2' => [
            'id' => '1',
            'show_views' => true,
            'show_comments' => true,
            'color_scheme' => 'main',
            'lazy' => true,
            'preview' => '/wa-apps/blog/themes/orion_blog/img/730x530.png',
            'width' => 730,
            'height' => 530,
            'shadow' => true,
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ],
        '3' => [
            'id' => '1',
            'show_views' => true,
            'show_comments' => true,
            'color_scheme' => 'main',
            'lazy' => true,
            'preview' => '/wa-apps/blog/themes/orion_blog/img/730x530.png',
            'width' => 730,
            'height' => 530,
            'shadow' => true,
            'publisher' => [
                'logo_url' => '',
                'phone' => '8 (800) 000 00 00',
                'city' => 'г. Москва'
            ]
        ]
    ]
]}

{call add_block block="trending" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import blogTrending from '@/assets/data/blog-trending.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockBlogTrending',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: blogTrending
            }
        }
    }
</script>