<template>
	<div>
		<app-title text="Установка блога" />

		<p class="documentation__text">
			Купите и установите тему дизайна через Инсталлер. Откройте приложение «Блог» 🠖 «Дизайн» 🠖 «Настройки». Выберите «Orion Blog» в качестве <accent text="темы оформления"/> и мобильной темы оформления.
		</p>

		<app-image url="/img/documentation/blog_installation_1.png" alt="Установка темы дизайна Orion Blog" />

		<p class="documentation__text">
			Далее установите плагины, это не обязательно, но крайне рекомендуется.
		</p>

		<app-subtitle text="Поддерживаемые плагины" number="1" />

		<p class="documentation__text">
			Для корректной работы требуется установить следующие плагины для приложения «Блог»:
		</p>

		<ul class="documentation__cards">
			<li class="documentation__card">
				<a href="https://www.webasyst.ru/store/plugin/blog/mainpic/" class="documentation__card-image" target="_blank">
					<app-image url="/img/documentation/blog_installation_2.png" alt="Плагин «Главная картинка»" border />
				</a>
				<a href="https://www.webasyst.ru/store/plugin/blog/mainpic/" class="documentation__card-name" target="_blank">Плагин «Главная картинка»</a>
				<p class="documentation__card-desc">Добавляет главные картинки к блогам и постам.</p>
			</li>
			<li class="documentation__card">
				<a href="https://www.webasyst.ru/store/plugin/blog/viewed/" class="documentation__card-image" target="_blank">
					<app-image url="/img/documentation/blog_installation_3.png" alt="Плагин «Количество просмотров»" border />
				</a>
				<a href="https://www.webasyst.ru/store/plugin/blog/viewed/" class="documentation__card-name" target="_blank">Плагин «Количество просмотров»</a>
				<p class="documentation__card-desc">Позволяет вывести количество просмотров статьи.</p>
			</li>
			<li class="documentation__card">
				<a href="https://www.webasyst.ru/store/plugin/blog/category/" class="documentation__card-image" target="_blank">
					<app-image url="/img/documentation/blog_installation_4.png" alt="Плагин «Категории»" border />
				</a>
				<a href="https://www.webasyst.ru/store/plugin/blog/category/" class="documentation__card-name" target="_blank">Плагин «Категории»</a>
				<p class="documentation__card-desc">Фильтрация записей по категориям.</p>
			</li>
			<li class="documentation__card">
				<a href="https://www.webasyst.ru/store/plugin/blog/hidset/" class="documentation__card-image" target="_blank">
					<app-image url="/img/documentation/blog_installation_5.png" alt="Плагин «Скрытые настройки»" border />
				</a>
				<a href="https://www.webasyst.ru/store/plugin/blog/hidset/" class="documentation__card-name" target="_blank">Плагин «Скрытые настройки»</a>
				<p class="documentation__card-desc">Изменение некоторых скрытых настроек Блога, позволяет изменять количество статей в блоке «Поток статей».</p>
			</li>
		</ul>

	</div>
</template>

<script>
    export default {
        name: 'BlogApp',
        data() {
            return {

            }
        },
    }
</script>