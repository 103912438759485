<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Главный экран»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_mainscreen_1.png" alt="Блок «Главный экран»" description="Блок «Главный экран»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — mainscreen"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Главный экран» --&gt;

{$props = [
    'id' => '',
    'color' => '#ffffff',
    'half_screen' => false,
    'gradient' => true,
    'lazy' => false,
    'background' => 'img/2200x1220.png',
    'video' => '',
    'align' => 'left',
    'title' => [
        'align' => 'left',
        'size' => 'big, H2',
        'text' => 'Современный сайт с удобной настройкой блоков'
    ],
    'text' => '&lt;p&gt;Меняйте основные данные главной страницы в фронтенде. Теперь стало проще сделать одностраничный или многостраничный сайт.&lt;/p&gt;',
    'list' => [
        'template' => 'list-one',
        'inverse' => 'true',
        'align' => 'left',
        'list' => 'Добавление изображений * Редактирование стилей кнопок и теней * Настройка цвета и размера шрифтов'
    ],
    'buttons' => [
        '1' => [
            'template' => 'button-one',
            'mode' => 'feedback',
            'link' => '#',
            'target' => '_self',
            'type' => 'primary',
            'anchor' => true,
            'icon' => 'none',
            'text' => 'Заказать звонок'
        ],
        '2' => [
            'template' => 'button-one',
            'mode' => 'link',
            'link' => '#',
            'target' => '_self',
            'type' => 'secondary',
            'anchor' => true,
            'icon' => 'none',
            'text' => 'Подробнее'
        ]
    ]
]}

{call add_block block="mainscreen" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import mainscreen from '@/assets/data/mainscreen.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockMainscreen',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: mainscreen,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>