<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Способы оплаты»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_payments_1.png" alt="Блок «Способы оплаты»" description="Блок «Способы оплаты»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — payments"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Способы оплаты» --&gt;

{$props = [
  'mode' => 'carousel',
  'no_margin' => false,
  'lazy' => false,
  'color_scheme' => 'main',
  'title' => [
    'align' => 'left',
    'size' => 'small, H3',
    'text' => 'Способы оплаты'
  ],
  'cards' => [
    '1' => [
      'image' => 'img/200x120.png',
      'text' => 'Курьер обязан выдать бумажный чек, для этого курьеру нужен принтер чеков.',
      'button' => [
        'template' => 'button-one',
        'mode' => 'link',
        'link' => '#',
        'target' => '_self',
        'type' => 'secondary',
        'anchor' => true,
        'icon' => 'none',
        'wide' => true,
        'text' => 'Наличными'
      ]
    ],
    '2' => [
      'image' => 'img/200x120.png',
      'text' => 'Курьеру понадобится мобильный терминал, а после расчёта он должен напечатать чек.',
      'button' => [
        'template' => 'button-one',
        'mode' => 'link',
        'link' => '#',
        'target' => '_self',
        'type' => 'secondary',
        'anchor' => true,
        'icon' => 'none',
        'wide' => true,
        'text' => 'Пластиковой картой'
      ]
    ],
    '3' => [
      'image' => 'img/200x120.png',
      'text' => 'Пункты самовывоза могут быть вашими или предоставляться сторонними компаниями.',
      'button' => [
        'template' => 'button-one',
        'mode' => 'link',
        'link' => '#',
        'target' => '_self',
        'type' => 'secondary',
        'anchor' => true,
        'icon' => 'none',
        'wide' => true,
        'text' => 'Оплата в пункте самовывоза'
      ]
    ],
    '4' => [
      'image' => 'img/200x120.png',
      'text' => 'Подходит, если вы хотите доставлять заказы почтой',
      'button' => [
        'template' => 'button-one',
        'mode' => 'link',
        'link' => '#',
        'target' => '_self',
        'type' => 'secondary',
        'anchor' => true,
        'icon' => 'none',
        'wide' => true,
        'text' => 'Наложенный платёж'
      ]
    ],
    '5' => [
      'image' => 'img/200x120.png',
      'text' => 'Заключите договор с агрегатором и настроить интеграцию с ним.',
      'button' => [
        'template' => 'button-one',
        'mode' => 'link',
        'link' => '#',
        'target' => '_self',
        'type' => 'secondary',
        'anchor' => true,
        'icon' => 'none',
        'wide' => true,
        'text' => 'Агрегатор платежей'
      ]
    ],
    '6' => [
      'image' => 'img/200x120.png',
      'text' => 'При помощи электронных кошельков можно получать от клиентов электронные деньги.',
      'button' => [
        'template' => 'button-one',
        'mode' => 'link',
        'link' => '#',
        'target' => '_self',
        'type' => 'secondary',
        'anchor' => true,
        'icon' => 'none',
        'wide' => true,
        'text' => 'Оплата электронными деньгами'
      ]
    ]
  ]
]}

{call add_block block="payments" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopPayments from '@/assets/data/shop-payments.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopPayments',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopPayments,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>