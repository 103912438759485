<template>
  <div>
    <app-title text="Блоки и элементы для Orion Shop" />

    <p class="documentation__text">
      В бэкенде приложения <accent text="Магазин"/> 🠖 <accent text="Витрина"/> 🠖 <accent text="Страницы"/> вы можете создать страницы с текстовым контентом. Кроме этого такие страницы можно "собрать" из блоков.
    </p>

    <p class="documentation__text">
      После создания новой страницы и перейдите в режим редактирования HTML. <b>Один раз</b> в начале страницы необходимо добавить следующий код:
    </p>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Подключает утилиты --&gt;
{include "{$wa->shop->themePath(waRequest::getTheme())}utils.html"}

&lt;!-- Убирает стандартные стили --&gt;
{$wa->globals("userCode", true)}

&lt;!-- Настройки шрифта и кнопок --&gt;
{$_primary_font = 'philosopher'}
{$_button_type = 'button-one'}</ssh-pre>
    </div>

    <p class="documentation__text">
      Далее на страницу можно добавить любое количество блоков. Для этого скопируйте код блока, вставьте его и отредактируйте контент так как вам нужно.
    </p>

    <navigation-box
        icon="cards"
        title="Карточки"
        description="Блоки темы дизайна «Orion Shop»"
        :links="navigationLinks(`shop-cards`)"
    />

    <navigation-box
        icon="fullscreen"
        title="Слайдеры и изображения"
        description="Блоки темы дизайна «Orion Shop»"
        :links="navigationLinks(`shop-slider`)"
    />

    <navigation-box
        icon="interactive"
        title="Интерактивные"
        description="Блоки темы дизайна «Orion Shop»"
        :links="navigationLinks(`shop-interactive`)"
    />

    <navigation-box
        icon="text"
        title="Текстовые"
        description="Блоки темы дизайна «Orion Shop»"
        :links="navigationLinks(`shop-text`)"
    />

    <p class="documentation__text">
      Элементы — это составные части блоков, которые можно использовать в своей HTML разметке. Как и в случае с блоками, в начале страницы должны быть подключены утилиты и заданы стили кнопок и шрифты.
    </p>

    <navigation-box
        icon="element"
        title="Элементы"
        description="Темы дизайна «Orion Shop»"
        :links="navigationLinks(`shop-element`)"
    />

  </div>
</template>

<script>
  import NavigationBox from '@/components/NavigationBox.vue'
  import SshPre from 'simple-syntax-highlighter'
  import 'simple-syntax-highlighter/dist/sshpre.css'
  import IconCopy from '@/components/icons/IconCopy.vue'

  export default {
    name: 'ShopAddingBlocks',
    components: {
      NavigationBox,
      SshPre,
      IconCopy
    },
    data() {
      return {

      }
    },
    computed: {
      navigation() {
        const navigation = this.$router.options.routes.find(element => element.name === `Documentation`);
        return navigation.children.filter(function (link) {
          return link.meta.type === `block`;
        });
      }
    },
    methods: {
      navigationLinks(category) {
        const navigation =  this.navigation.filter(function(link) {
          return link.meta.category === category;
        });
        return navigation.map(function (link) {
          return {url: link.path, text: link.meta.name};
        });
      }
    }
  }
</script>