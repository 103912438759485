<template>
    <div class="info-box">
        <span>{{ text }}</span>
        <router-link
            v-if="link.path"
            class="info-box__link"
            :to="link.path"
            active-class="is-active"
            >{{ link.name }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'InfoBox',
    props: {
        text: String,
        link: Object,
    },
};
</script>

<style lang="less">
@border-size: 4px;
@icon-size: 20px;

.info-box {
    position: relative;
    padding: 0.8rem 1.5rem;
    border-left: @border-size solid @contrast;
    background-color: @background;
    font-size: 0.95em;
    margin-bottom: 20px;

    &::before {
        content: '!';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc((-@icon-size - @border-size) / 2);
        display: flex;
        justify-content: center;
        align-items: center;
        width: @icon-size;
        height: @icon-size;
        border-radius: 50%;
        background-color: @contrast;
        color: @text-inverse;
    }

    &__link {
        color: @primary;
        transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:hover {
            color: darken(@primary, 15%);
        }
    }
}
</style>