<template>
    <div class="burger" :class="{'is-opened': opened}" @click="toggleNavigation">
        <div class="burger__icon">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="burger__cross">
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IconBurger',
        data() {
            return {
                opened: false
            }
        },
        methods: {
            toggleNavigation() {
                this.opened = !this.opened;
                this.$emit('toggleNavigation', this.opened);
            }
        }
    }
</script>

<style lang="less">
    .burger {
        position: relative;
        flex: 0 0 auto;
        width: 40px;
        height: 40px;
        cursor: pointer;

        &__icon,
        &__cross {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }

        &__icon {
            & span {
                position: relative;
                display: block;
                width: 22px;
                height: 2px;
                margin-bottom: 3px;
                overflow: hidden;

                &:last-child {
                    margin:0;
                }

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: lighten(@text, 30%);
                    transform: translateX(-200%);
                    transition: transform ease 300ms;
                }

                &::after {
                    transform: translateX(0);
                }

                &:nth-child(2)::before,
                &:nth-child(2)::after {
                    transition-delay: 100ms;
                }

                &:last-child::before,
                &:last-child::after {
                    transition-delay: 200ms;
                }
            }
        }

        &:hover {
            & .burger__icon span::before {
                transform: translateX(0);
            }

            & .burger__icon span::after {
                transform: translateX(200%);
            }
        }

        &.is-opened {
            & .burger__icon span::before {
                transform: translateX(100%);
            }

            & .burger__icon span::after {
                transform: translateX(200%);
            }

            & .burger__cross span {
                transition-delay:450ms;
                transform:translateY(50%) rotate(45deg) scaleX(1);

                &:last-child{
                    transform:translateY(-50%) rotate(-45deg) scaleX(1);
                }
            }
        }

        &__cross {
            & span {
                display: block;
                width: 22px;
                height: 2px;
                background-color: lighten(@text, 30%);
                transform: translateY(50%) rotate(45deg) scaleX(0);
                transition: transform ease 200ms;

                &:last-child{
                    transform: translateY(-50%) rotate(-45deg) scaleX(0);
                }
            }
        }
    }

    @media (min-width: 1200px) {
        .burger {
            display: none;
        }
    }
</style>