<template>
	<div>
		<div class="documentation__header">
			<app-title text="Элемент «Вопрос-ответ»"></app-title>
			<app-go-back :link="{name: 'Набор элементов', path: '/documentation/elements-list'}"></app-go-back>
		</div>

		<app-subtitle text="Вызов элемента на страницах сайта"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову элементов на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Элемент «Вопрос-ответ» --&gt;
{$props = [
  'template' => 'faq-one',
  'color_scheme' => 'main',
  'question' => 'Откуда он появился?',
  'answer' => 'Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов, но это не совсем так. Его корни уходят в один фрагмент классической латыни 45 года н.э., то есть более двух тысячелетий назад.'
]}

{call add_element element="faq/`$props.template`" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import faq from '@/assets/data/element-faq.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'ElementFaq',
        components: {
            SshPre,
            IconCopy,
            AppGoBack
        },
        data() {
            return {
                tableData: faq,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>