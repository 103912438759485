<template>
  <div>
    <div class="documentation__header">
      <app-title text="Блок «Новинки»"></app-title>
      <app-go-back :link="{name: 'Набор блоков', path: '/documentation/shop-app/adding-blocks'}"></app-go-back>
    </div>

    <app-image url="/img/documentation/block_shop_new_1.png" alt="Блок «Новинки»" description="Блок «Новинки»" border />

    <app-subtitle text="Вызов блока на страницах сайта" label="ID — new"/>

    <div class="documentation__code">
      <ssh-pre language="html" label="Smarty" :copy-button="true">
        <template v-slot:copy-button>
          <icon-copy />
        </template>&lt;!-- Блок «Новинки» --&gt;

{$props = [
  'color_scheme' => 'semitone',
  'no_margin' => false,
  'set' => 'new',
  'title' => [
    'align' => 'left',
    'size' => 'medium, H3',
    'text' => 'Тема дизайна «Orion Shop»'
  ],
  'text' => 'Тема дизайна Orion Shop для Shop-Script позволит вам создать современный и гибкий интернет магазин, оптимизированный для любых устройств.',
  'link' => [
    'template' => 'link-two',
    'anchor' => true,
    'uppercase' => true,
    'color' => 'secondary',
    'target' => '_self',
    'link' => '#',
    'text' => 'Все новинки'
  ],
  'product' => [
    'template' => 'three',
    'color_scheme' => 'semitone',
    'lazy' => false,
    'image_type' => 'gallery',
    'use_link' => true,
    'image_size' => '400',
    'image_margins' => true,
    'show_desc' => false,
    'show_rating' => true,
    'show_stocks' => true,
    'show_controls' => true,
    'hide_button' => true,
    'show_compare' => true,
    'show_favorite' => true,
    'button_on_hover' => true,
    'button' => 'В корзину',
    'button_added' => 'Оформить заказ'
  ]
]}

{call add_block block="new" props=$props}</ssh-pre>
    </div>

    <app-table :data="tableData"/>
  </div>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'
import IconCopy from '@/components/icons/IconCopy.vue'
import shopNew from '@/assets/data/shop-new.json'
import AppGoBack from "../../components/AppGoBack"

export default {
  name: 'BlockShopNew',
  components: {
    SshPre,
    IconCopy,
    AppGoBack
  },
  data() {
    return {
      tableData: shopNew,
      infoLink: {name: 'Подробнее', path: '/documentation/shop-app/adding-blocks'}
    }
  }
}
</script>