<template>
	<div>
		<div class="documentation__header">
			<app-title text="Блок «Услуги»"></app-title>
			<app-go-back :link="{name: 'Набор блоков', path: '/documentation/block-list'}"></app-go-back>
		</div>

		<app-image url="/img/documentation/block_services_1.png" alt="Блок «Услуги»" description="Блок «Услуги»" border />

		<app-subtitle text="Вызов блока на страницах сайта" label="ID — services"/>

		<info-box text="Перед использованием кода ознакомьтесь с руководством по вызову блоков на дополнительных страницах" :link="infoLink"/>

		<div class="documentation__code">
			<ssh-pre language="html" label="Smarty" :copy-button="true">
				<template v-slot:copy-button>
					<icon-copy />
				</template>&lt;!-- Блок «Услуги» --&gt;

{$props = [
    'background_type' => 'image',
    'color' => '#ffffff',
    'gradient' => true,
    'lazy' => false,
    'background' => 'img/2200x1220.png',
    'tabs' => [
        '1' => [
            'background' => 'img/2200x1220_1.png',
            'name' => 'Установка',
            'link' => '#',
            'linkname' => 'Перейти'
        ],
        '2' => [
            'background' => 'img/2200x1220_2.png',
            'name' => 'Настройка',
            'link' => '#',
            'linkname' => 'Перейти'
        ],
        '3' => [
            'background' => 'img/2200x1220_3.png',
            'name' => 'Готовые блоки',
            'link' => '#',
            'linkname' => 'Перейти'
        ],
        '4' => [
            'background' => 'img/2200x1220_4.png',
            'name' => 'Обновления',
            'link' => '#',
            'linkname' => 'Перейти'
        ]
    ]
]}

{call add_block block="services" props=$props}</ssh-pre>
		</div>

		<app-table :data="tableData"/>
	</div>
</template>

<script>
    import SshPre from 'simple-syntax-highlighter'
    import 'simple-syntax-highlighter/dist/sshpre.css'
    import IconCopy from '@/components/icons/IconCopy.vue'
    import services from '@/assets/data/services.json'
    import AppGoBack from "../../components/AppGoBack"

    export default {
        name: 'BlockServices',
        components: {
            SshPre,
            IconCopy,
			AppGoBack
        },
        data() {
            return {
                tableData: services,
                infoLink: {name: 'Подробнее', path: '/documentation/adding-blocks'}
            }
        }
    }
</script>