<template>
    <div class="subtitle">
        <hr class="subtitle__hr" v-if="line" />
        <div class="subtitle__wrapper">
            <h2 class="subtitle__header">
                <span class="subtitle__number" v-if="number">{{ number }}</span>
                <span class="subtitle__text">{{ text }}</span>
                <span class="subtitle__label" v-if="label">{{ label }}</span>
            </h2>
            <span class="subtitle__badge" v-if="badge">{{ badge }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppSubtitle',
    props: {
        text: String,
        number: String,
        line: Boolean,
        label: String,
        badge: String,
    },
};
</script>

<style lang="less">
.subtitle {
    margin-bottom: 20px;

    &__hr {
        height: 1px;
        background-color: darken(@secondary, 8%);
        border: unset;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 1.5rem;
    }

    &__badge {
        display: inline-block;
        padding: 0.1em 0.8em;
        border-radius: 4px;
        background-color: @contrast;
        color: @text-inverse;
        font-size: 0.9em;
        line-height: 1.4em;
        white-space: nowrap;
    }

    &__header {
        display: flex;
        gap: 0.6em;
        align-items: baseline;
        font-family: @title-font;
        font-weight: 600;
    }

    &__number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        background-color: @secondary;
        border-radius: 50%;
        color: lighten(@text, 30%);
        font-size: 0.6em;
        line-height: 1.4em;
        font-weight: 700;
    }

    &__label {
        display: inline-block;
        padding: 0 0.8em 0.1em 0.8em;
        border-radius: 20px;
        background-color: @secondary;
        color: lighten(@text, 30%);
        font-size: 0.6em;
        line-height: 1.4em;
        font-weight: 700;
    }
}
</style>